import React from 'react';

import { View } from "../../../TiendaConfig";

const DestacadosView = (props) => (
  <div className="productos-destacados-container my-2">
    {props.title &&
      <h2 className="text-center title big"><strong>{props.title ? props.title : ""}</strong></h2>
    }
    {
      props.destacados.filter(item => item.Articulos.length > 0).map((item, index) => (
        <View.DestacadoView
          updateURL={(url) => props.updateURL && props.updateURL(url)}
          code={item.code}
          titulo={item.Titulo}
          articulos={item.Articulos}
        />
      ))
    }
  </div>
);

export default DestacadosView;
