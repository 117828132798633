import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import { Input, Table, Nav, NavItem, NavLink as RSNavLink } from 'reactstrap';
import { GlobalConfigTienda } from '../../../../TiendaConfig';
import api from '../../../api';
import apifunctions from '../../../api/functions';
import functions from '../../../functions/functions';

const CardTabs = ({ state, setState, CodigoCondicionDePago }) => {
  return (
    <Nav tabs>
      {
        state.tarjetas.map(
          (tarj, tarjIndex) => (
            <NavItem key={`${CodigoCondicionDePago}-${tarjIndex}`} onClick={() => setState({ ...state, currentCardSelected: tarjIndex })}>
              <RSNavLink href="#" active={tarjIndex == state.currentCardSelected}>
                <img id={`${CodigoCondicionDePago}-${tarjIndex}`} alt={tarj.name} src={tarj.imageSrc} className="rounded img-thumbnail" style={{ height: "35px" }} />
              </RSNavLink>
            </NavItem>
          )
        )
      }
    </Nav>
  )
}

const CardTable = ({ state, setState, CodigoCondicionDePago, total }) => {
  const UpdateItemSelected = (tarjIndex, value) => {
    setState(prevState => {
      let array = [...prevState.planSelected];
      array[tarjIndex] = value;
      prevState.planSelected = array;
      return prevState;
    })
  }

  const valorCuota = () => {
    let cuota = state.tarjetas[state.currentCardSelected].cuotas[state.planSelected[state.currentCardSelected]];
    let valorCuota = total / cuota.nroCuotas;
    return `$ ${functions.FormatPrice(valorCuota, 2)}`;
  }

  return (
    <Table className="text-left">
      <thead>
        <tr>
          <th className="text-center">Cuotas</th>
          <th className="text-center">Cuota Mensual</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{ width: "400px" }}>
            <Input type="select" name="select" style={{ width: "400px" }}
              onChange={(e) => UpdateItemSelected(state.currentCardSelected, e.target.value)}
            >
              {
                state.tarjetas[state.currentCardSelected].cuotas.map(
                  (cuota, cuotaIndex) => (
                    state.planSelected[state.currentCardSelected] == cuotaIndex ?
                      <option key={`${CodigoCondicionDePago}-tarj-${state.currentCardSelected}-cuota-${cuotaIndex}`} selected="selected" value={cuotaIndex}>{cuota.label}</option>
                      :
                      <option key={`${CodigoCondicionDePago}-tarj-${state.currentCardSelected}-cuota-${cuotaIndex}`} value={cuotaIndex}>{cuota.label}</option>
                  )
                )
              }
            </Input>
          </td>
          <td className="text-center font-weight-bold">{valorCuota()}</td>
        </tr>
      </tbody>
    </Table>
  )
}

const CardOptionsSelector = ({ state, setState, CodigoCondicionDePago }) => {
  return (
    <Input type="select" name="select"
      onChange={(evt) => setState({ ...state, currentCardSelected: parseInt(evt.target.value) })}
    >
      {
        state.tarjetas.map(
          (tarj, tarjIndex) => (
            <option key={`${CodigoCondicionDePago}-${tarjIndex}`} value={tarjIndex}>Tarjeta {tarj.name}</option>
          )
        )
      }
    </Input>
  )
}

const PlanOptionsSelector = ({ state, setState, CodigoCondicionDePago, total }) => {
  const UpdateItemSelected = (tarjIndex, value) => {
    setState(prevState => {
      let array = [...prevState.planSelected];
      array[tarjIndex] = value;
      prevState.planSelected = array;
      return prevState;
    })
  }

  const valorCuota = () => {
    let cuota = state.tarjetas[state.currentCardSelected].cuotas[state.planSelected[state.currentCardSelected]];
    let valorCuota = total / cuota.nroCuotas;
    return `$ ${functions.FormatPrice(valorCuota, 2)}`;
  }

  return (
    <>
      <Input type="select" name="select"
        onChange={(e) => UpdateItemSelected(state.currentCardSelected, e.target.value)}
      >
        {
          state.tarjetas[state.currentCardSelected].cuotas.map(
            (cuota, cuotaIndex) => (
              state.planSelected[state.currentCardSelected] == cuotaIndex ?
                <option key={`${CodigoCondicionDePago}-tarj-${state.currentCardSelected}-cuota-${cuotaIndex}`} selected="selected" value={cuotaIndex}>{cuota.label}</option>
                :
                <option key={`${CodigoCondicionDePago}-tarj-${state.currentCardSelected}-cuota-${cuotaIndex}`} value={cuotaIndex}>{cuota.label}</option>
            )
          )
        }
      </Input>
      <p className="mt-2">Cuota Mensual {valorCuota()}</p>
    </>
  )
}

const initialState = {
  currentCardSelected: 0,
  planSelected: [],
  tarjetas: [],
};

const TarjetasSelector = (props) => {
  const { CodigoCondicionDePago, total, onChange } = props;
  const [state, setState] = useState({
    currentCardSelected: 0,
    planSelected: [],
    tarjetas: [],
  })

  useEffect(() => {
    const getAndProcess = (reintento = 0) => {
      const successCallback = (response) => {
        let newState = { ...initialState };
        if (response.data && response.data.length > 0) {
          newState.tarjetas = response.data;
          newState.planSelected = Array.from(Array(response.data.length).keys()).map(_ => (0));
        }
        setState(newState);
        onChange && onChange(newState.planSelected[newState.currentCardSelected]);
      }
      const failureCallback = () => {
        if (reintento > 3) setState({ ...initialState });
        else getAndProcess(reintento + 1);
      }
      apifunctions.get(api.api.ventas.tarjetasyplanes, CodigoCondicionDePago, null, {}, successCallback, failureCallback, failureCallback);
    }
    getAndProcess();
  }, [CodigoCondicionDePago]);

  useEffect(() => {
    let currentPlan = null;
    try {
      currentPlan = state.tarjetas[state.currentCardSelected].cuotas[state.planSelected[state.currentCardSelected]].id;
    } catch (e) { currentPlan = null; }
    onChange(currentPlan);
  }, [state.planSelected, state.currentCardSelected])

  return state.tarjetas.length > 0 && CodigoCondicionDePago && (
    <React.Fragment>
      <div className="d-none d-md-block mt-4">
        <CardTabs {...{ state, setState, CodigoCondicionDePago }} />
        <CardTable {...{ state, setState, CodigoCondicionDePago, total }} />
      </div>
      <div className="d-sm-block d-md-none">
        <CardOptionsSelector {...{ state, setState, CodigoCondicionDePago }} />
        <PlanOptionsSelector {...{ state, setState, CodigoCondicionDePago, total }} />
      </div>
    </React.Fragment>
  );
}
export default TarjetasSelector;