import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import ProductosDestacados from "../../components/ProdDestacados";
import Ofertas from "../../components/ProdOfertados";
import { LoginContext } from "../../components/ParadigmaLoginProvider";
import { MercadoPagoLinkGenerator } from "./MercadoPago";
import emptycart from "../../assets/empty-shoppingcart.png";

import { GlobalConfigTienda } from "../../../TiendaConfig";

const EmptyCart = (props) => {
  const { successfulOrder, updateURL } = props;
  const message = successfulOrder
    ? "<h1>¡Pedido realizado!</h1><h3 className='font-weight-light mb-4'>A la brevedad nos pondremos en contacto con usted para coordinar el envío.</h3>"
    : "<h1>Su carrito está Vacio</h1>";
  const successfulOrderCode =
    successfulOrder && successfulOrder.code ? successfulOrder.code : false;

  window.scrollTo(0, 0);

  return (
    <div className="empty-cart-container text-center my-5">
      <img width={"auto"} height={70} src={emptycart} alt={"Carrito vacio"} />
      <div dangerouslySetInnerHTML={{ __html: message }}></div>
      {successfulOrderCode && (
        <MercadoPagoLinkGenerator code={successfulOrderCode} />
      )}
      {
        <LoginContext.Consumer>
          {({ logged }) => (
            <Row className="mx-0">
              <Col className="py-1" xs={12} md={logged ? 4 : 6}>
                <Link
                  to="/"
                  className="text-center rounded btn btn-outline-light btn-lg"
                  style={{ textDecoration: "none", color: "#2b4a77" }}
                >
                  <img
                    width={"auto"}
                    className="pr-1"
                    height={32}
                    src={GlobalConfigTienda.logoSrc}
                    alt={"Ir a Catálogo"}
                  />{" "}
                  <br />
                  Volver a Inicio
                </Link>
              </Col>
              <Col className="py-1" xs={12} md={logged ? 4 : 6}>
                <Link
                  to="/tienda/productos"
                  className="text-center rounded btn btn-outline-light btn-lg"
                  style={{ textDecoration: "none", color: "#2b4a77" }}
                >
                  <i
                    className="fa fa-arrow-left fa-lg pr-1"
                    aria-hidden="true"
                  ></i>{" "}
                  <br />
                  Ir a Tienda
                </Link>
              </Col>
              {logged && (
                <Col className="py-1" xs={12} md={4}>
                  <Link
                    to="/tienda/cliente/compras"
                    className="text-center rounded btn btn-outline-light btn-lg"
                    style={{ textDecoration: "none", color: "#2b4a77" }}
                  >
                    <i
                      className="fa fa-arrow-right fa-lg pl-1"
                      aria-hidden="true"
                    ></i>
                    <br />
                    Ver Historial de Compras
                  </Link>
                </Col>
              )}
            </Row>
          )}
        </LoginContext.Consumer>
      }

      <hr />
      <ProductosDestacados
        updateURL={updateURL}
        title="Algunos de Nuestros Productos Destacados"
      />
      <Ofertas
        updateURL={updateURL}
        title="Algunas de nuestras Super Ofertas"
      />
    </div>
  );
};
export default EmptyCart;
