import React, { useState, useEffect } from 'react';
import { Alert } from 'reactstrap';

import api from '../../components/Tienda/api';
import apifunctions from '../../components/Tienda/api/functions.js';

import './Contacto.scss';

const AlertMensaje = (props) => {
	const Mensajes = [
		" Enviando ...",
		" Su Mensaje ha sido enviado con exito",
		" Fallo el envio de su mensaje, intentelo nuevamente más tarde."
	];
	const ClassNames = [
		"alert-info",
		"alert-success",
		"alert-danger",
	];
	return props.Estado > 0 && (props.Estado - 1) < Mensajes.length && (
		<Alert className={ClassNames[props.Estado - 1]}>
			{props.Estado === 1 && (<i className="fa fa-spinner fa-spin" aria-hidden="true"></i>)}
			{props.Estado === 2 && (<i className="fa fa-check" aria-hidden="true"></i>)}
			{props.Estado === 3 && (<i className="fa fa-times" aria-hidden="true"></i>)}
			{Mensajes[props.Estado - 1]}
		</Alert>
	);
}

const Contacto = (props) => {
	let envioTimeout = 0;

	/* 0 - Sin ENVIAR; 1 - Enviando; 2 - Exito; 3 - Fallo; */
	const [envioState, updateEnvioState] = useState(0);

	const [formState, updateState] = useState({
		NombreCompleto: '',
		Email: '',
		Direccion: '',
		Telefono: '',
		Ciudad: '',
		Provincia: '',
		Mensaje: '',
	});

	const updateFormState = (prop, val) => updateState({ ...formState, [prop]: val, });

	const SendMail = () => {

		let enviar = false;

		const fieldNames = {
			NombreCompleto: "Nombre y Apellido",
			Email: "Email",
			Direccion: "Direccion",
			Telefono: "Teléfono",
			Ciudad: "Ciudad",
			Provincia: "Provincia",
			Mensaje: "Mensaje",
		}

		let fomularioContacto = document.getElementById("fomularioContacto");
		if (fomularioContacto !== undefined && fomularioContacto !== null) {
			let data = new FormData();

			Object.keys(fieldNames).forEach((field, index) => {
				if (formState[field] !== undefined && formState[field] != null && formState[field].length > 0) {
					data.append(`NombresCampos[${index}]`, fieldNames[field]);
					data.append(`ValoresCampos[${index}]`, formState[field]);
				}
			});

			data.append("Asunto", "Contacto");
			formState.SendTo && data.append("SendTo", formState.SendTo);

			const successCallback = (resp) => {
				clearTimeout(envioTimeout);
				envioTimeout = setTimeout(() => updateEnvioState(0), 2500);
				updateEnvioState(resp && resp.success ? 2 : 3);
			}
			const failureCallback = () => {
				clearTimeout(envioTimeout);
				envioTimeout = setTimeout(() => updateEnvioState(0), 2500);
				updateEnvioState(3);
			};

			enviar = fomularioContacto.getElementsByClassName("is-invalid").length === 0;

			if (enviar) {
				clearTimeout(envioTimeout);
				envioTimeout = setTimeout(() => updateEnvioState(0), 2500);
				updateEnvioState(1);

				apifunctions.post(api.api.sitio.contacto, null, data, successCallback, failureCallback, failureCallback);
			}
		}
	}

	const validationClass = (classNames, val, minLength) => val && val.length > minLength ? `${classNames} is-valid` : `${classNames} is-invalid`;

	useEffect(() => document.querySelector('html').scrollTo(0, 0), []);

	return (
		<>
			<section className="last contacto">
				<h1 className="text-center my-5">CONTÁCTENOS</h1>
				<div className="container">
					<div className="row">
						<div className="col-sm-8 offset-sm-2 mb-5">
							<form id="fomularioContacto" onSubmit={() => (false)}>
								<div className="row">
									<div className="form-group col-sm-6">
										<input type="text" placeholder="Nombre y Apellido" name="NombreCompleto"
											className={validationClass("form-control", formState["NombreCompleto"], 10)}
											onChange={(evt) => updateFormState("NombreCompleto", evt.target.value)}
										/>
									</div>
									<div className="form-group col-sm-6">
										<input type="text" placeholder="Teléfono" name="Telefono"
											className={validationClass("form-control", formState["Telefono"], 5)}
											onChange={(evt) => updateFormState("Telefono", evt.target.value)}
										/>
									</div>
									<div className="form-group col-sm-6">
										<input type="email" placeholder="Su e-mail" name="Email"
											className={validationClass("form-control", formState["Email"], 10)}
											onChange={(evt) => updateFormState("Email", evt.target.value)}
										/>
									</div>
									<div className="form-group col-sm-6">
										<input type="text" name="Direccion" placeholder="Su dirección"
											className="form-control"
											onChange={(evt) => updateFormState("Direccion", evt.target.value)}
										/>
									</div>
								</div>

								<div className="row">
									<div className="form-group col-sm-6">
										<input type="text" name="Ciudad" placeholder="Ciudad"
											className="form-control"
											onChange={(evt) => updateFormState("Ciudad", evt.target.value)}
										/>
									</div>
									<div className="form-group col-sm-6">
										<input type="text" name="Provincia" placeholder="Provincia"
											className="form-control"
											onChange={(evt) => updateFormState("Provincia", evt.target.value)}
										/>
									</div>
								</div>
								<div className="form-group">
									<textarea className="form-control" placeholder="Sus comentarios" name="Mensaje" onChange={(evt) => updateFormState("Mensaje", evt.target.value)} rows="4"></textarea>
								</div>
								<AlertMensaje Estado={envioState} />
								<div className="text-center mb-3">
									<button type="button" onClick={() => SendMail()} className="btn btn-lg btn-primary"> ENVIAR <i className="fa fa-envelope-o" aria-hidden="true"></i> </button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default Contacto;
