import React, { Component } from 'react';
import ProductosFilterItem from './ProductosFilterItem.js';

export default (props) => {
    const { loading, filters, onShowMore, onChangeFilter } = props;

    return (
      <div className={`filter ${props.className ? props.className : ""}`}>
        <div className="card border-1 border-color-primary" >
          <div className="card-header font-weight m-0 card-title bg-white bb-none">
            <h5 className="m-0 p-1">{"Filtrar"}</h5>
          </div>
          {
            filters.sort((a,b)=>a.main ? -1 : b.main ? 1 : 0).map(
              (filter, i) => (
                filter.title != "" && filter.Opciones.length > 0 &&
                <ProductosFilterItem 
                    filter={filter} i={i} key={'ProductosFilterItem-'+i}
                    onShowMore={onShowMore}
                    loading={loading}
                    onChangeFilter={onChangeFilter}
                />
              )
            )
          }
        </div>
      </div>
    )
}
