import moment from 'moment';
import { GlobalConfigTienda, View } from '../../TiendaConfig';

const IsEqual = function (a, b) {
  if (a === b) return true;
  if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime();
  if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')) return a === b;
  if (a === null || a === undefined || b === null || b === undefined) return false;
  if (a.prototype !== b.prototype) return false;
  let keys = Object.keys(a);
  if (keys.length !== Object.keys(b).length) return false;
  return keys.every(k => IsEqual(a[k], b[k]));
};

export default {
  FormatDateTime: function (date) {
    return moment(date).format('DD/MM/YYYY HH:mm');
  },

  FormatDate: function (date) {
    return moment(date).format('DD/MM/YYYY');
  },

  FormatPriceWithoutDecimals: function (price, margenRentabilidad = 0) {
    return Math.round(price * (1 + margenRentabilidad / 100)).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  },

  FormatPrice: function (price, cantidadDecimales = 2, margenRentabilidad = 0) {
    try {
      if (cantidadDecimales == 0) {
        return Math.round(price * (1 + margenRentabilidad / 100)).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
      else {
        let price_ = price.toFixed(cantidadDecimales).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".").split(',');
        if (price_.length === 1) {
          price_[1] = "00";
        } else if (price_[1].length === 1) {
          price_[1] = price_[1] + "0";
        }
        return price_.join(',');
      }
    } catch {
      return "0,00"
    }
  },

  IsEqual: IsEqual,

  ExtractParams: function (uri) {
    let result;
    try {
      let temp = uri.indexOf("?") >= 0 ? uri.substring(1) : uri;
      result = {};
      temp.split("&").forEach(item => {
        let parameters = item.split("=");
        if (parameters.length === 2) result[parameters[0].trim().toLowerCase()] = parameters[1].trim().toLowerCase();
      });
    } catch (e) {
      result = { search: '' };
    } finally {
      return result;
    }
  },

  isHTML: function (str) {
    var a = document.createElement('div');
    a.innerHTML = str;

    for (var c = a.childNodes, i = c.length; i--;) {
      if (c[i].nodeType === 1) return true;
    }

    return false;
  },

  isEmailValid: function (email) {
    if (email.indexOf("@") == -1 || email.indexOf(".") == -1) {
      return false;
    }
    return email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) != null;
  },

  validateChar: function (text, desiredLength) {
    return !(text == undefined || text == null || text.length < desiredLength);
  },

  validate(type, value, desiredLength) {
    let result = false;
    switch (type) {
      case 'text':
        result = this.ValidateChar(value, desiredLength);
        break;
      case 'email':
        result = this.isEmailValid(value);
        break;
      default:
        break;
    }
    return result;
  },

  getMaxQuantity: function (producto, talle_id, color_id) {
    let result = producto.IgnorarStock == undefined || producto.IgnorarStock ? GlobalConfigTienda.maxQuantity : -1;
    if (producto && !producto.IgnorarStock && Array.isArray(producto.MovimientosStock) && producto.MovimientosStock.length > 0) {
      producto.MovimientosStock.forEach(mov => {
        if (mov.Color_id == color_id && mov.Talle_id == talle_id) result = mov.Cantidad;
      });
    }
    return result;
  }

}
