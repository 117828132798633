import React from "react";
import { withRouter, Redirect } from "react-router";
import { LoginContext } from "./ParadigmaLoginProvider.js";

const AuthenticatedRoute = (props) => {
  const { component, ...componentProps } = props;
  const WrappedComponent = withRouter(component);

  return (
    <LoginContext.Consumer>
      {({ logged }) =>
        logged ? (
          <WrappedComponent {...componentProps} />
        ) : (
          <Redirect
            to={props.ifNotLoggedRedirectTo ? props.ifNotLoggedRedirectTo : "/"}
          />
        )
      }
    </LoginContext.Consumer>
  );
};

export default AuthenticatedRoute;
