import React from 'react';
import { Router, Route, Switch } from "react-router-dom";

import api from '../../api/api.js';
import ProductosController from './ProductosController.js';
import ProductoController from '../Producto/ProductoController';

const ProductosRouter = (props) => {
  return (
    <Router history={props.history}>
      <Switch>
        {/* Listar articulos destacados */}
        <Route path="/tienda/destacados/:code/" render={(props) => (<ProductosController {...props} getUrl={api.api.articulos.destacados} showFilter={false} />)} />
        {/* Todas las ofertas */}
        <Route path="/tienda/ofertas/-:filter" exact={true} render={(props) => (<ProductosController {...props} getUrl={api.api.articulos.ofertas.todasLasOfertas} />)} />
        <Route path="/tienda/ofertas/:code/" render={(props) => (<ProductosController {...props} getUrl={api.api.articulos.ofertas.ofertas} showFilter={false} showBanner={true} />)} />
        <Route path="/tienda/ofertas/" render={(props) => (<ProductosController {...props} getUrl={api.api.articulos.ofertas.todasLasOfertas} />)} />
        {/* Listar articulos */}
        <Route path={"/tienda/productos/-:filter"} exact={true} name={"productos"} render={(props) => (<ProductosController {...props} />)} />
        <Route path={"/tienda/productos/:principal"} exact={true} name={"productos"} render={(props) => (<ProductosController {...props} />)} />
        <Route path={"/tienda/productos/:principal/-:filter"} exact={true} name={"productos"} render={(props) => (<ProductosController {...props} />)} />
        <Route path={"/tienda/productos/:principal/:name"} exact={true} name={"productos"} render={(props) => (<ProductosController {...props} />)} />
        <Route path={"/tienda/productos/:principal/:name/-:filter"} exact={true} name={"productos"} render={(props) => (<ProductosController {...props} />)} />
        <Route path={"/tienda/productos/:principal/-:filter/:name/"} exact={false} name={"productos"} render={(props) => (<ProductosController {...props} />)} />
        <Route path={"/tienda/productos/:principal/-:filter/:name/"} exact={false} name={"productos"} render={(props) => (<ProductosController {...props} />)} />
        <Route path={"/tienda/productos/"} exact={true} name={"productos"} render={(props) => (<ProductosController {...props} />)} />

        {/* ver articulo */}
        <Route path={"/tienda/producto/:hash/"} exact={false} name={"producto"} render={props => (<ProductoController {...props} />)} />
      </Switch>
    </Router>
  );
}

export default ProductosRouter;
