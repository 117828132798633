
export const ClickInLogin = () => {
	let node = document.getElementById("OpenLoginModalBtn");
	if (node && node.click) node.click();
}
export const RedirectToCheckout = (history) => history && history.push('/tienda/checkout');

export const onClickEventHandler = (callbackFn) => ((evt) => {
	evt.preventDefault();
	evt.stopPropagation();
	callbackFn && callbackFn(evt);
});

export const animate = (id) => {
	let node = document.getElementById(id);
	let className = "animate";
	const removeClass = () => {
		if (node.classList.contains(className)) node.classList.remove(className);
	};
	if (node !== undefined && node !== null) {
		if (node.classList.contains(className)) node.classList.remove(className);
		setTimeout(removeClass, 4500);
		node.classList.add(className);
	}
};