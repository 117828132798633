import React, { Component, Fragment, useState, useEffect } from 'react';
import { Table, Container } from 'reactstrap';

import api from "../../../api/";
import apifunctions from "../../../api/functions.js";
import format from "../../../functions/functions.js";


const CuentasCorrientesView = (props) => {
  const [movimientos, setMovimientos] = useState([]);

  useEffect(() => {
    const successCallback = (response) => {
      let movs = response.data.movimientos;
      movimientos.forEach((mov) => {
        mov.Debito = mov.Importe >= 0 ? mov.Importe : 0;
        mov.Credito = mov.Importe < 0 ? mov.Importe : 0;
      });
      setMovimientos(movimientos);
    };
    const errorCallback = (response) => setMovimientos([]);

    apifunctions.get(api.api.clientes.cliente.resumenCuenta, null, 1, {}, successCallback, errorCallback);
  }, []);

  let acumulador = (array, propiedad) => {
    let result = 0;
    array.forEach(item => result += item[propiedad]);
    return result;
  }

  const totalDebito = acumulador(movimientos, 'Debito');
  const totalCredito = acumulador(movimientos, 'Credito')
  const totalAcumulado = acumulador(movimientos, 'Importe');

  const formatPrice = (number) => (number >= 0) ? (
    <span>${format.FormatPrice(number)}</span>
  ) : (
    <span>- ${format.FormatPrice(Math.abs(number))}</span>
  );

  return (
    <Container fluid className="estadoCC-Container">
      <h2 className="title text-center mt-1 mt-sm-3">Estado de Cuenta</h2>
      <Table hover responsive className="my-4 table-striped ctaCorriente">
        <thead>
          <tr className="clearfix">
            <td colSpan={9} className="text-center"><strong>Resumen</strong></td>
          </tr>
          <tr className="d-none d-sm-none d-xs-none d-md-table-row d-lg-table-row d-xl-table-row">
            <th>Fecha</th>
            <th>Fecha de Vencimiento</th>
            <th>Concepto</th>
            <th>Leyenda</th>
            <th>Nroº Comprobante</th>
            <th>Debito</th>
            <th>Credito</th>
            <th>Saldo</th>
          </tr>
          <tr className="d-sm-table-row d-xs-table-row d-md-none d-lg-none d-xl-none">
            <td>Total Debito<span className="float-right">{formatPrice(totalDebito)}</span></td>
          </tr>
          <tr className="d-sm-table-row d-xs-table-row d-md-none d-lg-none d-xl-none">
            <td>Total Credito<span className="float-right">{formatPrice(totalCredito)}</span></td>
          </tr>
          <tr className="d-sm-table-row d-xs-table-row d-md-none d-lg-none d-xl-none">
            <td>Total Saldo<span className="float-right">{formatPrice(totalAcumulado)}</span></td>
          </tr>
        </thead>
        <tbody>
          {
            movimientos != undefined && movimientos.length > 0 ?
              movimientos.reverse().map(
                (x, i) => (
                  <tr key={'payments-' + i}>
                    <td>{format.FormatDate(x.Fecha)}</td>
                    <td>{format.FormatDate(x.FechaVencimiento)}</td>
                    <td>{x.TipoMovimiento}</td>
                    <td>{x.Leyenda}</td>
                    <td>{x.NroComprobante}</td>
                    <td>{formatPrice(x.Debito)}</td>
                    <td>{formatPrice(x.Credito)}</td>
                    <td>{formatPrice(x.Acumulado)}</td>
                  </tr>
                )
              )
              :
              <tr><td colSpan="9" className="noContent"><p className="text-center">No hay movimientos para mostrar</p></td></tr>
          }
        </tbody>
        {
          movimientos != undefined && movimientos.length > 0 ?
            <tfoot className="table-info">
              <tr className="d-none d-sm-none d-xs-none d-md-table-row d-lg-table-row d-xl-table-row">
                <td colSpan={5}>Total</td>
                <td>{formatPrice(totalDebito)}</td>
                <td>{formatPrice(totalCredito)}</td>
                <td>{formatPrice(totalAcumulado)}</td>
              </tr>
            </tfoot>
            :
            null
        }
      </Table>
    </Container>
  );
}

export default CuentasCorrientesView;
