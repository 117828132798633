import React, { Component } from "react";
import PropTypes from "prop-types";
import api from "../../api/api";
import apifunctions from "../../api/functions.js";
import { generatePath } from "react-router";
import { View, GlobalConfigTienda } from "../../../TiendaConfig";
import imgNotFound from "../../assets/image_not_found.png";

const fields = GlobalConfigTienda.articulosFields
  ? GlobalConfigTienda.articulosFields
  : [
      "code",
      "link",
      "Codigo",
      "Titulo",
      "id",
      "Imagenes",
      "maxQuantity",
      "disponibilidad",
      "MovimientosStock",
      "IgnorarStock",
      "PuedeComprar",
      "OfertaNxM_Precio",
      "Precio",
      "PrecioLista",
      "OfertaNxM_Cantidad",
      "PrecioSuperOferta",
      "DescuentoSuperOferta",
      "OfertaPeriodo_FechaDesde",
      "OfertaPeriodo_FechaHasta",
      "Descripcion",
      "Info",
      "Categoria",
      "MargenRentabilidad",
      "Rubro",
      "Rubro_id",
      "Talles",
      "Colores",
    ];

export default class ProductosController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: [],
      productos: [],
      breadcrumbs: [],

      loading: false,
      reloading: false,

      chosenPage: 0,
      totalPages: 0,
      bannerSrc: null,
      totalProductos: 0,
    };
  }

  static propTypes = {
    showFilter: PropTypes.bool,
    showBanner: PropTypes.bool,
  };

  static defaultProps = {
    showFilter: true,
    showBanner: false,
  };

  getUrl() {
    let url = this.props.getUrl
      ? this.props.getUrl
      : api.api.articulos.articulos;
    let code = this.props.match.params.code
      ? this.props.match.params.code
      : null;
    let filter = this.props.match.params.filter
      ? this.props.match.params.filter
      : null;
    let principal = this.props.match.params.principal
      ? this.props.match.params.principal
      : null;

    if (this.props.getUrl) {
      if (code != null) url += code ? code : "";
      if (filter != null) url += filter ? "-" + filter : "";
      if (url.slice(-1) != "/") url += "/";
    } else {
      if (principal != null) url += principal ? principal : "";
      if (filter != null) url += filter && filter != "" ? "-" + filter : "";
      if (url.slice(-1) != "/") url += "/";
    }

    return url;
  }

  getArticulos(reintento = 0) {
    const { loading } = this.state;

    let search = new URLSearchParams(this.props.location.search);

    document.querySelector("html").scrollTo(0, 0);

    const getAndProcess = () => {
      let filters = [];

      if (search.has("price[lte]") && search.has("price[gte]")) {
        filters = [
          "price[gte]=" + search.get("price[gte]"),
          "price[lte]=" + search.get("price[lte]"),
        ];
      }

      if (search.has("search")) {
        filters.push("search=" + search.get("search"));
      }

      const params = {
        sort: search.has("sort") ? [search.get("sort")] : ["Precio"],
        pageSize: search.has("pageSize") ? [search.get("pageSize")] : 24,
        filters: filters,
        page: search.has("page") ? search.get("page") : 0,
        fields: fields,
      };

      const successCallback = (data) =>
        this.setState((prevState) => {
          document.querySelector("html").scrollTo(0, 0);
          data.data.filters.forEach((f) => {
            if (f.Opciones.filter((item) => item.checked == true).length > 0)
              f.showMore = true;
          });
          data.data.productos.forEach((art) => {
            if (art.Imagenes.length === 0)
              art.Imagenes.push({
                src: imgNotFound,
                talle_id: null,
                color_id: null,
              });
          });
          prevState.productos = data.data.productos;
          prevState.sorts =
            data.data.sorts && data.data.sorts.length > 0
              ? data.data.sorts.filter((s) => s.enabled == true)
              : [];
          prevState.filters = data.data.filters;
          prevState.breadcrumbs = data.data.breadcrumbs;
          prevState.loading = false;
          prevState.reloading = false;
          prevState.priceFilter = data.data.prices;
          prevState.chosenPage = data.meta.page;
          prevState.totalPages = data.meta.totalPages;
          prevState.bannerSrc = data.data.banner_url
            ? data.data.banner_url
            : null;
          prevState.totalProductos = data.meta.totalRowsFiltered;
          return prevState;
        });
      const failureCallback = () => {
        if (reintento > 3) {
          this.props.history && this.props.history.push("/");
        } else {
          this.getArticulos(reintento + 1);
        }
      };

      apifunctions.get(
        this.getUrl(),
        null,
        1,
        params,
        successCallback,
        failureCallback,
        failureCallback
      );
    };

    this.setState({ reloading: !loading }, getAndProcess);
  }

  componentDidMount() {
    this.getArticulos();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.getArticulos();
    }
  }

  onChangeSearch(value, key) {
    const { history, location } = this.props;
    let obj_search = new URLSearchParams(location.search);
    obj_search.set(key, value);
    if (key == "pageSize") {
      obj_search.set("page", 0);
    }
    if (obj_search.toString() !== "") {
      history.push(location.pathname + "?" + obj_search.toString());
    }
  }

  removeCodesFromUrl(filtersToRemove) {
    if (this.props.getUrl) {
      if (this.props.getUrl === api.api.articulos.ofertas.todasLasOfertas) {
        //filter es identifier + code
        let newFilter = "";
        let currentActiveFilters = this.props.match.params.filter
          ? (" " + this.props.match.params.filter).slice(1)
          : "";
        let newActiveFilters = [];

        currentActiveFilters = currentActiveFilters.replaceAll("-", "");
        currentActiveFilters = currentActiveFilters.match(/.{1,5}/g);
        currentActiveFilters.forEach((currentActiveFilter) => {
          let esta = false;
          filtersToRemove.forEach((filterToDelete) => {
            esta = currentActiveFilter == filterToDelete || esta;
          });
          if (!esta) {
            newActiveFilters.push(currentActiveFilter);
          }
        });
        newFilter = newActiveFilters.join("");

        let path =
          newFilter == "" ? "/tienda/ofertas/" : "/tienda/ofertas/-:filter";
        this.props.history.push({
          pathname: generatePath(path, {
            filter: newFilter,
          }),
        });
      }
    } else {
      //filter es identifier + code
      let principal = this.props.match.params.principal
        ? this.props.match.params.principal
        : "";
      let newFilter = "";
      let currentActiveFilters = this.props.match.params.filter
        ? (" " + this.props.match.params.filter).slice(1)
        : "";
      let newActiveFilters = [];

      currentActiveFilters = currentActiveFilters.replaceAll("-", "");
      currentActiveFilters = currentActiveFilters.match(/.{1,5}/g);
      currentActiveFilters.forEach((currentActiveFilter) => {
        let esta = false;
        filtersToRemove.forEach((filterToDelete) => {
          esta = currentActiveFilter == filterToDelete || esta;
        });
        if (!esta) {
          newActiveFilters.push(currentActiveFilter);
        }
      });
      newFilter = newActiveFilters.join("");

      let path = "";
      if (principal && principal != "") {
        path =
          newFilter == ""
            ? "/tienda/productos/:principal/"
            : "/tienda/productos/:principal/-:filter";
      } else {
        path =
          newFilter == "" ? "/tienda/productos/" : "/tienda/productos/-:filter";
      }
      this.props.history.push({
        pathname: generatePath(path, {
          principal: principal,
          filter: newFilter,
        }),
      });
    }
  }

  onChangeFilter(identifier, code, checked) {
    if (this.props.getUrl) {
      if (this.props.getUrl === api.api.articulos.ofertas.todasLasOfertas) {
        let newFilter = this.props.match.params.filter
          ? (" " + this.props.match.params.filter).slice(1)
          : "";
        newFilter = checked
          ? newFilter.replace(identifier + code, "")
          : newFilter + identifier + code;
        let path =
          newFilter == "" ? "/tienda/ofertas/" : "/tienda/ofertas/-:filter";
        this.props.history.push({
          pathname: generatePath(path, {
            filter: newFilter,
          }),
        });
      }
    } else {
      let principal = this.props.match.params.principal
        ? this.props.match.params.principal
        : "";
      let newFilter = this.props.match.params.filter
        ? (" " + this.props.match.params.filter).slice(1)
        : "";
      newFilter = checked
        ? newFilter.replace(identifier + code, "")
        : newFilter + identifier + code;
      let path = "";
      if (principal && principal != "") {
        path =
          newFilter == ""
            ? "/tienda/productos/:principal/"
            : "/tienda/productos/:principal/-:filter";
      } else {
        path =
          newFilter == "" ? "/tienda/productos/" : "/tienda/productos/-:filter";
      }
      this.props.history.push({
        pathname: generatePath(path, {
          principal: principal,
          filter: newFilter,
        }),
      });
    }
  }

  render() {
    const {
      filters,
      productos,
      breadcrumbs,
      loading,
      reloading,
      chosenPage,
      totalPages,
      bannerSrc,
      totalProductos,
      sorts,
    } = this.state;

    let search = new URLSearchParams(this.props.location.search);

    let onShowMore = (index, show) =>
      this.setState({
        filters: filters.map((f, i) => {
          if (i == index)
            f.showMore =
              show ||
              f.Opciones.filter((item) => item.checked == true).length > 0;
          return f;
        }),
      });

    let OnChangeNavigationBar = (value) =>
      document.querySelector("html").scrollTo(0, 0) &
      this.onChangeSearch(value, "page");
    let onChangeSearch = (value, key) => this.onChangeSearch(value, key);
    let onChangeFilter = (ident, code, checked) =>
      this.onChangeFilter(ident, code, checked);
    let removeCodesFromUrl = (filters) => this.removeCodesFromUrl(filters);

    let destacadosOrOfertas = this.props.getUrl ? true : false;
    let propsChildren = {
      filters,
      productos,
      breadcrumbs,
      loading,
      reloading,
      chosenPage,
      totalPages,
      search,
      bannerSrc,
      onChangeSearch,
      onChangeFilter,
      removeCodesFromUrl,
      onShowMore,
      OnChangeNavigationBar,
      totalProductos,
      destacadosOrOfertas,
      sorts,
    };

    return (
      <View.ProductosView
        {...propsChildren}
        showFilter={this.props.showFilter}
        showBanner={this.props.showBanner}
      />
    );
  }
}
