import React, { useState, useEffect } from 'react';

import apifunctions from '../../api/functions.js'
import api from '../../api/api.js'

import { View } from "../../../TiendaConfig";

import imagenNoDisponible from '../../assets/image_not_found.png';


const DestacadosController = (props) => {
  const [destacados, setDestacados] = useState([]);
  const { title, updateURL } = props;

  useEffect(() => {
    const getAndProcess = (reintento = 0) => {

      const successCallback = (response) => {
        let array = [];
        try {
          let data = response.data;
          if (data != null && data.Destacados && data.Destacados.length > 0) array = data.Destacados;
          array.forEach((item) => {
            if (item.Articulos) item.Articulos.forEach(art => {
              if (art.Imagenes.length === 0) art.Imagenes.push({src: imagenNoDisponible, talle_id: null, color_id: null});
            })
          });
          setDestacados(array);
        } catch (e) {
          array = [];
          setDestacados(array);
        }
      };
      const failureCallback = () => {
        if (reintento > 3)
          setDestacados([]);
        else
          getAndProcess(reintento + 1);
      };

      apifunctions.get(
        api.api.articulos.destacados,
        null,
        1,
        { filters: ['quantity=8'] },
        successCallback,
        failureCallback,
        failureCallback
      );

    };
    getAndProcess();
  }, []);

  return destacados && destacados.length > 0 && (
    <View.DestacadosView
      destacados={destacados}
      title={title}
      updateURL={updateURL}
    />
  );
}

export default DestacadosController;
