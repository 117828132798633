import React, { useState, useEffect, Component } from 'react';
import {
	ModalHeader, Modal, ModalBody, NavLink,
	Button, Row, Col, Input, InputGroup,
	InputGroupAddon, InputGroupText,
} from 'reactstrap';

import { LoginContext } from "../ParadigmaLoginProvider";

const LoginModal = (props) => {
	const { showModal, toggle, openRegisterModal, openForgotPasswordModal } = props;
	const [showPass, setShowPass] = useState(false);
	const [loginData, setLoginData] = useState({
		username: "",
		password: "",
	});
	const [waitingForResponse, setWaiting] = useState(false);
	useEffect(() => {
		setLoginData({ username: "", password: "" });
		setWaiting(false);
	}, [props.showModal]);

	const canLogin = () => {
		if (loginData.username === "") {
			let node = document.getElementById("usernameInput");
			if (node && node.focus) node.focus();
			return false;
		}
		if (loginData.password === "") {
			let node = document.getElementById("passwordInput");
			if (node && node.focus) node.focus();
			return false;
		}
		return true;
	}

	return (
		<LoginContext.Consumer>
			{
				({ login, errorMsg }) => (
					<Modal size="md" centered isOpen={showModal} toggle={toggle}>
						<ModalHeader className="px-4">
							Ingresá a tu cuenta ó Registrate
						</ModalHeader>
						<ModalBody className="px-4">
							<InputGroup className="mb-3">
								<InputGroupAddon addonType="prepend">
									<InputGroupText className=""><i className="fa fa-envelope fa-1" aria-hidden="true"></i></InputGroupText>
								</InputGroupAddon>
								<Input
									value={loginData.username}
									id="usernameInput"
									disabled={waitingForResponse}
									onKeyDown={(e) => {
										if (e.keyCode === 13 && canLogin()) {
											const callback = (result) => {
												if (result && toggle) toggle();
											};
											login(loginData.username, loginData.password, callback);
										}
									}}
									onChange={(e) => setLoginData({ ...loginData, username: e.target.value, })}
									type="text"
									placeholder="Email"
									autoComplete="on"
								/>
							</InputGroup>

							<InputGroup className="">
								<InputGroupAddon addonType="prepend">
									<InputGroupText className="login-password-lock" ><i className="fa fa-lock" aria-hidden="true"></i></InputGroupText>
								</InputGroupAddon>
								<Input
									id="passwordInput"
									disabled={waitingForResponse}
									value={loginData.password}
									onKeyDown={(e) => {
										if (e.keyCode === 13 && canLogin()) {
											const callback = (result) => {
												if (result && toggle) toggle();
											};
											login(loginData.username, loginData.password, callback);
										}
									}}
									onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
									type={showPass ? "text" : "password"}
									placeholder="Contraseña"
									autoComplete="on"
								/>
								<InputGroupAddon addonType="append">
									<InputGroupText className="cursor-pointer" onClick={() => setShowPass(!!!showPass)}>
										<span className="rounded-pill">
											<i className={showPass ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i>
										</span>
									</InputGroupText>
								</InputGroupAddon>
							</InputGroup>

							<div className="text-right forgot-pass">
								<small className="p-0 link-forgot-pass" onClick={() => openForgotPasswordModal()}>¿Olvido su contraseña?</small>
							</div>

							<h6 className={"text-danger"}>{errorMsg}</h6>
							<Row>
								<Col xs={12} md={6} className="mx-auto my-1">
									<Button
										disabled={!(loginData.username !== "" && loginData.password !== "") || waitingForResponse}
										color="primary" className="px-4 py-2 w-100"
										onClick={() => {
											if (canLogin()) {
												const callback = (result) => {
													if (result && toggle) toggle();
												};
												login(loginData.username, loginData.password, callback);
											}
										}}
									>
										{
											waitingForResponse ?
												<><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> ESPERE...</>
												:
												<><i className="fa fa-sign-in" aria-hidden="true"></i> INGRESAR</>
										}
									</Button>
								</Col>
								<Col xs={12} md={6} className="mx-auto my-1">
									<Button
										disabled={waitingForResponse}
										onClick={openRegisterModal}
										color="success" className="px-4 py-2 w-100"
									><i className="fa fa-user-plus" aria-hidden="true"></i> REGISTRARME
									</Button>
								</Col>
							</Row>
						</ModalBody>
					</Modal>
				)
			}
		</LoginContext.Consumer>
	);
}

export default LoginModal;
