import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CartContext from "./components/Tienda/components/ParadigmaCartProvider";
import LoginContext from "./components/Tienda/components/ParadigmaLoginProvider";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
// import Home from "./views/Home/Home";
import Tienda from "./views/Tienda/Tienda";
import Contacto from "./views/Contacto/Contacto";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Login from "./views/Login/Login";
// import Producto from './views/Producto/Producto';

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <LoginContext>
          <CartContext>
            <Header />
            <div className="min-height-body">
              <Switch>
                <Route
                  path="/contacto"
                  render={(props) => <Contacto {...props} />}
                />
                <Route
                  path="/tienda/"
                  render={(props) => <Tienda {...props} />}
                />
                <Route path="/" exact component={Login} />
              </Switch>
            </div>
            <Footer />
          </CartContext>
        </LoginContext>
      </ScrollToTop>
    </Router>
  );
};

export default App;
