import React, { Component, useState, useEffect } from 'react';
import {
	Row, Col, Container, Collapse, Card, Badge,
	Button, UncontrolledTooltip
} from 'reactstrap';

import { CartContext } from '../../../components/ParadigmaCartProvider';

import api from '../../../api/';
import apifunctions from '../../../api/functions.js';
import format from "../../../functions/functions.js";

const RecomprarBtn = (props) => {
	const { pedido } = props;

	return (
		<CartContext.Consumer>
			{
				({ setItems, data, updateData }) => (
					<>
						<Button id={`pedido-${pedido.Numero}`} color="primary" className="p-1" size="sm" onClick={(evt) => {
							evt.preventDefault();
							evt.stopPropagation();
							const newCart = data && data.articulos ? data.articulos.map(art => ({
								code: art.code,
								quantity: art.quantity,
								color_id: art.color_id,
								talle_id: art.talle_id,
							})) : [];
							pedido && pedido.Detalle && pedido.Detalle.forEach(pa => {
								if (newCart.filter(art => art.code == pa.code && pa.color_id == art.color_id && art.talle_id == pa.talle_id).length == 0) {
									newCart.push({ code: pa.code, quantity: pa.Cantidad, color_id: pa.color_id, talle_id: pa.talle_id })
								}
							});
							setItems(newCart, () => updateData());
						}}>
							<small>
								<i className="fa fa-plus" aria-hidden="true"></i> <i className="fa fa-shopping-cart" aria-hidden="true"></i>
							</small>
						</Button>
						<UncontrolledTooltip placement="bottom" target={`pedido-${pedido.Numero}`}>
							<div className="bg-info rounded p-1 m-1">
								Volver a Comprar
							</div>
						</UncontrolledTooltip>
					</>
				)
			}
		</CartContext.Consumer>
	);
}

const Pedido = (props) => {

	const BulletColor = (estado) => {
		let result = "danger";
		if (estado == "PE" || estado == "PR" || estado == "PP") result = "warning";
		if (estado == "CA") result = "danger";
		if (estado == "CO" || estado == "FA") result = "success";
		return result;
	}
	const BulletText = (estado) => {
		let result = "CANCELADA";
		if (estado == "PE") result = "PENDIENTE";
		if (estado == "CA") result = "CANCELADA";
		if (estado == "FA") result = "FACTURADO";
		if (estado == "PR" || estado == "PP") result = "PARA FACTURAR";
		if (estado == "CO") result = "CONFIRMADA";
		return result;
	}

	const { history, pedido } = props;

	const [collapse, setCollapse] = useState(false);

	return (
		<Card body className="pedido-card text-left">
			<div className="h4 cursor-pointer" onClick={() => setCollapse(!collapse)}>
				{
					collapse == false ?
						<i className="text-left fa fa-angle-down" aria-hidden="true"></i>
						:
						<i className="text-left fa fa-angle-up" aria-hidden="true"></i>
				}
				{" "}<Badge color={BulletColor(pedido.Estado)}>{BulletText(pedido.Estado)}</Badge>
				{" "}Compra realizada el {format.FormatDateTime(pedido.Fecha)}
				<div className="float-right">
					{
						collapse == false &&
						<strong className="px-1">
							Total{" "}
							{"$" + format.FormatPrice(pedido.Total)}
						</strong>
					}
					<RecomprarBtn history={history} pedido={pedido} />
				</div>

				<div className="clearfix" />
			</div>
			<Collapse isOpen={collapse}>
				<div className="my-2">Condición de Pago: <strong>{pedido.DetalleCondicionDePago}</strong></div>
				{
					pedido.Observacion != null && pedido.Observacion.length > 0 &&
					<div className="my-1">Observaciones: {pedido.Observacion}</div>
				}
				<div className="d-none d-sm-none d-xs-none d-md-block d-lg-block d-xl-block">
					<Row>
						<Col xs={2}>
							<strong>Codigo</strong>
						</Col>
						<Col xs={5} lg={5}>
							<strong>Descripción</strong>
						</Col>
						<Col className="text-nowrap" xs={1} lg={1}>
							<strong>Cant.</strong>
						</Col>
						<Col className="text-right" xs={2} lg={2}>
							<strong>Precio</strong>
						</Col>
						<Col className="text-right" xs={2} lg={2}>
							<strong>Importe</strong>
						</Col>
					</Row>
					{
						pedido.Detalle.map((x, i) =>
							<Row key={`detalle-${i}`} className={i % 2 == 0 ? "row-articulos bg-light" : "row-articulos bg-white"}>
								<Col xs={2} className="my-auto">
									{x.CodigoArticulo}
								</Col>
								<Col xs={5} lg={5} className="my-auto">
									{x.NombreArticulo}{" "}
									{
										x.Talle &&
										<Badge className="my-auto" color="info">TALLE: {x.Talle}</Badge>
									}
									{" "}
									{
										x.Color &&
										<Badge className="my-auto" color="info">COLOR: {x.Color}</Badge>
									}
								</Col>
								<Col className="text-center my-auto" xs={1} lg={1}>
									{x.Cantidad}
								</Col>
								<Col className="text-right my-auto" xs={2} lg={2}>
									{"$" + format.FormatPrice(x.PrecioUnitarioConIVA)}
								</Col>
								<Col className="text-right my-auto" xs={2} lg={2}>
									{"$" + format.FormatPrice(x.TotalConIVA)}
								</Col>
							</Row>
						)
					}
				</div>
				<div className="d-sm-block d-xs-block d-md-none d-lg-none d-xl-none">
					{
						pedido.Detalle.map((x, i) =>
							<table key={"articulo-" + i} className="w-100 mt-2 border-top">
								<tbody>
									<tr className="bg-softgray">
										<td colSpan={2} className="bg-secondary">{x.CodigoArticulo}</td>
									</tr>
									<tr className="bg-softgray">
										<td>Titulo</td>
										<td className="text-right">
											{x.NombreArticulo}{" "}
											{
												x.Talle &&
												<Badge className="my-auto" color="info">TALLE: {x.Talle}</Badge>
											}
											{" "}
											{
												x.Color &&
												<Badge className="my-auto" color="info">COLOR: {x.Color}</Badge>
											}
										</td>
									</tr>
									<tr className="bg-softgray">
										<td>Cant.</td>
										<td className="text-right">{x.Cantidad}</td>
									</tr>
									<tr className="bg-softgray">
										<td>Precio</td>
										<td className="text-right">{"$" + format.FormatPrice(x.PrecioUnitarioConIVA)}</td>
									</tr>
									<tr className="bg-softgray">
										<td>Importe</td>
										<td className="text-right">{"$" + format.FormatPrice(x.TotalConIVA)}</td>
									</tr>
								</tbody>
							</table>
						)
					}
				</div>
				<hr />
				<Row className="text-dark">
					<Col xs={10} className="text-right">
						<strong>Subtotal</strong>
					</Col>
					<Col className="text-right">
						<strong>{"$" + format.FormatPrice(pedido.SubTotal)}</strong>
					</Col>
				</Row>
				<Row className="text-dark">
					<Col xs={10} className="text-right">
						<strong>Impuestos</strong>
					</Col>
					<Col className="text-right">
						<strong>{"$" + format.FormatPrice(pedido.ImporteIVA + pedido.ImporteIIBB)}</strong>
					</Col>
				</Row>
				<Row className="text-dark">
					<Col xs={10} className="text-right">
						<strong>Total</strong>
					</Col>
					<Col className="text-right">
						<strong>{"$" + format.FormatPrice(pedido.Total)}</strong>
					</Col>
				</Row>
			</Collapse>
		</Card>
	);
}

const Pedidos = (props) => {

	const [pedidos, setPedidos] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const successCallback = (response) => setLoading(false) & setPedidos(response && response.data ? response.data : []);
		const failureCallback = () => setPedidos([]) & setLoading(false);
		setLoading(true);
		apifunctions.get(api.api.clientes.cliente.pedidos, null, null, {}, successCallback, failureCallback, failureCallback);
	}, []);

	const { history } = props;

	return (
		<Container className="pedidosContainer">
			<h2 className="title text-center mt-1 mt-sm-3">Historial de Compras</h2>
			<div className="my-4 text-center">
				{
					pedidos != undefined && pedidos.length > 0 ? (
						pedidos.map((x, i) => <Pedido key={'pedidos-' + i} history={history} pedido={x} />)
					) : (
						loading ? <><i className="fa fa-spinner fa-spin mr-2"></i> Solicitando ultimas compras</> : "No hay compras para mostrar"
					)
				}
			</div>
		</Container>
	);
}

export default Pedidos;