import React from 'react';
import { Link } from 'react-router-dom';
import { Nav, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { BeatLoader } from 'react-spinners';

const ProductosHeader = (props) => {
  const { breadcrumbs, loading } = props;
  return (
    <div className="esige-producto-controls px-4 px-md-0 mt-1">
      <Nav>
        <Breadcrumb>
          <BreadcrumbItem><Link to="/">INICIO</Link></BreadcrumbItem>
          {
            loading ? (
              <BreadcrumbItem active={true} className="breadcrumb-loader">
                <BeatLoader />
              </BreadcrumbItem>
            ) : (
              breadcrumbs.map((x, i) =>
                <BreadcrumbItem key={"breadcrumb-" + i} active={breadcrumbs.length === i ? true : false}>
                  <Link to={"/tienda/productos/" + x.code + "/" + breadcrumbs.filter((y, j) => j <= i).map(x => x.Nombre).join('-').split(' ').join('-')}>{x.Nombre.toUpperCase()}</Link>
                </BreadcrumbItem>
              )
            )
          }
        </Breadcrumb>
      </Nav>
    </div>
  );
}

export default ProductosHeader;
