import React, { useState, useEffect, useContext } from "react";
import { CartContext } from '../../../components/ParadigmaCartProvider';
import { Spinner, Alert } from 'reactstrap';
import api from '../../../api';
import apifunctions from '../../../api/functions';

export const MercadoPagoLinkGenerator = (props) => {
  const { code } = props;

  const [mpState, setMPState] = useState({
    formAction: '',
    publicKey: '',
    preference_id: '',
  });

  const [t, updateCounter] = useState(5);

  const [validationState, updateValidationState] = useState({
    success: false,
    retry: false,
    renderComponent: true,
    errorMsg: "Ocurrio un error en la generación del Link de Pago."
  });

  const generatePreference = (code) => {
    const failureCallback = (response) => {
      if (t > 0) {
        updateValidationState({
          errorMsg: "Fallo la comunicacion con el servidor",
          renderComponent: true,
          success: false,
          retry: true,
        });
        updateCounter(5);
      }
    }
    const successCallback = (response) => {
      const data = response.data;
      if (t > 0) {
        if (data.success) {
          updateCounter(-1);
          updateValidationState({
            errorMsg: "",
            renderComponent: true,
            success: true,
            retry: false,
          });
          setMPState({
            formAction: data.preference_link,
            publicKey: data.mp_public_key,
            preference_id: data.preference_id,
          });
        } else {
          if (data.MPDisabled) {
            updateCounter(-1);
            updateValidationState({
              renderComponent: false,
              success: false,
              errorMsg: "Ocurrio un error en la generación del Link de Pago.",
              retry: false,
            });
          } else {
            updateValidationState({
              renderComponent: true,
              errorMsg: "Ocurrio un error en la generación del Link de Pago.",
              success: false,
              retry: true,
            });
            updateCounter(5);
          }
        }
      } else {
        if (data.success) {
          updateCounter(-1);
          updateValidationState({
            errorMsg: "",
            renderComponent: true,
            success: true,
            retry: false,
          });
          setMPState({
            formAction: data.preference_link,
            publicKey: data.mp_public_key,
            preference_id: data.preference_id,
          });
        } else {
          if (data.MPDisabled) {
            updateCounter(-1);
            updateValidationState({
              renderComponent: false,
              success: false,
              errorMsg: "Ocurrio un error en la generación del Link de Pago.",
              retry: false,
            });
          } else {
            updateCounter(-1);
            updateValidationState({
              renderComponent: true,
              errorMsg: "Ocurrio un error en la generación del Link de Pago.",
              success: false,
              retry: true,
            });
          }
        }
      }
    };
    apifunctions.post(api.api.ventas.mercadopago.generarPreferencia, code, {}, successCallback, failureCallback, failureCallback);
  }

  useEffect(() => {
    if (validationState.success) {
      if (mpState.formAction != '') window.location.href = mpState.formAction;
    }
  }, [mpState.formAction]);

  useEffect(() => {
    if (t == 0) generatePreference(code);
    else if (t > 0) setTimeout(() => updateCounter(t - 1), 1000);
  }, [t]);

  useEffect(() => generatePreference(code), []);

  return validationState.renderComponent && (
    <div className="text-center">
      {
        !validationState.retry && !validationState.success &&
        <Spinner color="dark" />
      }
      {
        validationState.retry && !validationState.success && t > 0 &&
        <Alert color="primary">{validationState.errorMsg} Se Reintentara en {t} segs.</Alert>
      }
      {
        validationState.retry && !validationState.success && t == 0 &&
        <Alert color="danger">{validationState.errorMsg}</Alert>
      }
      {
        validationState.success && mpState.formAction != '' &&
        <>
          <form id="mp-container" action={mpState.formAction}></form>
          <script src="https://sdk.mercadopago.com/js/v2" ></script>
        </>
      }
    </div>
  );
}

const MPRHInnerComponent = (props) => {
  const {history, location, match } = props;
  const { articulos, setItems, updateData } = props;

  const [success, updateSuccess] = useState(false);
  const [loading, updateLoading] = useState(true);
  const [redirectIn, updateRedirector] = useState(4);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    let payment_id = params.has('payment_id') ? (params.get('payment_id') == "null" ? null : params.get('payment_id')) : '';
    let merchant_order_id = params.has('merchant_order_id') ? (params.get('merchant_order_id') == "null" ? null : params.get('merchant_order_id')) : '';
    let status = params.has('status') ? (params.get('status') == "null" ? null : params.get('status')) : '';
    const data = {
      payment_id, merchant_order_id, status
    };

    let code = match && match.params && match.params.code ? match.params.code : '';

    const successCallback = (response) => {
      updateLoading(false);
      if (response.data) {
        updateSuccess(response && response.data && response.data.confirmed);
        let data = response.data;

        const merge = (newCart, setItems, updateData, history) => {
          const callback = () => history.push("/tienda/checkout");
          setItems(newCart, () => updateData());
        };

        const newCart = articulos && articulos.length > 0 ? articulos.map(art => ({
          code: art.code,
          color_id:art.color_id,
          talle_id:art.talle_id,
          quantity: art.quantity,
        })) : [];
        data && data.cartItems && data.cartItems.forEach(pa => {
          if (newCart.filter(art => art.code == pa.code).length == 0) {
            newCart.push({ code: pa.code, quantity: pa.quantity, talle_id: pa.talle_id, color_id:pa.color_id })
          }
        });
        merge(newCart, setItems, updateData, history);
        updateRedirector(3);
      } else {
        updateSuccess(false);
      }
    }
    const failureCallback = (response) => {
      updateLoading(false);
      updateSuccess(false);
    }

    apifunctions.put(api.api.ventas.mercadopago.procesarPreferencia, code, data, successCallback, failureCallback, failureCallback);
  }, []);

  useEffect(()=>{
    if(redirectIn == 0){
      history.push(success ? "/tienda/cliente/compras" : "/tienda/checkout");
    } else {
      setTimeout(()=>updateRedirector(redirectIn-1), 1000);
    }
  }, [redirectIn]);

  return (
    <div className="text-center">
      {
        loading ? (
          <Spinner color="dark" />
        ) : (
          <div>
            {
              <Alert color={success ? "success" : "danger"}>
                {
                  success ? (
                    `El pago ha sido realizado con exito. Sera Redirigido en ${redirectIn} segs.`
                  ) : (
                    `El pago ha sido abortado. Sera Redirigido en ${redirectIn} segs.`
                  )
                }
              </Alert>
            }
          </div>
        )
      }
    </div>
  );
}

export const MercadoPagoResponseHandler = (props) => (
  <CartContext.Consumer>
    {
      ({ articulos, setItems, updateData }) => (<MPRHInnerComponent {...{ articulos, setItems, updateData }} {...props} />)
    }
  </CartContext.Consumer>
);
