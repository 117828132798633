import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Alert,
  Label,
  Input,
  // FormFeedback,
} from "reactstrap";
import api from "../../../api/api.js";
import apifunctions from "../../../api/functions.js";
import { LoginContext } from "../../../components/ParadigmaLoginProvider/ParadigmaLoginProvider.js";

import InputPassword from "../../../components/PasswordScheme/PasswordInput";

const that = {
  validaTimeout: null,
  editedSuccess: null,
  notifiedSuccess: null,
};

const password_validator = {
  verifyLength: function (text = "", minlength = 8) {
    return text !== undefined && text !== null && text.length >= minlength;
  },
  verifyContainsLetters: function (text = "", minQuantity = 3) {
    if (text != undefined && text != null && text.length >= minQuantity) {
      let counter = 0;
      for (let index = 0; index < text.length; index++) {
        if (text.charAt(index) >= "a" && text.charAt(index) <= "z")
          counter += 1;
        if (text.charAt(index) >= "A" && text.charAt(index) <= "Z")
          counter += 1;
      }
      return counter >= minQuantity;
    }
    return false;
  },
  verifyContainsNumbers: function (text = "", minQuantity = 3) {
    if (text != undefined && text != null && text.length >= minQuantity) {
      let counter = 0;
      for (let index = 0; index < text.length; index++) {
        if (text.charAt(index) >= "0" && text.charAt(index) <= "9")
          counter += 1;
      }
      return counter >= minQuantity;
    }
    return false;
  },
  isPasswordValid: function (text = "") {
    return (
      this.verifyContainsLetters(text, 6) &&
      this.verifyContainsNumbers(text, 2) &&
      this.verifyLength(text, 8)
    );
  },
};

const ValidarDNI = (nro) => {
  return nro.length >= 7 && nro.length < 9;
};

const ValidarCUIT = (cuit) => {
  if (cuit.length != 11) return false;
  let acumulado = 0;
  let digitos = cuit.split("");
  let digito = digitos.pop();
  for (let i = 0; i < digitos.length; i++) {
    acumulado += digitos[9 - i] * (2 + (i % 6));
  }
  let verif = 11 - (acumulado % 11);
  if (verif == 11) {
    verif = 0;
  } else if (verif == 10) {
    verif = 9;
  }
  return digito == verif;
};

const Perfil = (props) => {
  const [state, setState] = useState({
    Nombre: "",
    Email: "",
    Telefono: "",
    Domicilio: "",
    CodPostal: "",
    NroDoc: "",
    NuevaPassword: "",
    TipoDocumento_id: "1",
    SituacionIVA_id: "3",
    Localidad_id: "0001",
    MargenGananciaGeneral: 0,
    EsRevendedor: false,
  });

  const [validationState, updateValidationState] = useState({
    error: false,
    validaMail: false,
    sending: false,
  });

  const [showEditedSuccessAlert, updateShowEditedSuccessAlert] = useState(0);

  const validate = (type, value, desiredLength) => {
    let ValidateEmail = (mail) => {
      let result = false;
      if (mail.indexOf("@") == -1 || mail.indexOf(".") == -1) {
        result = false;
      } else {
        const timeoutFn = () => {
          clearTimeout(that.validaTimeout);
          updateValidationState({
            ...validationState,
            validaMail:
              mail.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) !=
              null,
          });
        };
        clearTimeout(that.validaTimeout);
        that.validaTimeout = setInterval(timeoutFn, 1000);
        result = true;
      }
      return result;
    };
    let ValidateChar = (text, desiredLength) => {
      return (
        (text == undefined || text == null || text.length < desiredLength) ==
        false
      );
    };
    let result = false;
    switch (type) {
      case "text":
        result = ValidateChar(value, desiredLength);
        break;
      case "email":
        result = ValidateEmail(value);
        break;
      default:
        break;
    }
    return result;
  };

  const [Localidades, SetLocalidades] = useState([]);
  const [TiposDocumentos, SetTiposDocumentos] = useState([]);
  const [SituacionesIVA, SetSituacionesIVA] = useState([]);

  const { refresh } = useContext(LoginContext);

  const isFormValid = () => {
    const isNombreValid = validate("text", state.Nombre, 10);
    const isEmailValid = validate("email", state.Email, 10);
    const isDomicilioValid = validate("text", state.Domicilio, 8);
    const isTipoDocValid = state.TipoDocumento_id != "";
    const isSituacionIVAValid = state.SituacionIVA_id != "";
    const isLocalidadValid = state.Localidad_id != "";
    const isCodPostalValid = validate("text", state.CodPostal, 4);
    const isPasswordValid =
      state.NuevaPassword == "" || validate("text", state.NuevaPassword, 8);
    const isNroDocValid =
      state.TipoDocumento_id == "3"
        ? ValidarCUIT(state.NroDoc)
        : ValidarDNI(state.NroDoc);

    const result =
      isNombreValid &&
      isEmailValid &&
      isDomicilioValid &&
      isCodPostalValid &&
      isNroDocValid &&
      isTipoDocValid &&
      isSituacionIVAValid &&
      isLocalidadValid &&
      isCodPostalValid &&
      isPasswordValid;

    return result;
  };

  const send = () => {
    const Perfil_SuccessCallback = (response) => {
      if (response && response.data) {
        let data = response.data;
        setState({
          Nombre: data.Nombre,
          Email: data.Email,
          Telefono: data.Telefono,
          Domicilio: data.Domicilio,
          CodPostal: data.CodPostal,
          NroDoc: data.NroDoc,
          NuevaPassword: "",
          TipoDocumento_id: data.TipoDocumento_id,
          SituacionIVA_id: data.SituacionIVA_id,
          Localidad_id: data.Localidad_id,
          MargenGananciaGeneral: data.MargenGananciaGeneral,
          EsRevendedor: data.EsRevendedor,
        });
        updateValidationState({ ...validationState, sending: false });

        updateShowEditedSuccessAlert(data.Edited ? 1 : 2);

        setTimeout(() => updateShowEditedSuccessAlert(0), 3500);
      }
    };
    const Perfil_FailureCallback = () =>
      updateValidationState({ ...validationState, sending: false });

    updateValidationState({ ...validationState, sending: true });
    apifunctions.post(
      api.api.clientes.cliente.perfil,
      null,
      state,
      Perfil_SuccessCallback,
      Perfil_FailureCallback,
      Perfil_FailureCallback
    );
  };

  useEffect(() => {
    const Localidades_SuccessCallback = (response) => {
      if (response.data) SetLocalidades(response.data);
    };
    const Localidades_FailureCallback = () => SetLocalidades([]);

    const SituacionIVA_SuccessCallback = (response) => {
      if (response.data) SetSituacionesIVA(response.data);
    };
    const SituacionIVA_FailureCallback = () => SetSituacionesIVA([]);

    const TiposDocumento_SuccessCallback = (response) => {
      if (response.data) SetTiposDocumentos(response.data);
    };
    const TiposDocumento_FailureCallback = () => SetTiposDocumentos([]);

    const Perfil_SuccessCallback = (response) => {
      if (response && response.data) {
        let data = response.data;
        setState({
          Nombre: data.Nombre,
          Email: data.Email,
          Telefono: data.Telefono,
          Domicilio: data.Domicilio,
          CodPostal: data.CodPostal,
          NroDoc: data.NroDoc,
          NuevaPassword: "",
          TipoDocumento_id: data.TipoDocumento_id,
          SituacionIVA_id: data.SituacionIVA_id,
          Localidad_id: data.Localidad_id,
          MargenGananciaGeneral: data.MargenGananciaGeneral,
          EsRevendedor: data.EsRevendedor,
        });
        refresh && refresh();
      }
    };
    const Perfil_FailureCallback = () => refresh && refresh();

    const params = { filters: ["paginationEnabled=False"] };

    apifunctions.get(
      api.api.clientes.cliente.localidades,
      null,
      1,
      params,
      Localidades_SuccessCallback,
      Localidades_FailureCallback,
      Localidades_FailureCallback
    );
    apifunctions.get(
      api.api.clientes.cliente.situacionesIVA,
      null,
      1,
      params,
      SituacionIVA_SuccessCallback,
      SituacionIVA_FailureCallback,
      SituacionIVA_FailureCallback
    );
    apifunctions.get(
      api.api.clientes.cliente.tiposdocumentos,
      null,
      1,
      params,
      TiposDocumento_SuccessCallback,
      TiposDocumento_FailureCallback,
      TiposDocumento_FailureCallback
    );

    apifunctions.get(
      api.api.clientes.cliente.perfil,
      null,
      1,
      {},
      Perfil_SuccessCallback,
      Perfil_FailureCallback,
      Perfil_FailureCallback
    );
  }, []);

  return (
    <Container className="container-perfil mb-2">
      <h2 className="title text-center mt-1 mt-sm-3">Editar Datos</h2>
      <Row>
        <Col xs={12} md={6}>
          <Label className="mt-4 mb-0" for="Nombre">
            Nombre / Razón Social
          </Label>
          <Input
            name="Nombre"
            type="text"
            className={
              validate("text", state.Nombre, 10)
                ? "rounded-lg border border-success"
                : "rounded-lg border border-danger"
            }
            value={state.Nombre}
            onChange={(e) =>
              setState({
                ...state,
                Nombre:
                  e.target.value != undefined &&
                  e.target.value != null &&
                  e.target.value.length > 0
                    ? e.target.value
                    : "",
              })
            }
          />
        </Col>
        <Col xs={12} md={6}>
          <Label className="mt-4 mb-0" for="Email">
            Email
          </Label>
          <Input
            type="email"
            name="Email"
            id="Email"
            placeholder="Ej: abc@gmail.com"
            className={"rounded-lg"}
            value={state.Email}
            disabled={true}
            onChange={(e) =>
              setState({
                ...state,
                Email:
                  e.target.value != undefined &&
                  e.target.value != null &&
                  e.target.value.length > 0
                    ? e.target.value
                    : "",
              })
            }
          />
        </Col>
        <Col xs={12} md={4}>
          <Label className="mt-4 mb-0" for="SituacionIVA_id">
            Condición de IVA
          </Label>
          <Input
            type="select"
            name="SituacionIVA_id"
            id="SituacionIVA_id"
            value={`${state.SituacionIVA_id}`}
            onChange={(e) =>
              setState({
                ...state,
                SituacionIVA_id: e.target.value,
                TipoDocumento_id:
                  e.target.value == "1" ? "3" : state.TipoDocumento_id,
              })
            }
          >
            {SituacionesIVA.map((td, index) => (
              <option key={`SituacionIVA-${index}`} value={`${td.Codigo}`}>
                {td.Nombre}
              </option>
            ))}
          </Input>
        </Col>
        <Col xs={12} md={4}>
          <Label className="mt-4 mb-0" for="TipoDocumento_id">
            Documento
          </Label>
          <Input
            type="select"
            name="TipoDocumento_id"
            id="TipoDocumento_id"
            value={`${state.TipoDocumento_id}`}
            onChange={(e) =>
              setState({ ...state, TipoDocumento_id: e.target.value })
            }
            disabled={state.SituacionIVA_id == "1"}
          >
            {TiposDocumentos.map((td, index) => (
              <option key={`TipoDoc-${index}`} value={`${td.Codigo}`}>
                {td.Nombre}
              </option>
            ))}
          </Input>
        </Col>
        <Col className="mt-4 mb-0" xs={12} md={4}>
          <Input
            type="number"
            min={"0"}
            max={"99999999999"}
            name="NroDoc"
            id="NroDoc"
            placeholder="Ej: 00000000000"
            value={state.NroDoc}
            className={
              (
                state.TipoDocumento_id == "3"
                  ? ValidarCUIT(state.NroDoc)
                  : ValidarDNI(state.NroDoc)
              )
                ? "rounded-lg border border-success"
                : "rounded-lg border border-danger"
            }
            onChange={(e) => setState({ ...state, NroDoc: e.target.value })}
          />
        </Col>
        <Col xs={12} md={4}>
          <Label className="mt-4 mb-0" for="Localidad_id">
            Localidad
          </Label>
          <Input
            type="select"
            name="Localidad_id"
            id="Localidad_id"
            value={`${state.Localidad_id}`}
            onChange={(e) =>
              setState({ ...state, Localidad_id: e.target.value })
            }
          >
            {Localidades.map((td, index) => (
              <option key={`Localidad-${index}`} value={td.Codigo}>
                {td.Nombre} - {td.Provincia}
              </option>
            ))}
          </Input>
        </Col>
        <Col xs={12} md={4}>
          <Label className="mt-4 mb-0" for="Domicilio">
            Domicilio
          </Label>
          <Input
            name="Domicilio"
            id="Domicilio"
            type="text"
            className={
              validate("text", state.Domicilio, 8)
                ? "rounded-lg border border-success"
                : "rounded-lg border border-danger"
            }
            value={state.Domicilio}
            onChange={(e) =>
              setState({
                ...state,
                Domicilio:
                  e.target.value != undefined &&
                  e.target.value != null &&
                  e.target.value.length > 0
                    ? e.target.value
                    : "",
              })
            }
          />
        </Col>

        <Col xs={12} md={4}>
          <Label className="mt-4 mb-0" for="CodPostal">
            Codigo Postal
          </Label>
          <Input
            name="CodPostal"
            id="CodPostal"
            type="text"
            placeholder="Ej: 8000"
            className={
              validate("text", state.CodPostal, 4)
                ? "rounded-lg border border-success"
                : "rounded-lg border border-danger"
            }
            value={state.CodPostal}
            maxLength="4"
            onChange={(e) =>
              setState({
                ...state,
                CodPostal:
                  e.target.value != undefined &&
                  e.target.value != null &&
                  e.target.value.length > 0
                    ? e.target.value
                    : "",
              })
            }
          />
        </Col>
        <Col xs={12} md={4}>
          <Label className="mt-4 mb-0" for="Telefono">
            Teléfono
          </Label>
          <Input
            name="Telefono"
            type="text"
            id="Telefono"
            placeholder="Celular ó Telefono Fijo"
            value={state.Telefono}
            onChange={(e) => setState({ ...state, Telefono: e.target.value })}
          />
        </Col>

        <Col xs={12} md={4} offset={4}>
          <Label className="mt-4 mb-0" for="MG">
            Margen de Ganancia:
          </Label>
          <Input
            name="MG"
            type="number"
            id="MG"
            placeholder="Margen de ganancia"
            value={localStorage.getItem("MG")}
            onChange={(e) => localStorage.setItem("MG", e.target.value)}
          />
        </Col>

        {state.EsRevendedor && (
          <Col xs={12} md={2}>
            <Label className="mt-4 mb-0" for="MargenRentabilidad">
              Margen de Rentabilidad (%)
            </Label>
            <Input
              className="text-right"
              name="MargenRentabilidad"
              type="number"
              id="MargenRentabilidad"
              min={0}
              placeholder="Ej: 25%"
              value={state.MargenGananciaGeneral}
              onChange={(e) =>
                setState({ ...state, MargenGananciaGeneral: e.target.value })
              }
            />
            <small>
              Los precios que figuran en Tienda han de incrementarse en base a
              dicho Margen
            </small>
          </Col>
        )}
      </Row>

      <hr />

      <InputPassword
        label="Contraseña"
        value={state.NuevaPassword}
        onChange={(newValue) =>
          setState({
            ...state,
            NuevaPassword:
              newValue != undefined && newValue != null && newValue.length > 0
                ? newValue
                : "",
          })
        }
      />
      {showEditedSuccessAlert > 0 && (
        <Alert>
          {showEditedSuccessAlert == 1
            ? "Se ha editado correctamente su información."
            : "La solicitud de edicion de su información ha sido registrada exitosamente."}
        </Alert>
      )}
      <Row>
        <Col className="text-center">
          <Button
            disabled={isFormValid() == false || validationState.sending == true}
            color="primary"
            className="cursor-pointer"
            onClick={send}
          >
            <i className="fa fa-floppy-o" aria-hidden="true"></i> Guardar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Perfil;
