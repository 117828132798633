import axios from "axios";
import api from "./api";

function getLastChar(string) {
  let charArray = string.split("");
  if (charArray.length > 0) return charArray[charArray.length - 1];
  else return "";
}

function getValue(value) {
  if (typeof value === "string") return value;
  else if (typeof value === "number") return value.toString();
  else if (typeof value === "function") return value().toString();
}

function generateUrl(url, id, depth, parameters) {
  let newurl = url;
  let lastChar = getLastChar(newurl);
  if (lastChar !== "/") newurl += "/";
  if (id) {
    if (url.indexOf("$id") !== -1) newurl = newurl.replace("$id", id);
    else newurl += getValue(id) + "/";
  }
  if (parameters) {
    newurl += "?";
    if (depth) newurl += "depth=" + getValue(depth) + "&";
    if (parameters.fields)
      newurl += "fields=" + parameters.fields.join(",") + "&";
    if (parameters.sort) newurl += "sort=" + parameters.sort.join(",") + "&";
    if (parameters.filters) newurl += parameters.filters.join("&") + "&";
    if (parameters.pageSize) newurl += "pageSize=" + parameters.pageSize + "&";
    if (parameters.page) newurl += "page=" + parameters.page + "&";
  } else {
    newurl += "?";
    if (depth) newurl += "depth=" + getValue(depth) + "&";
  }

  return newurl;
}

function ValidToken(token) {
  return (
    token !== undefined &&
    token !== null &&
    token !== btoa("") &&
    token !== btoa(undefined) &&
    token !== btoa(null)
  );
}

function Header() {
  let token = localStorage.getItem("token");
  if (ValidToken(token) === true) {
    token = atob(token).split('"').join("");
    return {
      headers: {
        Authorization: "Token " + token,
        "public-token": api.PUBLIC_TOKEN,
      },
    };
  } else {
    return {
      headers: { "public-token": api.PUBLIC_TOKEN },
    };
  }
}

export default {
  headers: () => Header(),
  isLogged: () => {
    // se asume logeado si existe token almacenado
    let token = localStorage.getItem("token");
    return (
      token !== undefined &&
      token !== null &&
      token !== btoa("") &&
      token !== btoa(undefined) &&
      token !== btoa(null)
    );
  },
  asyncGet: async (
    url,
    id,
    depth,
    parameters,
    successCallback,
    errorCallback,
    failCallback
  ) => {
    try {
      const response = await axios.get(
        generateUrl(url, id, depth, parameters),
        Header()
      );
      if (response.status === 200) {
        if (successCallback) successCallback(response.data);
      } else {
        if (errorCallback) errorCallback(response.data);
      }
      return response.data;
    } catch (error) {
      if (error) {
        if (errorCallback) errorCallback(error);
      } else {
        if (failCallback) failCallback(error);
      }
    }
  },
  get: (
    url,
    id,
    depth,
    parameters,
    successCallback,
    errorCallback,
    failCallback
  ) => {
    return axios
      .get(generateUrl(url, id, depth, parameters), Header())
      .then((response) => {
        if (response.status === 200) {
          if (successCallback) successCallback(response.data);
        } else {
          if (errorCallback) errorCallback(response.data);
        }
        return response.data;
      })
      .catch((error) => {
        let response = error.response;
        if (response) {
          if (errorCallback) errorCallback(response.data);
        } else {
          if (failCallback) failCallback(response);
        }
      });
  },
  asyncPost: async (
    url,
    id,
    data,
    successCallback,
    errorCallback,
    failCallback
  ) => {
    try {
      const response = await axios.post(generateUrl(url, id), data, Header());
      if (response.status === 200) {
        if (successCallback) successCallback(response.data);
      } else {
        if (errorCallback) errorCallback(response.data);
      }
      return response.data;
    } catch (error) {
      if (error) {
        if (errorCallback) errorCallback(error);
      } else {
        if (failCallback) failCallback(error);
      }
    }
  },
  post: (url, id, data, successCallback, errorCallback, failCallback) => {
    return axios
      .post(generateUrl(url, id), data, Header())
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          if (successCallback) successCallback(response.data);
        } else {
          if (errorCallback) errorCallback(response.data);
        }
        return response.data;
      })
      .catch((error) => {
        let response = error.response;
        if (response) {
          if (errorCallback) errorCallback(response.data);
        } else {
          if (failCallback) failCallback(response);
        }
      });
  },
  asyncPut: async (
    url,
    id,
    data,
    successCallback,
    errorCallback,
    failCallback
  ) => {
    try {
      const response = await axios.put(generateUrl(url, id), data, Header());
      if (response.status === 200) {
        if (successCallback) successCallback(response.data);
      } else {
        if (errorCallback) errorCallback(response.data);
      }
      return response.data;
    } catch (error) {
      if (error) {
        if (errorCallback) errorCallback(error);
      } else {
        if (failCallback) failCallback(error);
      }
    }
  },
  put: (url, id, data, successCallback, errorCallback, failCallback) => {
    return axios
      .put(generateUrl(url, id), data, Header())
      .then((response) => {
        if (response.status === 200) {
          if (successCallback) successCallback(response.data);
        } else {
          if (errorCallback) errorCallback(response.data);
        }
        return response.data;
      })
      .catch((error) => {
        let response = error.response;
        if (response) {
          if (errorCallback) errorCallback(response.data);
        } else {
          if (failCallback) failCallback(response);
        }
      });
  },
  asyncPatch: async (
    url,
    id,
    data,
    successCallback,
    errorCallback,
    failCallback
  ) => {
    try {
      const response = await axios.patch(generateUrl(url, id), data, Header());
      if (response.status === 200) {
        if (successCallback) successCallback(response.data);
      } else {
        if (errorCallback) errorCallback(response.data);
      }
      return response.data;
    } catch (error) {
      if (error) {
        if (errorCallback) errorCallback(error);
      } else {
        if (failCallback) failCallback(error);
      }
    }
  },
  patch: (url, id, data, successCallback, errorCallback, failCallback) => {
    return axios
      .patch(generateUrl(url, id), data, Header())
      .then((response) => {
        if (response.status === 200) {
          if (successCallback) successCallback(response.data);
        } else {
          if (errorCallback) errorCallback(response.data);
        }
        return response.data;
      })
      .catch((error) => {
        let response = error.response;
        if (response) {
          if (errorCallback) errorCallback(response.data);
        } else {
          if (failCallback) failCallback(response);
        }
      });
  },
  asyncPatch: async (url, id, successCallback, errorCallback, failCallback) => {
    try {
      const response = await axios.delete(generateUrl(url, id));
      if (response.status === 200) {
        if (successCallback) successCallback(response.data);
      } else {
        if (errorCallback) errorCallback(response.data);
      }
      return response.data;
    } catch (error) {
      if (error) {
        if (errorCallback) errorCallback(error);
      } else {
        if (failCallback) failCallback(error);
      }
    }
  },
  delete: (url, id, successCallback, errorCallback, failCallback) => {
    return axios
      .delete(generateUrl(url, id))
      .then((response) => {
        if (response.status === 200) {
          if (successCallback) successCallback(response.data);
        } else {
          if (errorCallback) errorCallback(response.data);
        }
        return response.data;
      })
      .catch((error) => {
        let response = error.response;
        if (response) {
          if (errorCallback) errorCallback(response.data);
        } else {
          if (failCallback) failCallback(response);
        }
      });
  },
};
