import React, { useState, useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { Popover, PopoverHeader } from "reactstrap";

import LoginModal from "../../Tienda/components/LoginNavItem/LoginModal";
import RegisterModal from "../../Tienda/components/LoginNavItem/RegisterModal";
import ForgottenPasswordModal from "../../Tienda/components/LoginNavItem/ForgottenPasswordModal";
import { LoginContext } from "../../Tienda/components/ParadigmaLoginProvider/ParadigmaLoginProvider";
import { CartContext } from "../../Tienda/components/ParadigmaCartProvider/ParadigmaCartProvider";
import "./header.scss";

const NotLoggedComponent = (props) => {
  const { showLoginModal, setShowLoginModal } = props;
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showForgotPassModal, setShowForgotPassModal] = useState(false);

  return (
    <div className="overflow-auto">
      <LoginModal
        showModal={showLoginModal}
        toggle={() =>
          setShowLoginModal(!!!showLoginModal) &
          setShowRegisterModal(false) &
          setShowForgotPassModal(false)
        }
        openRegisterModal={() =>
          setShowLoginModal(false) &
          setShowRegisterModal(true) &
          setShowForgotPassModal(false)
        }
        openForgotPasswordModal={() =>
          setShowLoginModal(false) &
          setShowRegisterModal(false) &
          setShowForgotPassModal(true)
        }
      />
      <RegisterModal
        showModal={showRegisterModal}
        toggle={() =>
          setShowRegisterModal(!!!showRegisterModal) &
          setShowRegisterModal(false)
        }
        openLoginModal={() =>
          setShowLoginModal(true) & setShowRegisterModal(false)
        }
      />
      <ForgottenPasswordModal
        showModal={showForgotPassModal}
        toggle={() =>
          setShowForgotPassModal(!!!showForgotPassModal) &
          setShowLoginModal(false)
        }
        openLoginModal={() =>
          setShowLoginModal(true) & setShowForgotPassModal(false)
        }
      />
    </div>
  );
};

const DropdownMenuLoggedComponent = ({
  logout,
  classNames,
  displayname,
  isOpen,
  target,
  toggle,
}) => {
  return (
    <Popover
      className={classNames + " popover-menu-account"}
      isOpen={isOpen}
      target={target}
      placement={"bottom"}
      toggle={toggle}
      trigger="legacy"
    >
      <PopoverHeader>{displayname}</PopoverHeader>
      <NavLink
        className={"nav-link "}
        to="/tienda/cliente/perfil"
        onClick={toggle}
      >
        <i className="fa fa-user"></i> Editar Datos
      </NavLink>
      <NavLink
        className={"nav-link "}
        to="/tienda/cliente/compras"
        onClick={toggle}
      >
        <i className="fa fa-cart-arrow-down" aria-hidden="true"></i> Historial
        de Compras
      </NavLink>
      <NavLink
        className={"nav-link "}
        to="/tienda/cliente/resumencuenta"
        onClick={toggle}
      >
        <i className="fa fa-credit-card" aria-hidden="true"></i> Estado de
        Cuenta
      </NavLink>
      <a
        className={`nav-link cursor-pointer`}
        onClick={() => {
          toggle();
          logout();
        }}
        style={{ color: "red" }}
      >
        <i className="fa fa-sign-out"></i> Salir
      </a>
    </Popover>
  );
};

const MenuLoggedComponent = ({
  logout,
  className,
  closeHeader,
  displayname,
}) => (
  <div className={className}>
    <a className={"nav-link "}>{displayname}</a>
    <NavLink
      className={"nav-link "}
      onClick={closeHeader}
      to="/tienda/cliente/perfil"
    >
      <i className="fa fa-user"></i> Editar Datos
    </NavLink>
    <NavLink
      className={"nav-link "}
      onClick={closeHeader}
      to="/tienda/cliente/compras"
    >
      <i className="fa fa-cart-arrow-down" aria-hidden="true"></i> Historial de
      Compras
    </NavLink>
    <NavLink
      className={"nav-link "}
      onClick={closeHeader}
      to="/tienda/cliente/resumencuenta"
    >
      <i className="fa fa-credit-card" aria-hidden="true"></i> Estado de Cuenta
    </NavLink>
    <a
      className={`nav-link cursor-pointer`}
      onClick={() => logout(() => closeHeader())}
    >
      <i className="fa fa-sign-out"></i> Salir
    </a>
  </div>
);

export const CustomLoginNavItemDesktop = (props) => {
  const { displayname, logout, className, logged } = props;

  const [openMenu, setOpenMenu] = useState(false);
  const toggle = () => setOpenMenu(!openMenu);
  return (
    <>
      <p>
        <a
          id="OpenLoginModalBtn"
          className={"cursor-pointer " + className}
          onClick={toggle}
        >
          {logged ? (
            "Mi Cuenta"
          ) : (
            <>
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 16 16"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"></path>
              </svg>
              {"Iniciar Sesión"}
            </>
          )}
        </a>
      </p>
      {logged ? (
        <DropdownMenuLoggedComponent
          logout={logout}
          className={className ? className : ""}
          displayname={displayname}
          isOpen={openMenu}
          target={"OpenLoginModalBtn"}
          toggle={toggle}
        />
      ) : (
        <NotLoggedComponent
          showLoginModal={openMenu}
          setShowLoginModal={setOpenMenu}
        />
      )}
    </>
  );
};

const CustomLoginNavItemMobile = (props) => {
  const { className, logout, logged, closeHeader, displayname } = props;
  const [openModal, setOpenModal] = useState(false);

  return !logged ? (
    <>
      <a
        className={"nav-link cursor-pointer " + className}
        onClick={() => setOpenModal(true)}
      >
        {"Ingresar"}
      </a>
      <NotLoggedComponent
        showLoginModal={openModal}
        setShowLoginModal={setOpenModal}
      />
    </>
  ) : (
    <MenuLoggedComponent
      logout={logout}
      className={className ? className : ""}
      closeHeader={closeHeader}
      displayname={displayname}
    />
  );
};

export const LoginNavItem = (props) => {
  const { clear } = useContext(CartContext);
  const { logged, logout, displayname } = useContext(LoginContext);

  return (
    <CustomLoginNavItemDesktop
      // className="d-none d-md-block"
      className=""
      logged={logged}
      logout={() => logout(() => clear())}
      displayname={displayname}
    />
  );
};

export const LoginNavItemMobile = (props) => {
  const { clear } = useContext(CartContext);
  const { logged, logout, displayname } = useContext(LoginContext);

  return (
    <CustomLoginNavItemMobile
      className="d-block d-md-none"
      logged={logged}
      logout={() => logout(() => clear())}
      closeHeader={props.closeHeader}
      displayname={displayname}
    />
  );
};
