import React, { useContext } from "react";
import { Redirect } from "react-router";
import { CustomLoginNavItemDesktop } from "../../components/Header/CustomLoginNavItem/LoginNavItem";
// import { CartContext } from "../../components/Tienda/components/ParadigmaCartProvider/ParadigmaCartProvider";
import { LoginContext } from "../../components/Tienda/components/ParadigmaLoginProvider/ParadigmaLoginProvider";
import { CartContext } from "../../components/Tienda/components/ParadigmaCartProvider/ParadigmaCartProvider";

import "./styles.scss";

const Login = () => {
  const { logged, logout, displayname } = useContext(LoginContext);
  const { clear } = useContext(CartContext);

  if (logged) {
    window.location.href = "/tienda/productos/";
  }

  return (
    <div className="login__wrap">
      <div className="login__form">
        <img
          className="img-fluid login__logo"
          src="./images/Logo-Agrosur.png"
          alt={process.env.REACT_APP_TIENDA_NOMBRE}
        />
        <p className="lead text-center">Ingrese a su cuenta </p>

        <CustomLoginNavItemDesktop
          className="btn btn-primary"
          logged={logged}
          logout={() => logout(() => clear())}
          displayname={displayname}
        />
      </div>
    </div>
  );
};

export default Login;
