import React, { Fragment, useState, useEffect } from "react";
import Magnifier from "react-magnifier";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import { CarouselControl } from "reactstrap";

const ProductoImages = (props) => {
  const [state, setState] = useState({
    currentIndex: 0,
    images: props.images,
    animating: false,
  });

  const [isOpenLightbox, setOpenLightbox] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  let images = [];
  state.images.map((image) => images.push(image.src));

  useEffect(() => {
    setPhotoIndex(state.currentIndex);
  }, [state.currentIndex]);

  const { cartItem } = props;

  const next = () => {
    const { currentIndex, images, animating } = state;
    if (!animating) {
      const nextIndex =
        currentIndex === images.length - 1 ? 0 : currentIndex + 1;
      setState({ ...state, currentIndex: nextIndex });
    }
  };

  const previous = () => {
    const { currentIndex, images, animating } = state;
    if (!animating) {
      const nextIndex =
        currentIndex === 0 ? images.length - 1 : currentIndex - 1;
      setState({ ...state, currentIndex: nextIndex });
    }
  };

  const onExiting = (animating) => {
    setState((prevState) => {
      prevState.animating = animating;
      return prevState;
    });
  };

  const onDrag = (dragging) => {
    setState((prevState) => {
      prevState.dragging = dragging;
      return prevState;
    });
  };

  useEffect(() => {
    setState({
      currentIndex: 0,
      images: props.images,
      animating: false,
    });
  }, [props.images]);

  useEffect(() => {
    let images = [...props.images];
    if (
      images.some(
        (image) =>
          image.Color_id == cartItem.color_id &&
          image.Talle_id == cartItem.talle_id
      )
    ) {
      images = images.filter(
        (image) =>
          image.Color_id == cartItem.color_id &&
          image.Talle_id == cartItem.talle_id
      );
    }
    setState({
      currentIndex: 0,
      images: images,
      animating: false,
    });
  }, [cartItem]);

  return (
    <Fragment>
      {/* <div className="d-block d-md-none mx-auto esige-carousel" */}
      <div
        className="mx-auto esige-carousel"
        activeIndex={state.currentIndex}
        next={() => next()}
        previous={() => previous()}
      >
        <div
          draggable={true}
          role="listbox"
          className="esige-carousel-inner p-3 p-md-4"
          style={{ left: "-" + 100 * state.currentIndex + "%" }}
        >
          {state.images.map((x, i) => (
            <div
              key={`esige-carousel-image-item-${i}`}
              className="cursor-zoom esige-carousel-item"
              onExiting={() => onExiting(true)}
              onExited={() => onExiting(false)}
              onMouseMove={() => onDrag(true)}
            >
              <a onClick={() => setOpenLightbox(true)}>
                <img
                  key={`image-${i}`}
                  className={
                    i === state.currentIndex ? "img-fluid active" : "img-fluid"
                  }
                  alt={"img"}
                  src={x.src}
                />
              </a>
            </div>
          ))}
        </div>
        {state.images.length > 1 && (
          <React.Fragment>
            <CarouselControl
              direction="prev"
              directionText="Previous"
              onClickHandler={() => previous()}
            />
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={() => next()}
            />
          </React.Fragment>
        )}
      </div>

      <div className="esige-producto-content p-0">
        <div className="esige-producto-gallery">
          {/* <div className="col-12 esige-producto-gallery-primary d-none d-md-block">
            {
              state.images.length > 0 && state.currentIndex >= 0 &&
              state.images.length > state.currentIndex &&
              state.images[state.currentIndex].src.indexOf('disponible') === -1 && (
                props.magnifier ? (
                  <Magnifier
                    src={(state.images.length > 0 ? state.images[state.currentIndex].src : "")}
                    mgWidth={150}
                    width="auto"
                    mgHeight={150}
                    zoomFactor={1.5}
                  />
                ) : (
                  <img className="img-fluid" alt={"Imagen Actual"} src={state.images[state.currentIndex].src} />
                )
              )
            }
          </div> */}
          <ul className="col-12 d-none d-md-flex p-0 esige-producto-gallery-list justify-content-center">
            {state.images.map((x, i) => (
              <li
                key={`image-thumbnail-${i}`}
                className={state.currentIndex === i ? "hover" : ""}
              >
                <img
                  className="img-fluid cursor-pointer"
                  alt=""
                  src={x.src}
                  onMouseEnter={(e) => {
                    const index = i;
                    if (index !== state.currentIndex) {
                      setState({ ...state, currentIndex: index });
                    }
                  }}
                />
              </li>
            ))}
          </ul>

          {isOpenLightbox && (
            <Lightbox
              mainSrc={images[photoIndex]}
              nextSrc={images[(photoIndex + 1) % images.length]}
              prevSrc={images[(photoIndex + images.length - 1) % images.length]}
              onCloseRequest={() => setOpenLightbox(false)}
              onMovePrevRequest={() => {
                setPhotoIndex((photoIndex + images.length - 1) % images.length);
              }}
              onMoveNextRequest={() => {
                setPhotoIndex((photoIndex + 1) % images.length);
              }}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default ProductoImages;
