import React, { useEffect, useContext } from 'react';
import { Tooltip } from 'reactstrap';

import { CartContext } from '../ParadigmaCartProvider';
import { View } from '../../../TiendaConfig';


const CartToolTip = (props) => {

  const { placement, isOpen, target, onChange, className, onClickCheckoutButton } = props;
  const cartContext = useContext(CartContext);

  useEffect(() => onToggle(isOpen), [cartContext.cartState.articulos]);

  const onToggle = (show) => {
    let toReturn = cartContext.cartState.articulos.length > 0 && window.location.href.indexOf("checkout") === -1 && show;
    onChange(toReturn);
  }

  return (
    <Tooltip
      autohide={false}
      className="rounded"
      placement={placement}
      isOpen={isOpen}
      target={target}
      toggle={() => onToggle(!isOpen)}
    >
      <View.CartDetailView
        className={className}
        visible={isOpen}
        placement={placement}
        onClickCheckoutButton={onClickCheckoutButton}
      />
    </Tooltip>
  )
}

export default CartToolTip;