import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Badge, Button, Col, Row } from "reactstrap";
import format from "../../../../functions/functions.js";
import { GlobalConfigTienda } from "../../../../../TiendaConfig";
import CantidadSelectorController from "../../../../components/CantidadSelector/CantidadSelectorController";
import functions from "../../../../functions/functions.js";
import imagenNoDisponible from "../../../../assets/image_not_found.png";

function roundUp(num) {
  return Math.ceil(num / 100) * 100 - 1;
}

const generateLink = (art) => {
  let url = `/tienda/producto/${art.code}/`;
  let params = [];
  if (art.talle_id) params.push(`talle_id=${art.talle_id}`);
  if (art.color_id) params.push(`color_id=${art.color_id}`);
  if (params.length > 0) url += `?${params.join("&")}`;
  return url;
};

const showOfertaImg = (art) =>
  (art.OfertaPeriodo_FechaDesde != null &&
    art.OfertaPeriodo_FechaHasta != null &&
    Date.parse(art.OfertaPeriodo_FechaHasta) >= Date.now()) ||
  art.OfertaNxM_Cantidad > 0;

const ImagenArticulo = (props) => {
  let { art, showOfertaImg } = props;
  return (
    <Link
      to={generateLink(art)}
      className="col-4 col-md-2 esige-cart-image product__off-link"
    >
      {showOfertaImg && <div className="product__off-cart">Oferta</div>}
      {art.Imagenes && art.Imagenes.length > 0 ? (
        art.Imagenes.some(
          (img) => art.color_id == img.Color_id && art.talle_id == img.Talle_id
        ) ? (
          <img
            src={
              art.Imagenes.filter(
                (img) =>
                  art.color_id == img.Color_id && art.talle_id == img.Talle_id
              )[0].src
            }
            alt={art.Titulo}
          />
        ) : (
          <img src={art.Imagenes && art.Imagenes[0].src} alt={art.Titulo} />
        )
      ) : (
        <img src={imagenNoDisponible} alt={art.Titulo} />
      )}
    </Link>
  );
};

// VER ESTO !!! DEJAR PRECIO DE LISTA
function precioUnitario(art) {
  
  return art.PrecioSinIVA > 0
  ?
  art.PrecioSinIVA
  :
  art.OfertaNxM_Cantidad &&
    art.quantity >= art.OfertaNxM_Cantidad &&
    art.OfertaNxM_Cantidad > 0
    ? art.OfertaNxM_Precio
    : art.PrecioLista;
  // : art.Precio;
  
}

const PanelButtons = (props) => {
  let { art, onChangeCantidad, removeItem } = props;
  return (
    <div className=" row esige-panel-buttons">
      <CantidadSelectorController
        className="mx-1 py-0"
        cantidadDisponible={functions.getMaxQuantity(
          art,
          art.talle_id,
          art.color_id
        )}
        cantidad={art.quantity}
        onChange={(quantity) =>
          onChangeCantidad({
            code: art.code,
            quantity: quantity,
            color_id: art.color_id,
            talle_id: art.talle_id,
          })
        }
      />
      <div className="ml-1 d-flex align-items-center">
        <Button
          onClick={() =>
            removeItem({
              code: art.code,
              color_id: art.color_id,
              talle_id: art.talle_id,
            })
          }
          className="p-1 rounded btn-block btn-delete"
          color="danger"
        >
          <i className="fa fa-trash fa-lg fa-1x" aria-hidden="true"></i>
        </Button>
      </div>
    </div>
  );
};

export default (props) => {
  const { index, art, onChangeCantidad, removeItem, cartState } = props;

  return (
    <Fragment key={"article-" + String(art.code)}>
      <div className="esige-cart-row my-1">
        <div className="row esige-cart-container">
          <ImagenArticulo art={art} showOfertaImg={showOfertaImg(art)} />

          <div
            className={
              "esige-cart-title" +
              (art.totalPrice > 0 ? " col-6 col-md-6 " : " col-6 col-md-8")
            }
          >
            <div className="my-auto width-inherit">
              {/* <Link to={generateLink(art)} className="h3 cart-articulo-titulo"> */}
              <h4 className="d-inline-block mr-2">
                <Badge color="info">{art.Codigo}</Badge>
              </h4>
              {art.Titulo}
              {art.talle_id && (
                <>
                  <Badge color="info">
                    TALLE: {art.talle ? art.talle : "No Especificado"}
                  </Badge>{" "}
                </>
              )}
              {art.color_id && (
                <>
                  <Badge color="info">
                    COLOR: {art.color ? art.color : "No Especificado"}
                  </Badge>{" "}
                </>
              )}
              {/* </Link> */}

              <PanelButtons
                art={art}
                onChangeCantidad={onChangeCantidad}
                removeItem={removeItem}
              />
            </div>
          </div>

          <div
            className={
              "col-2 col-md-2 d-flex flex-column justify-content-center align-items-center"
            }
          >
            <p className="mb-1">Precio Unitario</p>
            <p>$ {format.FormatPrice(precioUnitario(art), GlobalConfigTienda.NumeroDecimales)}</p>
            {/* <p>
              $ {format.FormatPrice(
                precioUnitario(art) / 1.21,
                // precioUnitario(art),
                2 // GlobalConfigTienda.NumeroDecimales
              )}
            </p> */}
          </div>
          {art.totalPrice > 0 && (
            <div className="col-6 col-md-2 d-flex justify-content-center pr-0">
              <div className="d-flex flex-row align-items-center text-center">
                {/* <p className="mt-2"><strong>$ {format.FormatPrice(art.totalPrice, GlobalConfigTienda.NumeroDecimales)}</strong></p> */}
                <p className="mt-2">
                  <strong>
                    ${" "}
                    {format.FormatPrice(
                      // (precioUnitario(art) / 1.21) * art.quantity,
                      precioUnitario(art) * art.quantity,
                      2 // GlobalConfigTienda.NumeroDecimales
                    )}
                  </strong>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      {index < cartState.articulos.length - 1 && (
        <div className="col-12">
          <hr className="my-2" />
        </div>
      )}
    </Fragment>
  );
};
