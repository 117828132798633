import React,{Component} from 'react';
import { CardHeader, CardBody, Collapse} from 'reactstrap';
import { BeatLoader } from 'react-spinners';

export default class ProductosFilterItem extends Component{

    constructor(props) {
        super(props);
        this.state = {
          collapseOpened: true,
        }
    }

    render(){
        const {filter, i, onShowMore, loading }= this.props;
        const {collapseOpened} = this.state;
        let productosFilterItemOptions = filter.Opciones.map(
            (opt,idx) => <ProductosFilterItemOption key={'ProductosFilterItemOption-'+idx} opt={opt} {...this.props}/>
        );

        if(!filter.showMore){
            productosFilterItemOptions = productosFilterItemOptions.slice(0,4);
            productosFilterItemOptions.push(
                <li key={`filter-${filter.Nombre}-showmore`}
                className='cursor-pointer'
                onClick={() => onShowMore(i, true)}>
                <h5 className={"showmore"}>Mostrar más [+]</h5>
                </li>);
        }

        return(
            <CardBody key={`filter-${filter.Nombre}`} className="p-0 w-100">
                <CardHeader onClick={()=> this.setState(
                        {collapseOpened:!collapseOpened},
                        () =>{ if (collapseOpened) onShowMore(i, false)}
                    )}
                    id={`filter-${filter.Nombre}`}
                    className={"cursor-pointer p-2 card-title bg-white bb-none bt-1 m-0 border-color-primary"}>
                    <h5 className="m-0 p-1">
                        {filter.Nombre}
                        <span className="float-right">
                            <i className={collapseOpened ?"fa fa-chevron-up":"fa fa-chevron-down"} aria-hidden="true"/>
                        </span>
                    </h5>
                </CardHeader>
                {
                loading == true ?
                    <BeatLoader className="text-align-center" />
                :
                <Collapse isOpen={collapseOpened}>
                    <ul className={"p-2 border-color-primary" + (loading ? " d-none" : "")} style={{listStyleType: "none"}}>
                        {filter && productosFilterItemOptions}
                    </ul>
                </Collapse>
                }
            </CardBody>
        );
    }
}


const ProductosFilterItemOption = (props) =>{
    const {filter, onChangeFilter, opt} = props;
    return (
        <li key={`filter-${filter.Nombre}-opt-${opt.code}`}>
            <div
            onClick={() => onChangeFilter(opt.identifier, opt.code, opt.checked)}
            className="inputGroup d-inline-block" id={"filter-" + filter.Nombre + "-" + opt.code}
            >
            <input
                onChange={() => null}
                checked={opt.checked == true}
                id={"filter-" + filter.Nombre + "-" + opt.code + "-option"}
                id={"filter-" + filter.Nombre + "-" + opt.code + "-option"}
                type="checkbox"
                className="form-check-input"
            />
            <label htmlFor={"filter-" + filter.Nombre + "-" + opt.code + "-option"}>{opt.Nombre}</label>
            </div>
        </li>);
}
