import React from "react";

import { Route, Switch, Redirect } from "react-router-dom";
import AuthenticatedRoute from "./components/ParadigmaLoginProvider/AuthenticatedRoute";

import ProductosRouter from "./views/Productos/ProductosRouter.js";
import Checkout from "./views/Checkout";
import CuentasCorrientes from "./views/Usuario/CuentasCorrientes";
import Pedidos from "./views/Usuario/Pedidos";
import Perfil from "./views/Usuario/Perfil";
import { MercadoPagoResponseHandler } from "./views/Checkout/MercadoPago";

import "./styles.scss";
const Tienda = () => {
  const pathsProductosRouter = [
    "/tienda/destacados",
    "/tienda/ofertas",
    "/tienda/productos",
    "/tienda/producto",
  ];
  return (
    <>
      <section className="p-0 container-fluid">
        <Switch>
          <AuthenticatedRoute
            path={pathsProductosRouter}
            component={(props) => <ProductosRouter {...props} />}
            // render={(props) => <ProductosRouter {...props} />}
          />
          <Route
            path="/tienda/mp/preference/response/:code/"
            render={(props) => <MercadoPagoResponseHandler {...props} />}
          />
          <AuthenticatedRoute path="/tienda/checkout" component={Checkout} />
          <AuthenticatedRoute
            path="/tienda/cliente/perfil"
            component={Perfil}
          />
          <AuthenticatedRoute
            path="/tienda/cliente/compras"
            component={Pedidos}
          />
          <AuthenticatedRoute
            path="/tienda/cliente/resumencuenta"
            component={CuentasCorrientes}
          />
          <Redirect to="/" />
        </Switch>
      </section>
    </>
  );
};

export default Tienda;
