import React, { useState, useEffect, Component } from 'react';
import { Redirect } from "react-router-dom";

import api from "../../api";
import apifunctions from "../../api/functions.js";

import imagenNoDisponible from '../../assets/image_not_found.png';

import { View } from '../../../TiendaConfig';

const ProductoController = (props) => {
  const [state, setState] = useState({
    loading: true,
    redirect: false,
    breadcrumbs: [],
  });

  useEffect(() => {
    const getAndProcess = (reintento = 0) => {
      const successCallback = (response) => {
        if (response && response.data) {
          let data = response.data;
          if (data.Articulo.Imagenes.length === 0) data.Articulo.Imagenes.push({ src: imagenNoDisponible, color_id: null, talle_id: null, });
          setState({
            producto: data.Articulo,
            breadcrumbs: data.breadcrumbs,
            loading: false,
            redirect: false,
          });
        } else {
          setState({ redirect: true });
        }
      };
      const failureCallback = () => {
        if (reintento > 3) {
          setState({ redirect: true });
        } else {
          getAndProcess(reintento + 1);
        }
      };
      apifunctions.get(api.api.articulos.articulo, props.match.params.hash, 1, {}, successCallback, failureCallback, failureCallback);
    };
    setState({ loading: true, redirect: false });
    getAndProcess();
  }, [props.hash, props.match.params.hash]);

  return state.redirect ? (
    <Redirect to="/" />
  ) : (
    <View.ProductoView
      {...props}
      {...state}
    />
  );
}

export default ProductoController;
