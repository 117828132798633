import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const PageNavigation = ({
  cantProd = 0,
  currentPage = 0,
  totalPages = 1,
  OnChangeNavigationBar,
}) => {
  let currentPageToDisplay = currentPage + 1;
  let first = "1";
  let last = String(totalPages);

  let previous = String(currentPageToDisplay - 1);
  let previous_previous = String(currentPageToDisplay - 2);
  let current = String(currentPageToDisplay);
  let next = String(currentPageToDisplay + 1);
  let next_next = String(currentPageToDisplay + 2);

  let firstClass =
    currentPage == 0 || currentPage == 1 || currentPage == 2
      ? "page-pagination__link d-none"
      : "page-pagination__link";
  let lastClass =
    currentPage == totalPages - 1 ||
    currentPage == totalPages - 2 ||
    currentPage == totalPages - 3
      ? "page-pagination__link d-none"
      : "page-pagination__link";

  let previousClass =
    currentPage == 0
      ? "page-pagination__link page-pagination__link--before d-none"
      : "page-pagination__link page-pagination__link--before";
  let previouspreviousClass =
    currentPage == 0 || currentPage == 1
      ? "page-pagination__link page-pagination__link--before d-none"
      : "page-pagination__link page-pagination__link--before";

  let nextClass =
    currentPage == totalPages - 1
      ? "page-pagination__link page-pagination__link--next d-none"
      : "page-pagination__link page-pagination__link--next";
  let nextnextClass =
    currentPage == totalPages - 1 || currentPage == totalPages - 2
      ? "page-pagination__link page-pagination__link--next d-none"
      : "page-pagination__link page-pagination__link--next";

  if (totalPages == 0 || cantProd == 0) {
    return <React.Fragment />;
  } else if (totalPages == 1) {
    return (
      <div className="text-center mt-2 mb-4">
        <div className={"page-pagination__link page-pagination__link--active"}>
          1
        </div>
      </div>
    );
  } else {
    return (
      <div className={"page-pagination text-center mt-2 mb-4"}>
        <div
          className={previousClass}
          onClick={() => OnChangeNavigationBar(currentPage - 1)}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </div>
        <div className={firstClass} onClick={() => OnChangeNavigationBar(0)}>
          {first}
        </div>
        <div
          className={previouspreviousClass}
          onClick={() => OnChangeNavigationBar(currentPage - 2)}
        >
          {previous_previous}
        </div>
        <div
          className={previousClass}
          onClick={() => OnChangeNavigationBar(currentPage - 1)}
        >
          {previous}
        </div>
        <div className={"page-pagination__link page-pagination__link--active"}>
          {current}
        </div>
        <div
          className={nextClass}
          onClick={() => OnChangeNavigationBar(currentPage + 1)}
        >
          {next}
        </div>
        <div
          className={nextnextClass}
          onClick={() => OnChangeNavigationBar(currentPage + 2)}
        >
          {next_next}
        </div>
        <div
          className={lastClass}
          onClick={() => OnChangeNavigationBar(totalPages - 1)}
        >
          {last}
        </div>
        <div
          className={nextClass}
          onClick={() => OnChangeNavigationBar(currentPage + 1)}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </div>
      </div>
    );
  }
};

export default PageNavigation;
