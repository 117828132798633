import React, { useState, useEffect } from "react";
import { View } from "../../../TiendaConfig";

const validateCantidad = (cantidad, cantidadDisponible) => {
  const fn = (a, b) => (a > b ? a : b);
  let cantidadDisponibleValidated = fn(cantidadDisponible, 1);
  let cantidadValidated = fn(cantidad, 1);

  return cantidadValidated > cantidadDisponibleValidated
    ? cantidadDisponibleValidated
    : cantidadValidated;
};

const CantidadSelectorController = (props) => {
  const updateCantidad = (cantidad) => {
    let cantidadValidated = validateCantidad(
      cantidad,
      props.cantidadDisponible
    );
    props.onChange && props.onChange(cantidadValidated);
  };

  useEffect(() => {
    let cantidadValidated = validateCantidad(
      props.cantidad,
      props.cantidadDisponible
    );
    if (cantidadValidated != props.cantidad)
      props.onChange && props.onChange(cantidadValidated);
  }, []);

  return (
    <View.CantidadSelectorView
      Cantidad={props.cantidad}
      maxCantidad={props.cantidadDisponible}
      setCantidad={updateCantidad}
      className={props.className ? props.className : ""}
    />
  );
};

export default CantidadSelectorController;
