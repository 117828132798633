import React, { useState, useEffect } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Row, Col, InputGroup, FormGroup,
  Label, Input, FormText, Alert,
  InputGroupAddon, InputGroupText
} from 'reactstrap';

import InputPassword from '../PasswordScheme/PasswordInput';

import api from '../../api/api.js';
import apifunctions from '../../api/functions.js';



const that = {
  validaTimeout: null,
};

const ForgottenPasswordModal = (props) => {
  const { showModal, toggle, openLoginModal } = props;

  const [showPass, setShowPass] = useState(false);

  const [state, setState] = useState({
    email: "",
    code: "",
    newPassword: "",
  });

  const [validationState, updateValidationState] = useState({
    showAlertSending: false,
    error: false,
    errorMsg: "",
    validaMail: false,
    showCodeInput: false,
  });

  useEffect(() => {
    setState({
      email: "",
      code: "",
      newPassword: "",
    });
    updateValidationState({
      showAlertSending: false,
      error: false,
      errorMsg: "",
      validaMail: false,
      showCodeInput: false,
    });
  }, [props.showModal]);

  const validate = (type, value, desiredLength) => {
    let ValidateEmail = (mail) => {
      let result = false;
      if (mail.indexOf("@") == -1 || mail.indexOf(".") == -1) {
        result = false;
      } else {
        const timeoutFn = () => {
          clearTimeout(that.validaTimeout);
          updateValidationState({ ...validationState, validaMail: mail.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) != null });
        }
        clearTimeout(that.validaTimeout);
        that.validaTimeout = setInterval(timeoutFn, 1000);
        result = true;
      }
      return result;
    };
    let ValidateChar = (text, desiredLength) => {
      return (text == undefined || text == null || text.length < desiredLength) == false;
    }
    let result = false;
    switch (type) {
      case 'text':
        result = ValidateChar(value, desiredLength);
        break;
      case 'email':
        result = ValidateEmail(value);
        break;
      default:
        break;
    }
    return result;
  }

  const isFormValid = () => {

    const isEmailValid = validate("email", state.email, 10);
    const isCodeValid = validate("text", state.code, 8);
    const isPasswordValid = validate("text", state.newPassword, 8);

    const result = isEmailValid && (validationState.showCodeInput ? isCodeValid && isPasswordValid : true);

    return result;
  }

  const send = () => {
    if (validationState.showCodeInput) {
      const sendDataAndWait = () => {
        const failureCallback = (response) => {
          updateValidationState({
            ...validationState,
            showAlertSending: false,
            error: true,
            errorMsg: response && response.errors && response.errors.length == 1 ? response.errors[0] : "Se produjo un error en el servidor, reitente nuevamente en unos momentos.",
          });
        }
        const successCallback = (response) => {
          if (response.data.success == true) {
            setState({
              email: "",
              code: "",
              newPassword: "",
            });
            updateValidationState({
              showAlertSending: false,
              error: false,
              errorMsg: "",
              validaMail: false,
              showCodeInput: false,
            });
            openLoginModal();
          } else {
            updateValidationState({
              ...validationState,
              showAlertSending: false,
              error: true,
              errorMsg: "Se produjo un error en el servidor, reitente nuevamente en unos momentos.",
            });
          }
        };
        apifunctions.put(api.api.clientes.auth.regenerarPassword, null, state, successCallback, failureCallback, failureCallback);
      }
      updateValidationState({ ...validationState, showAlertSending: true });
      sendDataAndWait();
    } else {
      const sendDataAndWait = () => {
        const failureCallback = (response) => {
          updateValidationState({
            ...validationState,
            showAlertSending: false,
            error: true,
            errorMsg: response && response.errors && response.errors.length == 1 ? response.errors[0] : "Se produjo un error en el servidor, reitente nuevamente en unos momentos.",
          });
        };
        const successCallback = (response) => {
          if (response.data.success == true) {
            setState({
              ...state,
              code: "",
              newPassword: "",
            });
            updateValidationState({
              ...validationState,
              showAlertSending: false,
              error: false,
              errorMsg: "",
              showCodeInput: true,
            });
          } else {
            updateValidationState({
              ...validationState,
              showAlertSending: false,
              error: true,
              errorMsg: "Se produjo un error en el servidor, reitente nuevamente en unos momentos.",
            });
          }
        };
        apifunctions.post(api.api.clientes.auth.regenerarPassword, null, { email: state.email }, successCallback, failureCallback, failureCallback);
      }

      updateValidationState({ ...validationState, showAlertSending: true });
      sendDataAndWait();
    }
  }

  return (
    <Modal centered isOpen={showModal} toggle={toggle} className="register" size="lg">
      <ModalHeader toggle={toggle}>Solicitud de Restablecimiento de Password</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="email">Email</Label>
          <Input type="email" name="email" id="email" placeholder="Ej: abc@gmail.com"
            className={validate("email", state.email, 10) ? "rounded-lg border border-success" : "rounded-lg border border-danger"}
            value={state.email}
            onChange={e => setState({ ...state, email: e.target.value != undefined && e.target.value != null && e.target.value.length > 0 ? e.target.value : "" })}
          />
        </FormGroup>
        {
          validationState.showCodeInput &&
          <>
            <FormGroup className="w-50">
              <Label for="code">Codigo entregado via mail</Label>
              <Input type="text" name="code" id="code" placeholder="Ej: oxmjyrfc"
                className={validate("text", state.code, 8) ? "rounded-lg border border-success" : "rounded-lg border border-danger"}
                value={state.code}
                onChange={e => setState({ ...state, code: e.target.value != undefined && e.target.value != null && e.target.value.length > 0 ? e.target.value : "" })}
              />
            </FormGroup>
            <InputPassword
              label="Nueva Contraseña"
              value={state.newPassword}
              onChange={newValue => setState({ ...state, newPassword: newValue != undefined && newValue != null && newValue.length > 0 ? newValue : "" })}
            />
          </>
        }
        {
          (validationState.showAlertSending == true || validationState.error == true) &&
          <Alert color={validationState.error == true ? "warning" : "info"} className="my-3 text-center">
            {
              validationState.showAlertSending == true && validationState.showCodeInput &&
              <><i className="fa fa-spinner fa-spin mr-2"></i> Procesando</>
            }
            {
              validationState.showAlertSending == true && !validationState.showCodeInput &&
              <><i className="fa fa-spinner fa-spin mr-2"></i> Enviando Mail a {state.email}</>
            }
            {
              validationState.error == true && validationState.errorMsg
            }
          </Alert>
        }
      </ModalBody>
      <ModalFooter>
        <Button disabled={(isFormValid() == false) || (validationState.showAlertSending == true)} color="success" onClick={send}
        ><i className="fa fa-thumbs-o-up" aria-hidden="true"></i> Confirmar</Button>{' '}
        <Button color="secondary" onClick={openLoginModal}><i className="fa fa-times" aria-hidden="true"></i> Cancelar</Button>
      </ModalFooter>
    </Modal>
  );
}

export default ForgottenPasswordModal;
