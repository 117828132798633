import React from 'react';
import { useState } from 'react';
import { Col, FormFeedback, Input, Label, Row } from 'reactstrap';
import { GlobalConfigTienda } from '../../../TiendaConfig';
const { Sugerencias, password_validator } = GlobalConfigTienda.passwordScheme;

const PasswordInput = (props) => {
    const [showPass, setShowPass] = useState(false);
    const { onChange, label="Contraseña", value, placeholder = "Ej: codTEL291"} = props;

    return (
        <>
            <Label for="NuevaPassword">{label}</Label>
            <Row>
                <Col xs={8} md={4}>
                    <Input type={showPass ? "text" : "password"} name="NuevaPassword" id="NuevaPassword" placeholder={placeholder}
                        className={password_validator.isPasswordValid(value) ? "rounded-lg border border-success" : "rounded-lg border border-danger"}
                        invalid={password_validator.isPasswordValid(value) == false && (value != "")}
                        valid={password_validator.isPasswordValid(value)}
                        value={value}
                        onChange={e => onChange(e.target.value != undefined && e.target.value != null && e.target.value.length > 0 ? e.target.value : "")}
                    />
                    <FormFeedback>Password no respeta formato</FormFeedback>
                </Col>
                <Col xs={2} md={1}>
                    <span className="btn btn-info rounded-pill" onClick={() => setShowPass(!showPass)}>
                        {
                            showPass ?
                                <i className="fa fa-eye-slash" aria-hidden="true"></i>
                                :
                                <i className="fa fa-eye" aria-hidden="true"></i>
                        }
                    </span>
                </Col>
            </Row>
            <div className="mt-2">
                <Sugerencias />
            </div>
        </>
    );
}

export default PasswordInput;