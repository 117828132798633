import React, { useContext, useEffect, useState } from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import "./ProductDetail.scss";

import { FaFacebookF } from "react-icons/fa";
import { BsWhatsapp } from "react-icons/bs";

import photoProduct from "../../assets/img/products/product.jpg";
import ProductosHeader from "../Tienda/views/Producto/ProductoHeader";
import ProductoImages from "../Tienda/views/Producto/ProductoImages";

import imagenNoDisponible from "../../assets/image_not_found.png";
import CantidadSelectorController from "../Tienda/components/CantidadSelector/CantidadSelectorController";
import functions from "../Tienda/functions/functions";
import { CartContext } from "../Tienda/components/ParadigmaCartProvider/ParadigmaCartProvider";
import { useHistory } from "react-router-dom";
import { DescripcionPreciosModal } from "../DescripcionPreciosModal/DescripcionPreciosModal";
import { ClickInLogin } from "../Tienda/components/CheckoutButton/CheckoutButtonUtils";
import { LoginContext } from "../Tienda/components/ParadigmaLoginProvider/ParadigmaLoginProvider";
import { GlobalConfigTienda } from "../TiendaConfig";

const Compartir = ({ titulo }) => (
  <div className="col-12 mt-2 bg-light">
    {/* <p className="font-italic p-2 mb-0 text-right">
      Compartilo{' '}
      <a
        className="mr-2 cFacebook"
        href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fbahiaconstrucciones.com.ar"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaFacebookF />
      </a>

      <a
        className="mr-2 cWhatsapp mr-3"
        href={`whatsapp://send?text=Hola, mira esto: ${titulo}`}
        data-action="share/whatsapp/share"
        target="_blank"
        rel="noopener noreferrer"
      >
        <BsWhatsapp />
      </a>
    </p> */}
  </div>
);

const ProductDetail = (props) => {
  const { loading, breadcrumbs, producto } = props;
  const cartItem = producto
    ? { code: producto.code, talle_id: null, color_id: null }
    : null;
  const cartContext = useContext(CartContext);
  const enCarrito = cartContext.getCantidadItem({ ...cartItem });
  const stockDisponible = cartItem
    ? functions.getMaxQuantity(producto, null, null)
    : 0;
  const [cantidad, setCantidad] = useState(enCarrito);

  const logged = useContext(LoginContext).logged;

  useEffect(() => {
    setCantidad(enCarrito);
  }, [enCarrito]);

  const history = useHistory();

  const goCard = () => {
    cartContext.setItem({ ...cartItem, quantity: cantidad });
    let path = `/tienda/checkout`;
    history.push(path);
  };

  // function roundUp(num) {
  //   return Math.ceil(num / 100) * 100 - 1;
  // }

  return (
    !!producto && (
      <>
        <div className="esige-producto">
          <ProductosHeader loading={loading} breadcrumbs={breadcrumbs} />
          <div className="container esige-producto-container mb-5">
            <div className="row">
              <Compartir titulo={producto.Titulo} />
            </div>

            <div className="row product__detail--border">
              <div className="col-12 col-lg-8 order-md-1 esige-producto-main-detail border">
                <div className="row">
                  <div className="col-md-7">
                    <ProductoImages
                      magnifier={true}
                      images={
                        loading || !!!producto
                          ? [
                              {
                                src: imagenNoDisponible,
                                talle_id: null,
                                color_id: null,
                              },
                            ]
                          : producto.Imagenes
                      }
                      cartItem={cartItem}
                    />
                  </div>
                  <div className="col-md-5">
                    <div className="product__detail p-5">
                      <h4>{producto.Titulo}</h4>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: producto.Descripcion,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4 border">
                <div className="p-5">
                  <h3>{producto.Titulo}</h3>
                  {/* <p className="product__price">${new Intl.NumberFormat('es-ES')
                    .format(  Math.round(producto.Precio) ) }</p> */}

                  {producto.PrecioSuperOferta !== 0 ? (
                    <>
                      <p className="product__price">
                        $
                        {functions.FormatPrice(
                          producto.PrecioSuperOferta,
                          GlobalConfigTienda.NumeroDecimales
                        )}
                      </p>
                      <del className="priceOld -mt-n1 mb-4">
                        ${" "}
                        {functions.FormatPrice(
                          producto.Precio,
                          GlobalConfigTienda.NumeroDecimales
                        )}
                      </del>
                    </>
                  ) : (
                    <p className="product__price">
                      ${" "}
                      {functions.FormatPrice(
                        producto.Precio,
                        GlobalConfigTienda.NumeroDecimales
                      )}
                    </p>
                  )}

                  <p className="text-center">
                    <small>
                      Precio de lista $
                      {new Intl.NumberFormat("es-ES").format(
                        (producto.PrecioLista * 1.35).toFixed(0)
                      )}
                    </small>
                  </p>

                  <p className="my-5 text-center">--- Medios de pago ---</p>

                  <DescripcionPreciosModal PrecioBase={producto.Precio} />

                  <div className="row my-5 d-flex align-items-center text-center">
                    {/* <div className="col-12 col-xl-6">--- Cantidad ---</div> */}
                    <div className="col-12">
                      <CantidadSelectorController
                        className="ml-2 m-0 justify-content-center"
                        cantidadDisponible={stockDisponible}
                        cantidad={cantidad}
                        onChange={(cantidad) => setCantidad(cantidad)}
                      />
                    </div>
                  </div>

                  <button
                    className="btn btn-success btn-lg w-100"
                    disabled={!producto.IgnorarStock && stockDisponible <= 0}
                    // onClick={() => cartContext.setItem({ ...cartItem, quantity: cantidad, })}
                    // onClick={goCard}
                    onClick={() => (logged ? goCard() : ClickInLogin())}
                  >
                    Comprar ahora &gt;
                  </button>

                  <button
                    className="btn btn-success btn-success__light btn-lg mt-3 w-100"
                    disabled={!producto.IgnorarStock && stockDisponible <= 0}
                    onClick={() =>
                      cartContext.setItem({ ...cartItem, quantity: cantidad })
                    }
                  >
                    Agregar al carrito &gt;
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default ProductDetail;
