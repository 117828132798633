import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { NavLink, Row, Col, Button, Badge } from "reactstrap";

import { CartContext } from "../ParadigmaCartProvider";
import CantidadSelectorController from "../CantidadSelector/CantidadSelectorController";
import functions from "../../functions";

import { GlobalConfigTienda } from "../../../TiendaConfig";
import imagenNoDisponible from "../../assets/image_not_found.png";

const CartDetail = (props) => {
  const cartContext = useContext(CartContext);

  const updateData = () => {
    if (props.visible) cartContext.updateData();
  };

  const generateLink = (art) => {
    let url = `/tienda/producto/${art.code}/`;
    let params = [];
    if (art.talle_id) params.push(`talle_id=${art.talle_id}`);
    if (art.color_id) params.push(`color_id=${art.color_id}`);
    if (params.length > 0) url += `?${params.join("&")}`;
    return url;
  };

  useEffect(updateData, [props.visible]);

  return (
    !cartContext.cartState.loading &&
    cartContext.cartState.total > 0 &&
    cartContext.cartState.articulos.length > 0 && (
      <div className={props.className + " cart-detail rounded"}>
        <div className="cart-detail-summary">
          <ol className={"overflow-auto"}>
            {cartContext.cartState.articulos.map((art) => (
              <li key={`fixed-cart-details-${art.code}`}>
                <Row>
                  <Col xs={4} className="cart-detail__photo">
                    {art.Imagenes && art.Imagenes.length > 0 ? (
                      art.Imagenes.some(
                        (img) =>
                          img.Color_id == art.color_id &&
                          img.Talle_id == art.talle_id
                      ) ? (
                        <img
                          src={`${
                            art.Imagenes.filter(
                              (img) =>
                                img.Color_id == art.color_id &&
                                img.Talle_id == art.talle_id
                            )[0].src
                          }`}
                          alt={`Imagen Articulo ${art.Codigo}`}
                        />
                      ) : (
                        <img
                          src={`${art.Imagenes[0].src}`}
                          alt={`Imagen Articulo ${art.Codigo}`}
                        />
                      )
                    ) : (
                      <img
                        src={imagenNoDisponible}
                        alt={`Imagen de Articulo ${art.Codigo} no disponible`}
                      />
                    )}
                  </Col>
                  <Col xs={8}>
                    <Link to={generateLink(art)}>
                      <p className="cart-detail__title">{art.Titulo}</p>
                    </Link>
                    {art.talle_id && (
                      <>
                        <Badge color="info">
                          TALLE: {art.talle ? art.talle : "No Especificado"}
                        </Badge>
                        <br />
                      </>
                    )}
                    {art.color_id && (
                      <>
                        <Badge color="info">
                          COLOR: {art.color ? art.color : "No Especificado"}
                        </Badge>
                        <br />
                      </>
                    )}
                    <CantidadSelectorController
                      className="m-0"
                      cantidadDisponible={functions.getMaxQuantity(
                        art,
                        art.talle_id,
                        art.color_id
                      )}
                      cantidad={cartContext.getCantidadItem({
                        code: art.code,
                        talle_id: art.talle_id,
                        color_id: art.color_id,
                      })}
                      onChange={(cantidad) =>
                        cartContext.setItem(
                          {
                            code: art.code,
                            quantity: cantidad,
                            color_id: art.color_id,
                            talle_id: art.talle_id,
                          },
                          updateData
                        )
                      }
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={10} className="text-right">
                    {art.quantity > 1 ? (
                      <>
                        ${" "}
                        <strong>
                          {functions.FormatPrice(
                            art.Precio,
                            GlobalConfigTienda.NumeroDecimales
                          )}
                        </strong>{" "}
                        x {art.quantity} = ${" "}
                        <strong>
                          {functions.FormatPrice(
                            art.Precio * art.quantity,
                            GlobalConfigTienda.NumeroDecimales
                          )}
                        </strong>
                      </>
                    ) : (
                      <>
                        ${" "}
                        <strong>
                          {functions.FormatPrice(
                            art.Precio,
                            GlobalConfigTienda.NumeroDecimales
                          )}
                        </strong>{" "}
                        x {art.quantity}
                      </>
                    )}
                  </Col>
                  <Col xs={2}>
                    <Button
                      color="danger"
                      className="btn-delete"
                      onClick={() =>
                        cartContext.removeItem(
                          {
                            code: art.code,
                            talle_id: art.talle_id,
                            color_id: art.color_id,
                          },
                          updateData
                        )
                      }
                      size="sm"
                    >
                      <i className="fa fa-trash" aria-hidden="true"></i>
                    </Button>
                  </Col>
                </Row>
              </li>
            ))}
          </ol>
          <h3>
            <strong className="text-center">
              Total: $
              {functions.FormatPrice(
                cartContext.cartState.total,
                GlobalConfigTienda.NumeroDecimales
              )}
            </strong>
          </h3>
          <p>
            TOTAL SIN IVA:
            <strong>
              $
              {functions.FormatPrice(
                // cartContext.cartState.total,
                cartContext.cartState.total / 1.21,
                2
                // ,GlobalConfigTienda.NumeroDecimales
              )}
            </strong>
          </p>
        </div>
        <NavLink
          className="btn btn-success btn-proceder-compra"
          onClick={props.onClickCheckoutButton}
        >
          <i className="fa fa-credit-card-alt" aria-hidden="true"></i>
          {" PROCEDER A LA COMPRA"}
        </NavLink>
      </div>
    )
  );
};

export default CartDetail;
