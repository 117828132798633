import React, { Component, Fragment } from 'react';
import {Input} from 'reactstrap';

import api from "../../../api/api.js";
import apifunctions from "../../../api/functions.js";

class FormaPagoSelector extends Component{
     constructor(props){
          super(props);
          this.state={
               formasdepago:[],
          };
     }

     componentDidMount(){
          let successCallback = (data) => {
               let items = data.data;
               let checkedItems = items.filter(item=>item.checked == true);
               if(items.length > 0 && checkedItems.length == 0){
                    items[0].checked = true;
               }
               this.setState(
                    {formasdepago:items},
                    ()=>{
                         let checkedItem = items.filter(item=>item.checked == true);
                         if(checkedItem.length > 0 && this.props.onChange){
                              this.props.onChange(checkedItem[0].Codigo);
                         }
                    }
               );
          };
          let failCallback = () => {
               this.setState(
                    {formasdepago:[]},
                    ()=>{
                         if(this.props.onChange){
                              this.props.onChange(0);
                         }
                    }
               );
          };
          apifunctions.get(api.api.ventas.condicionesdepago, null, null, null, successCallback, failCallback, failCallback);
     }

     selectFormasDePago(formapagoID){
          this.setState(
               prevState => {
                    let list = prevState.formasdepago;
                    list.forEach(
                         item => {
                              item.checked = false;
                              if(item.Codigo == formapagoID){
                                   item.checked = true;
                              }
                         }
                    );
                    prevState.formasdepago = list;
                    return prevState;
               },
               () => this.props.onChange && this.props.onChange(formapagoID)
          );
     }

     render(){
          const formasdepago = this.state.formasdepago;
          return (
               formasdepago.length > 0 ?
               <React.Fragment>
               <h4 className="color">Condición De Pago</h4>
               <Input type="select" name="select" id="exampleSelect"
               disabled={false}
               onChange={(opt)=>this.selectFormasDePago(opt.target.value)}
               >
               {
                    formasdepago.map(
                         (item,index) =>
                         <option key={"formasdepago-"+String(index)} id={"formasdepago-"+String(index)}
                         value={item.Codigo}
                         selected={item.checked}
                         >
                         {item.Nombre}
                         </option>
                    )
               }
               </Input>
               </React.Fragment>
               :
               <h5 className="color">No posee condiciones de pago asociadas</h5>
          );
     }
}
export default FormaPagoSelector;
