import React from "react";
// import MapContainer from '../MapContainer/MapContainer';
// import ReactTooltip from "react-tooltip";
import "./Footer.scss";

// import logo from "../../assets/img/TuHogarOnline-circle.png";
// import { BsFacebook, BsInstagram, BsMailbox } from "react-icons/bs";
// import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        {/* <div className="container">
          <div className="row">
            <div className="col-md-4 my-5 text-center text-center">
              <Link to="/">
                <img
                  className="footer__logo"
                  src={logo}
                  alt={process.env.REACT_APP_TIENDA_NOMBRE}
                />
              </Link>
              <h4 className="mb-0">Comprar es simple</h4>
            </div>

            <div className="col-md-2 mt-2 mb-1 mt-sm-5 mb-sm-3 text-center text-md-left">
              <h3 className="footer__title">Contacto</h3>
              <address>
                8000 | Bahía Blanca <br />
                Buenos Aires, Argentina <br />
                <a
                  className="mt-1"
                  href="mailto:tuhogaronline@gmail.com?Subject=Consulta%20desde%20TuHogarOnline.com.ar&body=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20"
                >
                  tuhogaronline@gmail.com
                </a>
              </address>
              <p>
                Seguinos: <br />
                <a
                  className="footer__icon mt-2"
                  target="_blank"
                  href="https://www.facebook.com/Tu-Hogar-Online-7-107105210886009/"
                  alt="Seguinos en Facebook"
                >
                  <BsFacebook />
                </a>
                <a
                  className="footer__icon mt-2"
                  target="_blank"
                  href="https://www.instagram.com/tu_hogar_online/"
                  alt="Seguinos en Instagram"
                >
                  <BsInstagram />
                </a>
              </p>
            </div>

            <div className="col-md-3 mt-2 mb-1 mt-sm-5 mb-sm-3 text-center">
              <h3 className="footer__title">Enlaces</h3>
              <ul className="list-unstyled">
                <li>
                  <a className="footer__link" href="">
                    Inicio
                  </a>
                </li>
                <li>
                  <a className="footer__link" href="">
                    Productos
                  </a>
                </li>
                <li>
                  <a className="footer__link" href="">
                    Contacto
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-md-3 mt-2 mb-1 mt-sm-5 mb-sm-3 text-center">
              <h3 className="footer__title">Cuenta</h3>
              <ul className="list-unstyled">
                <li>
                  <a className="footer__link" href="">
                    Iniciar Sesión
                  </a>
                </li>
                <li>
                  <a className="footer__link" href="">
                    Registro
                  </a>
                </li>
                <li>
                  <a className="footer__link" href="">
                    Carrito
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div> */}

        <div className="footer__copy">
          <div className="container">
            <div className="row">
              <div className="col-md-8 text-center text-md-left">
                {process.env.REACT_APP_TIENDA_NOMBRE} ©
                {new Date().getFullYear()} | Todos los derechos reservados.
              </div>
              <div className="col-md-4 text-center text-md-right">
                Desarrollado por:{" "}
                <a href="https://www.paradigma.com.ar/">
                  Paradigma del Sur S.A.{" "}
                  <img
                    src="https://paradigma.com.ar/img/favicon.ico"
                    height="16"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <a
        data-for="sucursal"
        data-tip="Chiclana"
        data-iscapture="true"
        title="Whatsapp de Chiclana 602"
        href="https://api.whatsapp.com/send?phone=542914563746&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20"
        className="float float_1" target="_blank">
        <i className="fa fa-whatsapp my-float"></i>
      </a> */}
      {/* <a
        data-for="sucursal"
        data-tip="Consúltanos"
        data-iscapture="true"
        title="Email"
        // href="https://api.whatsapp.com/send?phone=542915111792&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20"
        href="mailto:tuhogaronline@gmail.com?Subject=Consulta%20desde%20TuHogarOnline.com.ar&body=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20"
        className="float float_2"
        target="_blank"
      >
        <i className="fa fa-envelope-o my-float"></i>
      </a>
      <ReactTooltip
        id="sucursal"
        place="top"
        type="warning"
        effect="float"
        multiline={false}
      /> */}
    </>
  );
};

export default Footer;
