import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// import { api } from "../Tienda/api/api";
// import apifunctions from "../Tienda/api/functions";

import "./MainSearch.scss";

const MainSearchForm = (props) => {
  const [search, setSearch] = useState("");
  // const [rubro, setRubro] = useState("");

  // const [rubros, setRubros] = useState([]);

  const searchUpdated = (evt) => {
    if (evt.key !== "Enter") {
      const newState = evt.target.value;
      setSearch(newState);
    }
  };

  // const rubroUpdate = (evt) => {
  //   setRubro(evt.target.value ? evt.target.value : "");
  // }

  const navigateToProductos = (navigate) => {
    if (navigate) {
      let searchBtn = document.getElementById("searchBtn");
      if (searchBtn && searchBtn.click) searchBtn.click();
    }
  };

  // useEffect(() => {
  //   const getAndProcess = (reintento = 0) => {
  //     const successCallback = (response) => {
  //       const sortFn = (a, b) => a.Nombre > b.Nombre ? 1 : (a.Nombre < b.Nombre ? -1 : 0);
  //       const data = response.data.sort(sortFn);
  //       setRubros(data);
  //       setRubro(`-${data[0].identifier}${data[0].code}`)
  //     }
  //     const failureCallback = () => {
  //       if (reintento < 3) getAndProcess(reintento + 1);
  //       else {
  //         setRubros([]);
  //         setRubro("");
  //       }
  //     }
  //     apifunctions.get(api.articulos.rubros, null, null, {}, successCallback, failureCallback, failureCallback);
  //   }
  //   getAndProcess();
  // }, []);

  return (
    <div className={props.className}>
      <div className="mainSearchForm form-row align-items-center">
        {/* <Link id="searchBtn" to={`/tienda/productos/${rubro}${search !== "" ? `?search=${search}` : ""}`} /> */}
        <Link
          id="searchBtn"
          to={`/tienda/productos/${search !== "" ? `?search=${search}` : ""}`}
        />
        <div className="col-10 col-md-auto my-1">
          <label
            className="control-label sr-only"
            htmlFor="customControlAutosizing"
          ></label>
          <input
            type="text"
            value={search}
            onChange={searchUpdated}
            onKeyDown={(evt) => navigateToProductos(evt.key === "Enter")}
            className="form-control input-search"
            placeholder="¿Qué estás buscando?"
          />
        </div>
        {
          // rubros.length > 0 &&
          // <div className="col-12 col-md-auto my-1">
          //   <label className="mr-sm-2 sr-only" htmlFor="inlineFormCustomSelect">
          //     Categorías
          //   </label>
          //   <select className="custom-select mr-sm-2" id="inlineFormCustomSelect" onChange={rubroUpdate}>
          //     <optgroup label="Categorías">
          //       {
          //         rubros.map((rubro) => (
          //           <option key={`rubro-${rubro.identifier}${rubro.code}`} value={`-${rubro.identifier}${rubro.code}`}>{rubro.Nombre}</option>
          //         ))
          //       }
          //     </optgroup>
          //   </select>
          // </div>
        }
        <div className="col-2 col-md-auto my-1">
          <button
            onClick={() => navigateToProductos(true)}
            className="btn btn-default w-100 d-flex align-items-center"
          >
            <i className="fa fa-search mr-0 mr-sm-1" aria-hidden="true"></i>{" "}
            <span className="d-none d-md-block mt-0 mt-sm-1">BUSCAR</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MainSearchForm;
