import React from "react";
import Tienda from "../../components/Tienda";
import FloatCheckoutButton from "../../components/Tienda/components/CheckoutButton/FloatCheckoutButton";
import "./styles.scss";

const TiendaPage = (props) => {
  return (
    <div className="container">
      <Tienda {...props} />
      <FloatCheckoutButton />
    </div>
  );
};

export default TiendaPage;
