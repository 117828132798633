import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { CartContext } from "../Tienda/components/ParadigmaCartProvider/ParadigmaCartProvider";
import functions from "../Tienda/functions/functions";
import { GlobalConfigTienda } from "../TiendaConfig";
import CantidadSelectorController from "../Tienda/components/CantidadSelector/CantidadSelectorController";

import "./ProductoBoxLine.scss";
import { LoginContext } from "../Tienda/components/ParadigmaLoginProvider/ParadigmaLoginProvider";

const PromoIcon = ({ text }) => {
  const colorPercentBased = () => "#e5620d";
  return (
    <svg width="72" height="32" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <filter id="shadow">
          <feDropShadow
            dx="3"
            dy="3"
            stdDeviation="2"
            flood-color="#f6a32c"
            flood-opacity="0.5"
          ></feDropShadow>
        </filter>
      </defs>
      <g style={{ filter: "url(#shadow)" }}>
        <path
          d="M0,0 L72,0 L63,14 L72,28 L0,28 Z"
          fill={colorPercentBased()}
        ></path>
        <text
          x="43%"
          y="50%"
          fill="white"
          dominant-baseline="middle"
          text-anchor="middle"
        >
          PROMO
        </text>
      </g>
    </svg>
  );
};

function removeTags(str) {
  if (str === null || str === "") return false;
  else str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, "");
}

// const OffIcon = ({ percent }) => {
//   const colorPercentBased = (percent) => {
//     const red = "#e52233";
//     const softOrange = "#f6a32c";
//     const darkOrange = "#e5620d";
//     return percent >= 25 ? red : percent > 15 ? darkOrange : softOrange;
//   };
//   return (
//     <svg width="40" height="60" xmlns="http://www.w3.org/2000/svg">
//       <defs>
//         <filter id="shadow">
//           <feDropShadow
//             dx="3"
//             dy="3"
//             stdDeviation="2"
//             flood-color={colorPercentBased(percent)}
//             flood-opacity="0.5"
//           ></feDropShadow>
//         </filter>
//       </defs>
//       <g style={{ filter: "url(#shadow)" }}>
//         <path
//           d="M40,0 L0,0 L0,60 L20,45 L40,60 L40,0z"
//           fill={colorPercentBased(percent)}
//         ></path>
//         <text
//           x="50%"
//           y="20%"
//           fill="white"
//           dominant-baseline="middle"
//           text-anchor="middle"
//         >
//           {percent}%
//         </text>
//         <text
//           x="50%"
//           y="50%"
//           fill="white"
//           dominant-baseline="middle"
//           text-anchor="middle"
//         >
//           OFF
//         </text>
//       </g>
//     </svg>
//   );
// };

const getPrecio = (articulo, ofertaPeriodo, ofertaCantidad, superoferta) => {
  let price = articulo.Precio;
  switch (true) {
    case ofertaPeriodo && !superoferta:
      price = articulo.Precio;
      break;
    case ofertaCantidad && !superoferta:
      price = articulo.OfertaNxM_Precio;
      break;
    case !ofertaPeriodo && !ofertaCantidad && superoferta:
      price = articulo.PrecioSuperOferta;
      break;
    default:
      price = articulo.Precio;
  }
  price = price * (1 + articulo.MargenRentabilidad / 100);
  // price = price * (1 + localStorage.getItem("MG") / 100);
  return functions.FormatPrice(price, GlobalConfigTienda.NumeroDecimales);
};

const ProductoBoxView = (props) => {
  const {
    // link,
    image,
    //title,
    oldPrice,
    price,
    descuento,
    articulo: producto,
  } = props;

  const { userProfile } = useContext(LoginContext);
  let descuentoPerfil = +userProfile.Descuento;

  // descontar el iva a los precios
  // let precio = price.replaceAll(".", "") / 1.21; --> No usar
   let precio = producto.PrecioLista / 1.21;
  // let precio = producto.PrecioLista;

  const cartItem = producto
    ? { code: producto.code, talle_id: null, color_id: null }
    : null;

  const cartContext = useContext(CartContext);
  const enCarrito = cartContext.getCantidadItem({ ...cartItem });
  const [cantidad, setCantidad] = useState(enCarrito);

  useEffect(() => {
    setCantidad(enCarrito);
  }, [enCarrito]);

  const history = useHistory();

  return (
    <>
      <div className="productItem">
        {descuento > 0 && (
          <div className="productItem__off productItem__off--orange productItem__off--Promo">
            <PromoIcon text="Promo" />
            {/* <OffIcon percent={descuento} /> */}
          </div>
        )}
        {/* <Link to={link} className="productItem__photoContainer"> */}
        <img src={image.src} alt="" className="productItem__photo" />
        {/* </Link> */}
        {/* <h2 className="productItem__title" onClick={() => history.push(link)}> */}
        <h2 className="productItem__title">
          <span className="badge badge-light mr-1">{producto.Codigo}</span>

          {removeTags(producto.Descripcion)}
        </h2>

        <div className="d-flex align-items-center">
          <p className="mb-0">${functions.FormatPrice(precio)}</p>
        </div>

        <div className="productItem__price">
          {oldPrice && descuento > 0 ? (
            <>
              {oldPrice && <del className="priceOld">${oldPrice}</del>}
              <h3 className={"mb-0 price " + (oldPrice && "price__offer")}>
                ${functions.FormatPrice(precio)}
              </h3>
            </>
          ) : (
            <h3 className={"mb-0 price mx-auto"}>
              $
              {functions.FormatPrice(
                precio * (1 + localStorage.getItem("MG") / 100)
                // GlobalConfigTienda.NumeroDecimales
              )}
            </h3>
          )}
        </div>

        <CantidadSelectorController
          className="ml-2 m-0 justify-content-center"
          cantidadDisponible={producto.maxQuantity}
          cantidad={cantidad}
          onChange={(cantidad) => setCantidad(cantidad)}
        />
        <button
          className="btn btn-success btn-success__light"
          disabled={!producto.IgnorarStock && producto.maxQuantity <= 0}
          onClick={() =>
            cartContext.setItem({ ...cartItem, quantity: cantidad })
          }
        >
          <svg
            className="cart__svg"
            fill="#107427"
            id="floating-cart-icon"
            enableBackground="new 0 0 511.343 511.343"
            height="20"
            viewBox="0 0 511.343 511.343"
            width="20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="m490.334 106.668h-399.808l-5.943-66.207c-.972-10.827-10.046-19.123-20.916-19.123h-42.667c-11.598 0-21 9.402-21 21s9.402 21 21 21h23.468c12.825 142.882-20.321-226.415 24.153 269.089 1.714 19.394 12.193 40.439 30.245 54.739-32.547 41.564-2.809 102.839 50.134 102.839 43.942 0 74.935-43.826 59.866-85.334h114.936c-15.05 41.455 15.876 85.334 59.866 85.334 35.106 0 63.667-28.561 63.667-63.667s-28.561-63.667-63.667-63.667h-234.526c-15.952 0-29.853-9.624-35.853-23.646l335.608-19.724c9.162-.538 16.914-6.966 19.141-15.87l42.67-170.67c3.308-13.234-6.71-26.093-20.374-26.093zm-341.334 341.337c-11.946 0-21.666-9.72-21.666-21.667s9.72-21.667 21.666-21.667c11.947 0 21.667 9.72 21.667 21.667s-9.72 21.667-21.667 21.667zm234.667 0c-11.947 0-21.667-9.72-21.667-21.667s9.72-21.667 21.667-21.667 21.667 9.72 21.667 21.667-9.72 21.667-21.667 21.667zm47.366-169.726-323.397 19.005-13.34-148.617h369.142z" />
          </svg>
        </button>
        {/* <button onClick={() => history.push(link)} className="btn btn-success btn-productobox">
          Ver producto <i className="fa fa-arrow-circle-right" aria-hidden="true"></i>
        </button> */}
      </div>
    </>
  );
};

const ProductoBox = ({ articulo }) => {
  const { setItem, getCantidadItem, isItemPresent } = useContext(CartContext);

  const ofertaPeriodo =
    articulo.OfertaPeriodo_FechaDesde != null &&
    articulo.OfertaPeriodo_FechaHasta != null &&
    Date.parse(articulo.OfertaPeriodo_FechaHasta) >= Date.now();
  const ofertaCantidad = articulo.OfertaNxM_Cantidad > 0;
  const superoferta = articulo.DescuentoSuperOferta > 0;

  const addCart = () => {
    let cantItem = isItemPresent({
      code: articulo.code,
      talle_id: null,
      color_id: null,
    })
      ? getCantidadItem({ code: articulo.code, talle_id: null, color_id: null })
      : 0;
    setItem({
      code: articulo.code,
      talle_id: null,
      color_id: null,
      quantity: cantItem + 1,
    });
  };

  return (
    <ProductoBoxView
      link={"/tienda/producto/" + articulo.code}
      image={articulo.Imagenes[0]}
      category={
        articulo.Rubro && articulo.Rubro.Nombre
          ? articulo.Rubro.Nombre
          : "Sin Rubro"
      }
      title={articulo.Titulo}
      oldPrice={
        ofertaPeriodo || ofertaCantidad || superoferta
          ? getPrecio(articulo, false, false, false)
          : null
      }
      price={getPrecio(articulo, ofertaPeriodo, ofertaCantidad, superoferta)}
      addCart={addCart}
      descuento={articulo.DescuentoSuperOferta}
      articulo={articulo}
    />
  );
};

export default ProductoBox;
