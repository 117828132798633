import React, { Fragment } from "react";
import { Card, CardText, CardBody, CardHeader, CardFooter } from "reactstrap";

import format from "../../../../functions/functions.js";
import DetalleCompraArticulo from "./DetalleCompraArticulo";
import { GlobalConfigTienda } from "../../../../../TiendaConfig.js";

// function roundUp(num) {
//   return Math.ceil(num / 100) * 100 - 1;
// }

const GoBackButton = (props) => {
  let goBack = (evt) => {
    evt.preventDefault();
    props.history.push("/tienda/productos/");
  };

  return (
    <div
      className={
        props.className +
        (props.history.length === 1
          ? "d-none"
          : " btn-arrow-left cursor-pointer")
      }
      onClick={(evt) => goBack(evt)}
    >
      <svg width="250" height="40" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <filter id="shadow">
            <feDropShadow
              dx="3"
              dy="3"
              stdDeviation="2"
              flood-color="#000000"
              flood-opacity="0.25"
            ></feDropShadow>
          </filter>
        </defs>
        <g style={{ filter: "url(#shadow)" }}>
          <path
            d="M250,0 L25,0 L0,18 L25,36 L250,36 L200,18 L250,0z"
            fill="#555"
          ></path>
          <text x="35" y="24" fill="white">
            VOLVER AL LISTADO
          </text>
        </g>
      </svg>
    </div>
  );
};

const TotalFooter = (props) => {
  const { cartState } = props;
  return (
    <Fragment>
      {cartState.montoIIBB != undefined && cartState.montoIIBB > 0 && (
        <Fragment>
          <CardText>
            Subtotal: $
            {format.FormatPrice(
              cartState.subtotal,
              2 // GlobalConfigTienda.NumeroDecimales
            )}
          </CardText>
          <CardText>
            Percepción IIBB {cartState.porcentajeIIBB}%: $
            {format.FormatPrice(
              cartState.montoIIBB,
              2 // GlobalConfigTienda.NumeroDecimales
            )}
          </CardText>
        </Fragment>
      )}
      <CardText className="w-100 h2">
        Total: $
        {format.FormatPrice(
          cartState.total,
          2 // GlobalConfigTienda.NumeroDecimales
        )}
        <small style={{ display: "block", fontSize: ".9rem" }}>
          * Precio IVA Incluido
        </small>
      </CardText>
      <CardText className="w-100 h2">
        ${format.FormatPrice(
          cartState.subtotal,
          2 // GlobalConfigTienda.NumeroDecimales
        )}
        <small style={{ display: "block", fontSize: ".9rem" }}>
          * Precio sin IVA
        </small>
      </CardText>
      
    </Fragment>
  );
};

export default (props) => {
  const { cartState, history } = props;
  return (
    <Card>
      <CardHeader>
        <h3 className="float-left mb-0">Detalle de Compra</h3>
        <GoBackButton className="float-right" history={history} />
      </CardHeader>
      <CardBody>
        {cartState.articulos.map((art, i) => (
          <DetalleCompraArticulo art={art} index={i} {...props} />
        ))}
      </CardBody>
      <CardFooter className="text-right pr-5">
        <TotalFooter {...props}></TotalFooter>
      </CardFooter>
    </Card>
  );
};
