import React, { Fragment, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Input,
  Row,
  Col,
} from "reactstrap";
import Select from "react-select";
import "react-select/dist/react-select.css";

// import logo from "../../assets/img/TuHogarOnline-circle.png";
import tarjVisa from "../../assets/img/tarjetas/visa.png";
import tarjNaranja from "../../assets/img/tarjetas/naranja.png";
import tarjMaster from "../../assets/img/tarjetas/mastercard.png";
import tarjCabal from "../../assets/img/tarjetas/cabal.png";
import mercadopago from "../../assets/img/tarjetas/Mercadopago.jpg";

/**
 *Las cuotas también se deben modificar en Cart-->Cuotas.js
 */
const tarjetas = [
  // {
  //     imageSrc: logo,
  //     name: "Credito Personal",
  //     cuotas: [
  //         { nro: 3, interes: 0, label: '3 Cuotas' },
  //         { nro: 6, interes: 40, label: '6 Cuotas' },
  //         { nro: 8, interes: 60, label: '8 Cuotas' },
  //         { nro: 12, interes: 80, label: '12 Cuotas' },
  //     ],
  // },

  //     imageSrc: tarjNaranja,
  //     name: "Tarjeta Naranja",
  //     cuotas: [
  //         // { nro: 1, interes: 12, label: 'Plan Z' },
  //         { nro: 5, interes: 0, label: '5 Cuotas' },
  //         { nro: 10, interes: 20, label: '10 Cuotas'},
  //         { nro: 18, interes: 70, label: '18 Cuotas' },
  //     ],
  // },
  {
    imageSrc: tarjVisa,
    name: "Tarjeta Visa",
    cuotas: [
      { nro: 1, interes: 0, label: "1 Cuota" },
      { nro: 3, interes: 35, label: "3 Cuotas" },
      { nro: 6, interes: 50, label: "6 Cuotas" },
      { nro: 12, interes: 120, label: "12 Cuotas" },
      // { nro: 18, interes: 80, label: '18 Cuotas' },
    ],
  },
  {
    imageSrc: tarjMaster,
    name: "Tarjeta MasterCard",
    cuotas: [
      { nro: 1, interes: 0, label: "1 Cuota" },
      { nro: 3, interes: 35, label: "3 Cuotas" },
      { nro: 6, interes: 50, label: "6 Cuotas" },
      { nro: 12, interes: 120, label: "12 Cuotas" },
      // { nro: 18, interes: 80, label: '18 Cuotas' },
    ],
  },
  // {
  // {
  //     imageSrc: tarjCabal,
  //     name: "Tarjeta Cabal",
  //     cuotas: [
  //         { nro: 1, interes: 0, label: '1 Cuota' },
  //         { nro: 3, interes: 0, label: '3 Cuotas' },
  //         { nro: 6, interes: 20, label: '6 Cuotas' },
  //         { nro: 12, interes: 50, label: '12 Cuotas' },
  //         { nro: 18, interes: 80, label: '18 Cuotas' },
  //     ],
  // },
  // {
  //     imageSrc: tarjNativa,
  //     name: "Tarjeta Nativa",
  //     cuotas: [
  //         { nro: 1, interes: 0, label: '1 Cuota' },
  //         { nro: 3, interes: 0, label: '3 Cuotas' },
  //         { nro: 6, interes: 0, label: '6 Cuotas' },
  //         // { nro: 12, interes: 20, label: '12 Cuotas' },
  //         // { nro: 18, interes: 40, label: '18 Cuotas' },
  //     ],
  // },
  // {
  //     imageSrc: mercadopago,
  //     name: "Mercado Pago",
  //     cuotas: [
  //         { nro: 1, interes: 0, label: '1 Cuota' },
  //     ],
  // },
];

// const formatPriceWithoutDecimals = (price) => {
//     return Math.round(price).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
// },
// const formatPrice = (price) => {
//    let price_ = price.toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".").split(',');
//    if (price_.length === 1) {
//        price_[1] = "00";
//    } else if (price_[1].length === 1) {
//        price_[1] = price_[1] + "0";
//    }
//    return price_.join(',');
// }

export const DescripcionPreciosModal = ({ PrecioBase }) => {
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
  const [activeCard, setActiveCart] = useState(0);
  /**
   * Teniendo en cuenta el Ticket #00006969 se realizará la financación de las tarjetas sobre el PrecioLista
   * El descuento por pago contado se tendrá sobre el precioLista
   * De ser necesario realizar la finaciación sobre el precioContado, tener en cuenta la línea comentada
   */
  const ValorCuota = (interes, nroCuotas) =>
    `$ ${new Intl.NumberFormat("de-DE").format(
      Math.ceil((PrecioBase * (1 + interes / 100)) / nroCuotas)
    )}`;
  // const ValorCuota = (interes, nroCuotas, pContado = true) => `$ ${((pContado ? PrecioBase : Precio) * (1 + interes / 100) / nroCuotas))}`;

  const Intereses = (interes) =>
    `$ ${new Intl.NumberFormat().format(
      Math.ceil((PrecioBase * interes) / 100)
    )}`;
  const TotalFinanciado = (interes) =>
    `$ ${new Intl.NumberFormat().format(
      Math.ceil(PrecioBase * (1 + interes / 100))
    )}`;

  // const Intereses = (interes) => `$  ${(PrecioBase * interes / 100)}`;
  // const TotalFinanciado = (interes) => `$ ${(PrecioBase * (1 + interes / 100))}`;

  return (
    <div className="">
      <Button className="w-100" color="primary" onClick={toggleModal}>
        <i className="fa fa-credit-card-alt" aria-hidden="true"></i>
        {" Ver cuotas y medios de pago"}
      </Button>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Cuotas y Formas de Pago</ModalHeader>
        <ModalBody className="px-5">
          <Row>
            <Col xs={9} className="my-auto">
              <Input
                value={activeCard}
                type="select"
                onChange={(evt) => setActiveCart(evt.target.value)}
              >
                {tarjetas.map((card, index) => (
                  <option value={index}>{card.name}</option>
                ))}
              </Input>
            </Col>
            <Col xs={3} className="my-1">
              <img
                src={tarjetas[activeCard].imageSrc}
                alt={tarjetas[activeCard].name}
                height="40"
                className="rounded bg-light"
              />
            </Col>
          </Row>
          <Table>
            <thead>
              <tr>
                <th>Cantidad de Cuotas</th>
                <th>Valor de Cuota</th>
                {/* <th>Intereses</th> 
                <th>Total Financiado</th>*/}
              </tr>
            </thead>
            <tbody>
              {tarjetas[activeCard].name === "Tarjeta Naranja" ? (
                <Fragment>
                  <td className="text-center text-dark bg-warning" colSpan={3}>
                    <strong>Plan Z con un 12% de interés</strong>
                  </td>
                  {/* 12% con Plan Z */}
                  <td className="text-dark bg-warning">
                    <strong>{TotalFinanciado(12)}</strong>
                  </td>
                </Fragment>
              ) : (
                <Fragment></Fragment>
              )}
              {tarjetas[activeCard].cuotas.map((cuota, index) => (
                <tr key={`card-${activeCard}-cuota-${index}`}>
                  <td className="pl-5">{cuota.label}</td>
                  <td className="pl-4">
                    {ValorCuota(cuota.interes, cuota.nro)}
                  </td>
                  {/* <td>{Intereses(cuota.interes)}</td> 
                  <td>{TotalFinanciado(cuota.interes)}</td>*/}
                </tr>
              ))}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
