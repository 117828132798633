import React, { Component, } from "react";
import { Button, } from 'reactstrap';

import TransportesSelector from "./TransportesSelector.js";
import TarjetasSelector from "./TarjetasSelector.js";
import FormaPagoSelector from "./FormaPagoSelector.js";
import BuyerForm from "./BuyerFrom/BuyerForm.js";
import DetalleCompra from "./DetalleCompra/DetalleCompra.js";

import api from "../../../api/api.js";
import apifunctions from "../../../api/functions.js";
import { View } from "../../../../TiendaConfig.js";

export default class CartDetailed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buyer: {},
      Observaciones: "",
      idTransporte: null,

      validaUsuario: false,
      sendingData: false,
      modified: true,

      modalRedirect: false,
      loadingFirstUpdate: true,
    };
  }

  componentDidMount() {
    document.querySelector("html").scrollTo(0, 0);
    const successCallback = (response) => {
      if (response && response.data) {
        let data = response.data;
        let buyer = {
          disabled: true,
          Nombre: data.Nombre,
          TipoDoc_id: data.TipoDocumento_id,
          SituacionIVA_id: data.SituacionIVA_id,
          Localidad_id: data.Localidad_id,
          Documento: data.NroDoc,
          DomicilioEntrega: data.DomicilioEntrega,
          Domicilio: data.Domicilio,
          CodPostal: data.CodPostal,
          Telefono: data.Telefono,
          Email: data.Email
        }
        this.setState({ buyer: buyer }, () => this.props.updateData && this.props.updateData(() => this.setState({ loadingFirstUpdate: false })));
      } else {
        this.props.updateData && this.props.updateData(() => this.setState({ loadingFirstUpdate: false }));
      }
    }
    const failureCallback = () => this.props.updateData && this.props.updateData(() => this.setState({ loadingFirstUpdate: false }));

    apifunctions.get(
      api.api.clientes.cliente.perfil,
      null, null, { fields: ["Nombre", "TipoDocumento_id", "NroDoc", "DomicilioEntrega", "Domicilio", "CodPostal", "Telefono", "Localidad", "Email"] },
      successCallback,
      failureCallback,
      failureCallback
    )



  }

  generarPedido() {
    const { removeItems, cartState, onSuccessfulOrder } = this.props;
    const { validaUsuario, Observaciones, idTransporte, buyer } = this.state;
    if (cartState.articulos.length > 0 && validaUsuario) {
      document.querySelector('html').scrollTo(0, 0);
      const toRemove = cartState.articulos
        .filter(art => art.checked == true)
        .map(x => ({ code: x.code, talle_id: x.talle_id, color_id: x.color_id }));
      let obj = {
        Observaciones: Observaciones ? Observaciones : "",
        otrosFacturables: [],
        Articulos: cartState.articulos
          .filter(art => art.checked == true)
          .map(x => ({ Codigo: x.Codigo, Cantidad: x.quantity, Color_id: x.color_id, Talle_id: x.talle_id })),
        idTransporte: idTransporte,
        CodigoCondicionDePago: cartState.CodigoCondicionDePago,
        CodigoPlanPagoConTarjeta: cartState.CodigoPlanPagoConTarjeta,
        clienteAnonimo: buyer
      };
      this.setState({ sendingData: true }, () => {
        apifunctions.post(
          api.api.ventas.pedidosGenerar,
          null,
          obj,
          response => {
            const data = response.data;
            this.setState({ sendingData: false }, () => {
              const callback = () => onSuccessfulOrder && onSuccessfulOrder(data);
              removeItems(toRemove, callback);
            });
          },
          () => this.setState({ sendingData: false }),
          () => this.setState({ sendingData: false })
        );
      });
    }
  }

  isDisabledSubmitButton() {
    const { total, articulos } = this.props.cartState;
    const { validaUsuario, sendingData } = this.state;
    return total == 0 || validaUsuario == false || articulos.length == 0 || sendingData;
  }

  render() {
    const { buyer, loadingFirstUpdate } = this.state;
    const {
      setItem, removeItem, updateData, setCodigoCondicionDePago,
      cartState
    } = this.props;

    let loading = loadingFirstUpdate || cartState.loading;

    return (
      <View.LoadingScreen loading={loading}>
        <div className="container">
          <div className="row esige-cart my-4 p-4 d-flex justify-content-center">
            <div className={loading ? "col-12 vh-100" : "col-12"}>
              <div>
                {!loading &&
                  <DetalleCompra
                    cartState={cartState}
                    history={this.props.history}
                    onChangeCantidad={({ code, quantity, color_id, talle_id }) => setItem({ code, quantity, color_id, talle_id }, () => updateData())}
                    removeItem={code => removeItem(code, () => updateData())}
                  />
                }
                <div className="col-12">
                  <hr />
                </div>
                <FormaPagoSelector
                  onChange={CodigoCondicionDePago => setCodigoCondicionDePago(CodigoCondicionDePago, cartState.CodigoPlanPagoConTarjeta, () => updateData())}
                />
                <TarjetasSelector
                  CodigoCondicionDePago={cartState.CodigoCondicionDePago}
                  total={cartState.total}
                  onChange={CodigoPlanPagoConTarjeta => setCodigoCondicionDePago(cartState.CodigoCondicionDePago, CodigoPlanPagoConTarjeta, () => updateData())}
                />
                <div className="col-12"><hr /></div>
                {
                  View.TransportesSelector
                  ? <View.TransportesSelector onChange={(id) => this.setState({ idTransporte: id })} />
                  : <TransportesSelector onChange={(id) => this.setState({ idTransporte: id })} />
                }
                <div className="col-12">
                  <hr />
                </div>
                {
                  !loadingFirstUpdate &&
                  <BuyerForm
                    buyer={buyer}
                    onChange={(isValid, buyer) => this.setState({ validaUsuario: isValid, buyer: buyer })}
                  />
                }
                <div className="col-12">
                  <hr />
                </div>
                <Observaciones
                  onChange={obs => this.setState({ Observaciones: obs })}
                />
                <div className="col-12 esige-cart-price my-2 col-12 esige-cart-price my-2 text-center">
                  <Button
                    disabled={this.isDisabledSubmitButton()}
                    color={this.isDisabledSubmitButton() ? "secondary" : "success"}
                    size="lg"
                    className="btn center-block rounded"
                    onClick={() => this.generarPedido()}
                  >
                    <i className="fa fa-thumbs-up" aria-hidden="true"></i>{" Confirmar Orden de Compra"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </View.LoadingScreen>
    );
  }
}

const Observaciones = (props) => {
  return (
    <div className="">
      <h4 className="color">Observaciones</h4>
      <textarea className="form-control" row="1" col="75" onChange={(e) => props.onChange && props.onChange(e.target.value)} name="Observaciones" placeholder="Ingrese aquí sus Observaciones"></textarea>
    </div>
  );
}
