import React from 'react';

import { View } from "../../../TiendaConfig";

const OfertasView = (props) => (
    <div className="productos-ofertados-container my-2">
        {props.title &&
            <h2 className="title">
                <strong>{props.title ? props.title : ""}</strong>
                {/* <span>de nuestra tienda</span> */}
            </h2>
        }
        {
            props.ofertas.filter(item => item.Articulos.length > 0).map((item, index) => (
                <View.OfertaView
                    key={"oferta" + index}
                    updateURL={(url) => props.updateURL && props.updateURL(url)}
                    code={item.code}
                    titulo={item.Titulo}
                    articulos={item.Articulos}
                />
            ))
        }
    </div>
);

export default OfertasView;