import React, { useState, useEffect } from "react";
import PageNavigation from "../Tienda/components/Navigation/Pagination";
import ProductosHeader from "../Tienda/views/Productos/Header/ProductosHeader";
// import ProductosFilter from './Filter/ProductosFilter';
import ProductosFilter from "../Tienda/views/Productos/Filter/ProductosFilter";

// import ParadigmaHeaderMenu from '../ParadigmaHeaderMenu';

import { View } from "../TiendaConfig";
import Loader from "react-loader-spinner";

// import api from "../Tienda/api";
// import functions from "../Tienda/api/functions.js";

// let dataMenu = [
//   {
//     "id": 3248,
//     "Nombre": "COLCHONES & SOMIER",
//     "href": "/productos/lyl2/-",
//     "submenues": [
//       {
//         "id": 3279,
//         "Nombre": "BASE DE SOMMIER",
//         "href": "/productos/lyl2/-cwrqd",
//         "submenues": []
//       },
//       {
//         "id": 3251,
//         "Nombre": "ALMOHADAS",
//         "href": "/productos/lyl2/-cxnhl",
//         "submenues": []
//       },
//       {
//         "id": 3252,
//         "Nombre": "ROPA DE CAMA",
//         "href": "/productos/lyl2/-ci9d9",
//         "submenues": [
//           {
//             "id": 3253,
//             "Nombre": "SABANAS",
//             "href": "/productos/lyl2/-czk9c",
//             "submenues": []
//           },
//           {
//             "id": 3255,
//             "Nombre": "ACOLCHADOS",
//             "href": "/productos/lyl2/-cug3s",
//             "submenues": []
//           }
//         ]
//       },
//       {
//         "id": 3277,
//         "Nombre": "COLCHONES",
//         "href": "/productos/lyl2/-cv4y2",
//         "submenues": []
//       },
//       {
//         "id": 3278,
//         "Nombre": "CONJUNTOS DE SOMMIER",
//         "href": "/productos/lyl2/-c56dw",
//         "submenues": []
//       }
//     ]
//   },
//   {
//     "id": 2157,
//     "Nombre": "BEBES Y NIÑOS",
//     "href": "/productos/1hyu/-",
//     "submenues": [
//       {
//         "id": 3187,
//         "Nombre": "JUEGOS DE AIRE LIBRE",
//         "href": "/productos/1hyu/-cs35s",
//         "submenues": [
//           {
//             "id": 3198,
//             "Nombre": "METEGOL",
//             "href": "/productos/1hyu/-cb92x",
//             "submenues": []
//           },
//           {
//             "id": 3200,
//             "Nombre": "JARDIN",
//             "href": "/productos/1hyu/-c93w1",
//             "submenues": []
//           }
//         ]
//       },
//       {
//         "id": 2160,
//         "Nombre": "SILLA DE COMER",
//         "href": "/productos/1hyu/-c08f4",
//         "submenues": []
//       },
//       {
//         "id": 2163,
//         "Nombre": "BUTACA PARA AUTO",
//         "href": "/productos/1hyu/-c2uxd",
//         "submenues": []
//       },
//       {
//         "id": 3185,
//         "Nombre": "JUGUETES",
//         "href": "/productos/1hyu/-cqrx1",
//         "submenues": [
//           {
//             "id": 5348,
//             "Nombre": "DINOSAURIOS",
//             "href": "/productos/1hyu/-c4ldp",
//             "submenues": []
//           },
//           {
//             "id": 5349,
//             "Nombre": "PISTOLAS",
//             "href": "/productos/1hyu/-cjbzr",
//             "submenues": []
//           },
//           {
//             "id": 5350,
//             "Nombre": "BELLEZA",
//             "href": "/productos/1hyu/-c5r0a",
//             "submenues": []
//           },
//           {
//             "id": 3274,
//             "Nombre": "JUEGOS DE ROL",
//             "href": "/productos/1hyu/-cl9g6",
//             "submenues": []
//           },
//           {
//             "id": 3193,
//             "Nombre": "JUEGOS DE MESA",
//             "href": "/productos/1hyu/-cnx3i",
//             "submenues": []
//           },
//           {
//             "id": 3192,
//             "Nombre": "MUÑECAS",
//             "href": "/productos/1hyu/-c0zvb",
//             "submenues": []
//           },
//           {
//             "id": 3197,
//             "Nombre": "AUTOS Y CAMIONES",
//             "href": "/productos/1hyu/-cf947",
//             "submenues": []
//           },
//           {
//             "id": 3196,
//             "Nombre": "INSTRUMENTOS MUSICALES",
//             "href": "/productos/1hyu/-c7w1c",
//             "submenues": []
//           },
//           {
//             "id": 3195,
//             "Nombre": "ESTACIONES & PISTAS",
//             "href": "/productos/1hyu/-c4aoz",
//             "submenues": []
//           },
//           {
//             "id": 5347,
//             "Nombre": "CARPAS INFANTILES",
//             "href": "/productos/1hyu/-cky0g",
//             "submenues": []
//           }
//         ]
//       },
//       {
//         "id": 3181,
//         "Nombre": "AUTOS A BATERIA",
//         "href": "/productos/1hyu/-cv8qi",
//         "submenues": []
//       },
//       {
//         "id": 3189,
//         "Nombre": "ANDADORES Y TRICICLOS",
//         "href": "/productos/1hyu/-cxeac",
//         "submenues": []
//       },
//       {
//         "id": 2159,
//         "Nombre": "CUNAS Y CORRALITOS",
//         "href": "/productos/1hyu/-cirug",
//         "submenues": []
//       },
//       {
//         "id": 2158,
//         "Nombre": "COCHES",
//         "href": "/productos/1hyu/-c33ap",
//         "submenues": []
//       }
//     ]
//   },
//   {
//     "id": 33,
//     "Nombre": "TECNOLOGIA",
//     "href": "/productos/hjpq/-",
//     "submenues": [
//       {
//         "id": 3302,
//         "Nombre": "SMART WATCH",
//         "href": "/productos/hjpq/-crtp1",
//         "submenues": []
//       },
//       {
//         "id": 34,
//         "Nombre": "TELEVISORES",
//         "href": "/productos/hjpq/-cg0la",
//         "submenues": [
//           {
//             "id": 105,
//             "Nombre": "SMART TV & LED",
//             "href": "/productos/hjpq/-c5frv",
//             "submenues": []
//           },
//           {
//             "id": 106,
//             "Nombre": "SOPORTES",
//             "href": "/productos/hjpq/-cutdw",
//             "submenues": []
//           }
//         ]
//       },
//       {
//         "id": 35,
//         "Nombre": "AUDIO",
//         "href": "/productos/hjpq/-cpenx",
//         "submenues": [
//           {
//             "id": 3305,
//             "Nombre": "MICROFONOS",
//             "href": "/productos/hjpq/-ccrgf",
//             "submenues": []
//           },
//           {
//             "id": 107,
//             "Nombre": "PARLANTES",
//             "href": "/productos/hjpq/-cjvs5",
//             "submenues": []
//           },
//           {
//             "id": 3290,
//             "Nombre": "AURICULARES",
//             "href": "/productos/hjpq/-chstu",
//             "submenues": []
//           },
//           {
//             "id": 3201,
//             "Nombre": "RADIOS",
//             "href": "/productos/hjpq/-clepq",
//             "submenues": []
//           }
//         ]
//       },
//       {
//         "id": 3291,
//         "Nombre": "INFORMATICA",
//         "href": "/productos/hjpq/-cpzbp",
//         "submenues": [
//           {
//             "id": 3292,
//             "Nombre": "TECLADO Y MOUSE",
//             "href": "/productos/hjpq/-cjr3b",
//             "submenues": []
//           },
//           {
//             "id": 3295,
//             "Nombre": "SILLA OFICINA & GAMER",
//             "href": "/productos/hjpq/-co1x1",
//             "submenues": []
//           }
//         ]
//       },
//       {
//         "id": 3297,
//         "Nombre": "CAR AUDIO",
//         "href": "/productos/hjpq/-cooi2",
//         "submenues": []
//       },
//       {
//         "id": 3172,
//         "Nombre": "CELULARES & TABLETS",
//         "href": "/productos/hjpq/-",
//         "submenues": [
//           {
//             "id": 3306,
//             "Nombre": "CELULARES",
//             "href": "/productos/hjpq/-cid15",
//             "submenues": []
//           },
//           {
//             "id": 3307,
//             "Nombre": "TABLET",
//             "href": "/productos/hjpq/-ce9xe",
//             "submenues": []
//           },
//           {
//             "id": 3309,
//             "Nombre": "ANILLO DE LUZ",
//             "href": "/productos/hjpq/-c7uov",
//             "submenues": []
//           }
//         ]
//       }
//     ]
//   },
//   {
//     "id": 28,
//     "Nombre": "HERRAMIENTAS",
//     "href": "/productos/pdj3/-",
//     "submenues": [
//       {
//         "id": 31,
//         "Nombre": "MAQUINARIA",
//         "href": "/productos/pdj3/-cdlh0",
//         "submenues": [
//           {
//             "id": 4334,
//             "Nombre": "BOMBAS DE AGUA",
//             "href": "/productos/pdj3/-city2",
//             "submenues": []
//           },
//           {
//             "id": 5336,
//             "Nombre": "HORMIGONERAS",
//             "href": "/productos/pdj3/-cegj3",
//             "submenues": []
//           },
//           {
//             "id": 103,
//             "Nombre": "COMPRESOR",
//             "href": "/productos/pdj3/-cfjlq",
//             "submenues": []
//           }
//         ]
//       },
//       {
//         "id": 5340,
//         "Nombre": "CAJAS DE HERRAMIENTAS",
//         "href": "/productos/pdj3/-czwvu",
//         "submenues": []
//       },
//       {
//         "id": 5339,
//         "Nombre": "HERRAMIENTAS MANUALES",
//         "href": "/productos/pdj3/-c17mj",
//         "submenues": []
//       },
//       {
//         "id": 29,
//         "Nombre": "EQUIPAMIENTO DE JARDIN ",
//         "href": "/productos/pdj3/-crueq",
//         "submenues": [
//           {
//             "id": 4318,
//             "Nombre": "SOPLADORA",
//             "href": "/productos/pdj3/-cwmo4",
//             "submenues": []
//           },
//           {
//             "id": 90,
//             "Nombre": "DESMALEZADORAS",
//             "href": "/productos/pdj3/-cu9eo",
//             "submenues": []
//           },
//           {
//             "id": 91,
//             "Nombre": "MOTOSIERRAS",
//             "href": "/productos/pdj3/-che1y",
//             "submenues": []
//           },
//           {
//             "id": 92,
//             "Nombre": "CORTACESPED ",
//             "href": "/productos/pdj3/-c9glo",
//             "submenues": []
//           },
//           {
//             "id": 156,
//             "Nombre": "BORDEADORAS",
//             "href": "/productos/pdj3/-c3v7g",
//             "submenues": []
//           },
//           {
//             "id": 3314,
//             "Nombre": "CORTACERCOS",
//             "href": "/productos/pdj3/-c2mar",
//             "submenues": []
//           }
//         ]
//       },
//       {
//         "id": 30,
//         "Nombre": "HERRAMIENTAS ELECTRICAS",
//         "href": "/productos/pdj3/-ctnqh",
//         "submenues": [
//           {
//             "id": 99,
//             "Nombre": "SOLDADORAS",
//             "href": "/productos/pdj3/-cmfbz",
//             "submenues": []
//           },
//           {
//             "id": 101,
//             "Nombre": "LIJADORAS",
//             "href": "/productos/pdj3/-cev0c",
//             "submenues": []
//           },
//           {
//             "id": 95,
//             "Nombre": "TORNO & MINITORNO",
//             "href": "/productos/pdj3/-c08f2",
//             "submenues": []
//           },
//           {
//             "id": 96,
//             "Nombre": "ROTOMARTILLOS",
//             "href": "/productos/pdj3/-c2s4j",
//             "submenues": []
//           },
//           {
//             "id": 98,
//             "Nombre": "SIERRAS & ELECTROSIERRAS",
//             "href": "/productos/pdj3/-chx4n",
//             "submenues": []
//           },
//           {
//             "id": 3317,
//             "Nombre": "EQUIPOS PARA PINTAR",
//             "href": "/productos/pdj3/-cqgdy",
//             "submenues": []
//           },
//           {
//             "id": 93,
//             "Nombre": "AMOLADORAS",
//             "href": "/productos/pdj3/-cigr5",
//             "submenues": []
//           },
//           {
//             "id": 94,
//             "Nombre": "TALADROS",
//             "href": "/productos/pdj3/-cw61o",
//             "submenues": []
//           },
//           {
//             "id": 102,
//             "Nombre": "FRESADORAS",
//             "href": "/productos/pdj3/-ctlkf",
//             "submenues": []
//           },
//           {
//             "id": 3294,
//             "Nombre": "BANCO DE TRABAJO",
//             "href": "/productos/pdj3/-cbt36",
//             "submenues": []
//           },
//           {
//             "id": 3312,
//             "Nombre": "DESTORNILLADOR",
//             "href": "/productos/pdj3/-cpvy2",
//             "submenues": []
//           },
//           {
//             "id": 3316,
//             "Nombre": "PISTOLAS DE CALOR",
//             "href": "/productos/pdj3/-cumel",
//             "submenues": []
//           },
//           {
//             "id": 3318,
//             "Nombre": "TERMOFUSORA",
//             "href": "/productos/pdj3/-cypth",
//             "submenues": []
//           }
//         ]
//       },
//       {
//         "id": 116,
//         "Nombre": "ACCESORIO PARA AUTOS ",
//         "href": "/productos/pdj3/-c8bc4",
//         "submenues": [
//           {
//             "id": 3311,
//             "Nombre": "LINTERNA",
//             "href": "/productos/pdj3/-cj3vf",
//             "submenues": []
//           },
//           {
//             "id": 2165,
//             "Nombre": "BATERIA PARA AUTO",
//             "href": "/productos/pdj3/-cz4v7",
//             "submenues": []
//           },
//           {
//             "id": 117,
//             "Nombre": "ASPIRADORAS ",
//             "href": "/productos/pdj3/-cldkb",
//             "submenues": []
//           },
//           {
//             "id": 118,
//             "Nombre": "HIDROLAVADORAS",
//             "href": "/productos/pdj3/-cxql5",
//             "submenues": []
//           }
//         ]
//       }
//     ]
//   },
//   {
//     "id": 16,
//     "Nombre": "DEPORTES Y TIEMPO LIBRE",
//     "href": "/productos/x0p7/-",
//     "submenues": [
//       {
//         "id": 3296,
//         "Nombre": "FITNESS",
//         "href": "/productos/x0p7/-c6ngo",
//         "submenues": []
//       },
//       {
//         "id": 17,
//         "Nombre": "BICICLETAS",
//         "href": "/productos/x0p7/-cl8id",
//         "submenues": [
//           {
//             "id": 69,
//             "Nombre": "DE NIÑO",
//             "href": "/productos/x0p7/-cojtf",
//             "submenues": []
//           },
//           {
//             "id": 70,
//             "Nombre": "DE ADULTO",
//             "href": "/productos/x0p7/-csty6",
//             "submenues": []
//           }
//         ]
//       },
//       {
//         "id": 18,
//         "Nombre": "PATIO",
//         "href": "/productos/x0p7/-c5n43",
//         "submenues": [
//           {
//             "id": 73,
//             "Nombre": "PILETAS",
//             "href": "/productos/x0p7/-cc0ab",
//             "submenues": []
//           },
//           {
//             "id": 3174,
//             "Nombre": "CHULENGOS & PARRILLAS",
//             "href": "/productos/x0p7/-chq2z",
//             "submenues": []
//           }
//         ]
//       },
//       {
//         "id": 19,
//         "Nombre": "PLASTICO",
//         "href": "/productos/x0p7/-cyvuk",
//         "submenues": [
//           {
//             "id": 74,
//             "Nombre": "SILLAS",
//             "href": "/productos/x0p7/-cqz18",
//             "submenues": []
//           },
//           {
//             "id": 75,
//             "Nombre": "MESAS",
//             "href": "/productos/x0p7/-ch51c",
//             "submenues": []
//           }
//         ]
//       },
//       {
//         "id": 71,
//         "Nombre": "CAMPING",
//         "href": "/productos/x0p7/-c9f7j",
//         "submenues": [
//           {
//             "id": 3280,
//             "Nombre": "CARPAS",
//             "href": "/productos/x0p7/-cpnpp",
//             "submenues": []
//           },
//           {
//             "id": 3281,
//             "Nombre": "SOMBRILLAS",
//             "href": "/productos/x0p7/-cskg3",
//             "submenues": []
//           },
//           {
//             "id": 2169,
//             "Nombre": "REPOSERAS",
//             "href": "/productos/x0p7/-cg3dm",
//             "submenues": []
//           }
//         ]
//       },
//       {
//         "id": 72,
//         "Nombre": "GACEBOS",
//         "href": "/productos/x0p7/-c0739",
//         "submenues": []
//       },
//       {
//         "id": 3176,
//         "Nombre": "MONOPATIN",
//         "href": "/productos/x0p7/-ctfye",
//         "submenues": []
//       },
//       {
//         "id": 3177,
//         "Nombre": "ROLLERS",
//         "href": "/productos/x0p7/-cx00a",
//         "submenues": []
//       }
//     ]
//   },
//   {
//     "id": 12,
//     "Nombre": "SALUD Y BELLEZA",
//     "href": "/productos/hz4g/-",
//     "submenues": [
//       {
//         "id": 3283,
//         "Nombre": "CUIDADO DEL BEBE",
//         "href": "/productos/hz4g/-csc94",
//         "submenues": [
//           {
//             "id": 3284,
//             "Nombre": "ASPIRADOR NASAL",
//             "href": "/productos/hz4g/-cjpzf",
//             "submenues": []
//           },
//           {
//             "id": 3285,
//             "Nombre": "SACALECHE",
//             "href": "/productos/hz4g/-c1ipp",
//             "submenues": []
//           },
//           {
//             "id": 3286,
//             "Nombre": "ESTERILIZADOR",
//             "href": "/productos/hz4g/-c37fb",
//             "submenues": []
//           }
//         ]
//       },
//       {
//         "id": 13,
//         "Nombre": "CUIDADOS DEL CABELLO ",
//         "href": "/productos/hz4g/-ca2u3",
//         "submenues": [
//           {
//             "id": 61,
//             "Nombre": "PLANCHAS",
//             "href": "/productos/hz4g/-cdl91",
//             "submenues": []
//           },
//           {
//             "id": 62,
//             "Nombre": "SECADORES",
//             "href": "/productos/hz4g/-cnpm0",
//             "submenues": []
//           },
//           {
//             "id": 63,
//             "Nombre": "CEPILLOS TERMICOS ",
//             "href": "/productos/hz4g/-crasx",
//             "submenues": []
//           },
//           {
//             "id": 64,
//             "Nombre": "RIZADORES",
//             "href": "/productos/hz4g/-cnp49",
//             "submenues": []
//           }
//         ]
//       },
//       {
//         "id": 14,
//         "Nombre": "SALUD",
//         "href": "/productos/hz4g/-ccjjq",
//         "submenues": [
//           {
//             "id": 3287,
//             "Nombre": "OXIMETRO",
//             "href": "/productos/hz4g/-c4gin",
//             "submenues": []
//           },
//           {
//             "id": 65,
//             "Nombre": "TENSIOMETROS",
//             "href": "/productos/hz4g/-ct1hk",
//             "submenues": []
//           },
//           {
//             "id": 66,
//             "Nombre": "NEBULIZADORES",
//             "href": "/productos/hz4g/-c5kb6",
//             "submenues": []
//           },
//           {
//             "id": 3236,
//             "Nombre": "BALANZA DE BAÑO",
//             "href": "/productos/hz4g/-ct69i",
//             "submenues": []
//           }
//         ]
//       },
//       {
//         "id": 15,
//         "Nombre": "CUIDADO PERSONAL",
//         "href": "/productos/hz4g/-clmh9",
//         "submenues": [
//           {
//             "id": 3288,
//             "Nombre": "AROMATIZADOR",
//             "href": "/productos/hz4g/-c0tk3",
//             "submenues": []
//           },
//           {
//             "id": 3313,
//             "Nombre": "PEDICURIA",
//             "href": "/productos/hz4g/-cj6nj",
//             "submenues": []
//           },
//           {
//             "id": 67,
//             "Nombre": "CORTABARBA",
//             "href": "/productos/hz4g/-c2vp8",
//             "submenues": []
//           },
//           {
//             "id": 68,
//             "Nombre": "CORTAPELO",
//             "href": "/productos/hz4g/-czcma",
//             "submenues": []
//           }
//         ]
//       },
//       {
//         "id": 161,
//         "Nombre": "MASAJEADORES",
//         "href": "/productos/hz4g/-cpdei",
//         "submenues": []
//       }
//     ]
//   },
//   {
//     "id": 6,
//     "Nombre": "ELECTRODOMESTICOS",
//     "href": "/productos/chdf/-",
//     "submenues": [
//       {
//         "id": 3217,
//         "Nombre": "COCINAS & HORNOS",
//         "href": "/productos/chdf/-cbmmq",
//         "submenues": [
//           {
//             "id": 3218,
//             "Nombre": "COCINAS",
//             "href": "/productos/chdf/-ccaci",
//             "submenues": []
//           },
//           {
//             "id": 3219,
//             "Nombre": "MICROONDAS",
//             "href": "/productos/chdf/-c517g",
//             "submenues": []
//           },
//           {
//             "id": 3220,
//             "Nombre": "HORNOS",
//             "href": "/productos/chdf/-ceb1q",
//             "submenues": []
//           },
//           {
//             "id": 3235,
//             "Nombre": "CAMPANAS & PURIFICADORES",
//             "href": "/productos/chdf/-cd6cc",
//             "submenues": []
//           }
//         ]
//       },
//       {
//         "id": 3222,
//         "Nombre": "HELADERAS & FREEZERS",
//         "href": "/productos/chdf/-cwudi",
//         "submenues": [
//           {
//             "id": 4335,
//             "Nombre": "EXHIBIDORAS",
//             "href": "/productos/chdf/-c3mdm",
//             "submenues": []
//           },
//           {
//             "id": 3225,
//             "Nombre": "HELADERAS",
//             "href": "/productos/chdf/-cintj",
//             "submenues": []
//           },
//           {
//             "id": 3226,
//             "Nombre": "FREEZERS",
//             "href": "/productos/chdf/-chl24",
//             "submenues": []
//           },
//           {
//             "id": 3227,
//             "Nombre": "CAVAS",
//             "href": "/productos/chdf/-ct2xl",
//             "submenues": []
//           }
//         ]
//       },
//       {
//         "id": 3223,
//         "Nombre": "CLIMATIZACIÓN",
//         "href": "/productos/chdf/-c8623",
//         "submenues": [
//           {
//             "id": 3289,
//             "Nombre": "CALEFACTOR",
//             "href": "/productos/chdf/-c5to0",
//             "submenues": []
//           },
//           {
//             "id": 3293,
//             "Nombre": "ESTUFAS A GAS",
//             "href": "/productos/chdf/-coo0s",
//             "submenues": []
//           },
//           {
//             "id": 3228,
//             "Nombre": "AIRES ACONDICIONADOS",
//             "href": "/productos/chdf/-cr9xo",
//             "submenues": []
//           },
//           {
//             "id": 3229,
//             "Nombre": "VENTILADORES",
//             "href": "/productos/chdf/-c9sjp",
//             "submenues": []
//           },
//           {
//             "id": 3230,
//             "Nombre": "ESTUFAS A LEÑA",
//             "href": "/productos/chdf/-csv83",
//             "submenues": []
//           },
//           {
//             "id": 3231,
//             "Nombre": "ESTUFAS ELÉCTRICAS",
//             "href": "/productos/chdf/-cdb1r",
//             "submenues": []
//           }
//         ]
//       },
//       {
//         "id": 3224,
//         "Nombre": "TERMOTANQUES & CALEFONES",
//         "href": "/productos/chdf/-c13lh",
//         "submenues": [
//           {
//             "id": 3232,
//             "Nombre": "TERMOTANQUES A GAS",
//             "href": "/productos/chdf/-cd3q0",
//             "submenues": []
//           },
//           {
//             "id": 3233,
//             "Nombre": "TERMOTANQUES ELÉCTRICOS",
//             "href": "/productos/chdf/-c70jt",
//             "submenues": []
//           },
//           {
//             "id": 3234,
//             "Nombre": "CALEFONES",
//             "href": "/productos/chdf/-cnb9i",
//             "submenues": []
//           }
//         ]
//       },
//       {
//         "id": 3213,
//         "Nombre": "LAVADO",
//         "href": "/productos/chdf/-chyia",
//         "submenues": [
//           {
//             "id": 4333,
//             "Nombre": "LAVAVAJILLAS",
//             "href": "/productos/chdf/-cj9ma",
//             "submenues": []
//           },
//           {
//             "id": 5344,
//             "Nombre": "MOPA",
//             "href": "/productos/chdf/-c43r9",
//             "submenues": []
//           },
//           {
//             "id": 3216,
//             "Nombre": "TENDER",
//             "href": "/productos/chdf/-c4f1l",
//             "submenues": []
//           },
//           {
//             "id": 3310,
//             "Nombre": "TABLA DE PLANCHAR",
//             "href": "/productos/chdf/-cnp6m",
//             "submenues": []
//           },
//           {
//             "id": 3214,
//             "Nombre": "LAVARROPAS",
//             "href": "/productos/chdf/-cchkq",
//             "submenues": []
//           },
//           {
//             "id": 3215,
//             "Nombre": "SECARROPAS",
//             "href": "/productos/chdf/-c7w7i",
//             "submenues": []
//           }
//         ]
//       },
//       {
//         "id": 3202,
//         "Nombre": "PEQUEÑOS HOGAR",
//         "href": "/productos/chdf/-ccm13",
//         "submenues": [
//           {
//             "id": 3209,
//             "Nombre": "MIXER & PROCESADORAS",
//             "href": "/productos/chdf/-cl1ym",
//             "submenues": []
//           },
//           {
//             "id": 3221,
//             "Nombre": "BALANZA DE COCINA",
//             "href": "/productos/chdf/-cyr4p",
//             "submenues": []
//           },
//           {
//             "id": 3203,
//             "Nombre": "TOSTADORAS & SANDWICHERAS",
//             "href": "/productos/chdf/-c62i4",
//             "submenues": [
//               {
//                 "id": 4319,
//                 "Nombre": "BATERIA DE COCINA",
//                 "href": "/productos/chdf/-",
//                 "submenues": []
//               }
//             ]
//           },
//           {
//             "id": 3204,
//             "Nombre": "PAVAS ELÉCTRICAS",
//             "href": "/productos/chdf/-cv0zz",
//             "submenues": []
//           },
//           {
//             "id": 3205,
//             "Nombre": "EXPRIMIDORES & JUGUERAS",
//             "href": "/productos/chdf/-coqhg",
//             "submenues": []
//           },
//           {
//             "id": 3206,
//             "Nombre": "PLANCHAS DE ROPA",
//             "href": "/productos/chdf/-cuuec",
//             "submenues": []
//           },
//           {
//             "id": 3207,
//             "Nombre": "CAFETERAS",
//             "href": "/productos/chdf/-c7igd",
//             "submenues": []
//           },
//           {
//             "id": 3208,
//             "Nombre": "LICUADORAS",
//             "href": "/productos/chdf/-c8ff3",
//             "submenues": []
//           },
//           {
//             "id": 3210,
//             "Nombre": "RALLADORES & PICADORES",
//             "href": "/productos/chdf/-c54o7",
//             "submenues": []
//           },
//           {
//             "id": 3211,
//             "Nombre": "COCCIÓN",
//             "href": "/productos/chdf/-cwebj",
//             "submenues": []
//           },
//           {
//             "id": 3212,
//             "Nombre": "MAQUINAS DE COSER",
//             "href": "/productos/chdf/-cm8ql",
//             "submenues": []
//           },
//           {
//             "id": 3237,
//             "Nombre": "BATIDORAS",
//             "href": "/productos/chdf/-c1a63",
//             "submenues": []
//           }
//         ]
//       }
//     ]
//   },
//   {
//     "id": 1,
//     "Nombre": "HOGAR",
//     "href": "/productos/1noe/-",
//     "submenues": [
//       {
//         "id": 5341,
//         "Nombre": "BAZAR",
//         "href": "/productos/1noe/-cjjbn",
//         "submenues": [
//           {
//             "id": 5342,
//             "Nombre": "JUEGO DE OLLAS",
//             "href": "/productos/1noe/-cohqj",
//             "submenues": []
//           },
//           {
//             "id": 5343,
//             "Nombre": "JUEGO DE CUBIERTOS",
//             "href": "/productos/1noe/-c1ru9",
//             "submenues": []
//           },
//           {
//             "id": 5345,
//             "Nombre": "TERMO & MATES",
//             "href": "/productos/1noe/-ckqnd",
//             "submenues": []
//           },
//           {
//             "id": 5346,
//             "Nombre": "TUPPERS",
//             "href": "/productos/1noe/-cln4c",
//             "submenues": []
//           },
//           {
//             "id": 5351,
//             "Nombre": "TABLAS",
//             "href": "/productos/1noe/-ckcky",
//             "submenues": []
//           }
//         ]
//       },
//       {
//         "id": 3261,
//         "Nombre": "AMOBLAMIENTOS DE COCINA",
//         "href": "/productos/1noe/-cl9ow",
//         "submenues": [
//           {
//             "id": 3265,
//             "Nombre": "MESADAS DE COCINA",
//             "href": "/productos/1noe/-cs3g6",
//             "submenues": []
//           },
//           {
//             "id": 3262,
//             "Nombre": "BAJO MESADAS",
//             "href": "/productos/1noe/-cuo7z",
//             "submenues": []
//           },
//           {
//             "id": 3263,
//             "Nombre": "ALACENAS",
//             "href": "/productos/1noe/-cgnm3",
//             "submenues": []
//           },
//           {
//             "id": 3264,
//             "Nombre": "GABINETES MULTIFUNCION",
//             "href": "/productos/1noe/-c5v4r",
//             "submenues": []
//           }
//         ]
//       },
//       {
//         "id": 3268,
//         "Nombre": "COMEDOR",
//         "href": "/productos/1noe/-cgq5l",
//         "submenues": [
//           {
//             "id": 3270,
//             "Nombre": "BANQUETAS & BARRAS",
//             "href": "/productos/1noe/-cpqfj",
//             "submenues": []
//           },
//           {
//             "id": 3272,
//             "Nombre": "MESAS",
//             "href": "/productos/1noe/-cvnth",
//             "submenues": []
//           },
//           {
//             "id": 3273,
//             "Nombre": "SILLAS",
//             "href": "/productos/1noe/-c6zfy",
//             "submenues": []
//           },
//           {
//             "id": 3276,
//             "Nombre": "JUEGOS DE COMEDOR",
//             "href": "/productos/1noe/-csyyn",
//             "submenues": []
//           }
//         ]
//       },
//       {
//         "id": 2,
//         "Nombre": "DORMITORIO",
//         "href": "/productos/1noe/-cnb1j",
//         "submenues": [
//           {
//             "id": 4326,
//             "Nombre": "ZAPATEROS",
//             "href": "/productos/1noe/-c8oju",
//             "submenues": []
//           },
//           {
//             "id": 4321,
//             "Nombre": "CAMAS & CUCHETAS",
//             "href": "/productos/1noe/-c8y35",
//             "submenues": []
//           },
//           {
//             "id": 4323,
//             "Nombre": "COMODAS & CHIFONIER",
//             "href": "/productos/1noe/-c9k8n",
//             "submenues": []
//           },
//           {
//             "id": 4324,
//             "Nombre": "MESAS DE LUZ",
//             "href": "/productos/1noe/-c6nma",
//             "submenues": []
//           },
//           {
//             "id": 4325,
//             "Nombre": "RESPALDOS DE CAMAS",
//             "href": "/productos/1noe/-c8k3z",
//             "submenues": []
//           },
//           {
//             "id": 39,
//             "Nombre": "PLACARES",
//             "href": "/productos/1noe/-csrgd",
//             "submenues": []
//           }
//         ]
//       },
//       {
//         "id": 3275,
//         "Nombre": "BAÑO",
//         "href": "/productos/1noe/-cvhia",
//         "submenues": [
//           {
//             "id": 5337,
//             "Nombre": "VANITORY",
//             "href": "/productos/1noe/-clp62",
//             "submenues": []
//           },
//           {
//             "id": 5338,
//             "Nombre": "BOTIQUIN & ESPEJOS",
//             "href": "/productos/1noe/-cimt3",
//             "submenues": []
//           }
//         ]
//       },
//       {
//         "id": 3257,
//         "Nombre": "OFICINA",
//         "href": "/productos/1noe/-cuqn2",
//         "submenues": [
//           {
//             "id": 3258,
//             "Nombre": "ESCRITORIOS",
//             "href": "/productos/1noe/-c8lko",
//             "submenues": []
//           },
//           {
//             "id": 3259,
//             "Nombre": "BIBLIOTECAS",
//             "href": "/productos/1noe/-cs6qx",
//             "submenues": []
//           }
//         ]
//       },
//       {
//         "id": 3260,
//         "Nombre": "LIVING",
//         "href": "/productos/1noe/-ce3ka",
//         "submenues": [
//           {
//             "id": 4327,
//             "Nombre": "SILLONES",
//             "href": "/productos/1noe/-c8d1x",
//             "submenues": []
//           },
//           {
//             "id": 4328,
//             "Nombre": "MUEBLES DE PINO",
//             "href": "/productos/1noe/-chqet",
//             "submenues": []
//           },
//           {
//             "id": 4330,
//             "Nombre": "PERCHEROS",
//             "href": "/productos/1noe/-cdaxe",
//             "submenues": []
//           },
//           {
//             "id": 3267,
//             "Nombre": "MODULARES & RACKS",
//             "href": "/productos/1noe/-cyqu6",
//             "submenues": []
//           }
//         ]
//       }
//     ]
//   }
// ];

const ProductosView = (props) => {
  const {
    filters,
    productos,
    breadcrumbs,
    loading,
    reloading,
    chosenPage,
    totalPages,
    search,
    onChangeSearch,
    onChangeFilter,
    removeCodesFromUrl,
    onShowMore,
    OnChangeNavigationBar,
    showBanner,
    bannerSrc,
    totalProductos,
  } = props;

  const showFilters = props.showFilter && filters && filters.length > 0;

  const [menues, setMenues] = useState([]);

  // useEffect(() => {
  //   functions.get(api.api.sitio.menu, null, null, null, (data) => {
  // 		setMenues( data.data );
  // 	});
  // }, [])

  return (loading || reloading) && filters.length == 0 ? (
    <div className="text-center">
      <Loader
        type="Bars"
        color="#5271ff"
        height={100}
        width={100}
        timeout={3000} //3 secs
        svgClass="spinnerDiv"
      />
    </div>
  ) : (
    <>
      {/* <ParadigmaHeaderMenu Menues={menues} /> */}

      <div className="esige-productos">
        <ProductosHeader
          loading={loading || reloading}
          breadcrumbs={breadcrumbs}
          sort={search.get("sort")}
          pageSize={search.has("pageSize") ? search.get("pageSize") : 24}
          onChangeSearch={(value, key) => onChangeSearch(value, key)}
        />

        <div className="esige-productos-container px-md-4 px-md-0 pr-md-3">
          {showFilters && (
            <div className="d-none d-md-block col-md-3 mt-1">
              <ProductosFilter
                onChangeFilter={onChangeFilter}
                removeCodesFromUrl={removeCodesFromUrl}
                breadcrumbs={breadcrumbs}
                filters={filters}
                loading={false}
                onShowMore={onShowMore}
              />
            </div>
          )}
          {totalProductos > 0 ? (
            reloading ? (
              <div
                className={`text-center my-5 col-xs-12 col-sm-12 ${
                  showFilters
                    ? "col-md-9 col-lg-9 mt-1-i"
                    : "col-md-12 col-lg-12"
                }`}
              >
                <Loader
                  type="Bars"
                  color="#5271ff"
                  height={100}
                  width={100}
                  timeout={3000} //3 secs
                />
              </div>
            ) : (
              <div
                className={`esige-productos-list col-xs-12 col-sm-9 ${
                  showFilters ? "col-12" : "col-md-12 col-lg-12"
                }`}
              >
                <div className="">
                  {/* row */}
                  <div className="mt-3">
                    {productos.map((producto, index) => (
                      <View.ProductoBox
                        key={`articulo-${producto.code}`}
                        orientation="vertical"
                        articulo={producto}
                        maxQuantity={999}
                      />
                    ))}
                  </div>
                </div>

                <div className="esige-productos-container px-md-4 px-md-0 pr-md-3 mt-2">
                  <p className="my-auto ml-auto text-right">
                    <strong>{totalProductos}</strong> productos encontrados
                  </p>
                </div>

                <PageNavigation
                  cantProd={productos.length}
                  currentPage={chosenPage}
                  totalPages={totalPages}
                  OnChangeNavigationBar={OnChangeNavigationBar}
                />
              </div>
            )
          ) : (
            <div className="text-center py-5 col-12 col-sm-9">
              <h2>No hay productos para mostrar</h2>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductosView;
