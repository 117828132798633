import React from 'react'

export const Sugerencias = () => (
    <div>
        <p className="mb-1">Sugerencias para una contraseña segura:</p>
        <ul>
            <li><small>Longitud no menor a 8 caracteres.</small></li>
            <li><small>Contener Mayusculas, Minusculas y/o Números.</small></li>
        </ul>
    </div>
);

export const password_validator = {
    verifyLength: function (text = '', minlength = 8) { return text != undefined && text != null && text.length >= minlength; },
    isPasswordValid: function (text = '') { return this.verifyLength(text, 8) },
};