import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { NavbarBrand, Nav, Alert } from "reactstrap";
// import {
//   BsFacebook,
//   BsInstagram,
//   BsEnvelope,
//   BsWhatsapp,
// } from "react-icons/bs";

import MainSearchForm from "../MainSearch/MainSearchForm";
import {
  LoginNavItem,
  // LoginNavItemMobile,
} from "./CustomLoginNavItem/LoginNavItem";
// import Menu from "./Menu";

import "./Header.scss";
import "./HeaderSearch.scss";

import { LoginContext } from "../../components/Tienda/components/ParadigmaLoginProvider/ParadigmaLoginProvider";

const SearchTopHeader = () => (
  <>
    {/* <section className="header__top container d-none d-md-flex justify-content-end position-absolute">
      
    </section> */}
    {/* flex-row justify-content-between align-items-center */}
    <div className="container headerSearch__brand d-flex px-md-5">
      <NavbarBrand className="order-1" href="/">
        <img
          className="img-fluid"
          src={process.env.REACT_APP_TIENDA_LOGO}
          alt={process.env.REACT_APP_TIENDA_NOMBRE}
        />
      </NavbarBrand>
      <MainSearchForm className="order-3 order-sm-2" />
      <div className="header__loguin order-2 order-sm-3">
        <Nav>
          <LoginNavItem closeHeader={false} />
        </Nav>
      </div>
    </div>
  </>
);

// const TopHeader = () => (
//   <React.Fragment>
//     <section className="header__top container d-none d-md-flex justify-content-end position-absolute">
//       <div className="header__loguin">
//         <Nav>
//           <LoginNavItem closeHeader={false} />
//         </Nav>
//       </div>
//     </section>
//     <NavbarBrand href={process.env.REACT_APP_URL}>
//       <img
//         className="img-fluid"
//         src={process.env.REACT_APP_LOGO}
//         alt={process.env.REACT_APP_TIENDA_NOMBRE}
//       />
//     </NavbarBrand>
//   </React.Fragment>
// );

const Header = (props) => {
  const { pathname } = useLocation();
  const isRoot = pathname === "/";

  const { logged, logout, displayname } = useContext(LoginContext);

  return (
    <header className="headerSearch">
      {logged && (
        <>
          {localStorage.getItem("MG") == null && (
            <Alert color="warning">
              Por favor, completar su perfil desde "Mi Cuenta" &gt; "Editar
              Datos"
            </Alert>
          )}
          <SearchTopHeader />
          {/* <Menu /> */}
        </>
      )}
    </header>
  );
};

export default Header;
