import React, { Component } from 'react';
import { Row, Col, Input} from 'reactstrap';

import functions from "../../../../functions/functions.js";

export default (props) => {
  const{ tiposDoc, localidades, situacionesIVA, situacionIVAEsInscripto, onChange, validaMail } = props;
  const {
    Nombre, TipoDoc_id, Localidad_id, SituacionIVA_id,
    Documento, DomicilioEntrega,
    Domicilio, CodPostal, Telefono,
    Email, disabled,
  } = props.buyer;
  return (
    <div className="container esige-profile-container">
    <div className="esige-title">
      <h3 className="title my-4 text-center">Comprador</h3>
    </div>
    <Row>
      <Col sm={12}>
        <label className="w-100">Nombre / Razón Social</label>
        <Input
          maxLength="100"
          disabled={disabled}
          className={functions.validateChar(Nombre, 10) ? "rounded-lg border border-success" : "rounded-lg border border-danger"}
          onChange={(e)=> onChange('Nombre', e.target.value)}
          value={Nombre}
        />
      </Col>

      <Col sm={12} className="mt-2">
        <label className="w-100">Condición de IVA</label>
        <Input
          type={"select"}
          disabled={disabled}
          className="rounded-lg border"
          onChange={(e)=> onChange('SituacionIVA_id', e.target.value)}
          value={SituacionIVA_id}>
          {
            situacionesIVA &&
            situacionesIVA.map(item=><option value={`${item.Codigo}`}>{item.Nombre}</option>)
          }
        </Input>
      </Col>

      <Col sm={12} className="mt-3">
        <label className="w-100">Documento</label>
        <Row>
          <Col sm={12} lg={3}>
            <Input
              type={"select"}
              disabled={disabled || situacionIVAEsInscripto}
              className="rounded-lg border"
              onChange={(e)=> onChange('TipoDoc_id', e.target.value)}
              value={TipoDoc_id}>
              {
                tiposDoc &&
                tiposDoc.map(item=><option value={`${item.Codigo}`}>{item.Nombre}</option>)
              }
            </Input>
          </Col>
          <Col sm={12} lg={3}>
            <Input
              type="number"
              max={99999999999}
              disabled={disabled}
              maxLength="11"
              className={functions.validateChar(String(Documento || ''), 7) ? "rounded-lg border border-success" : "rounded-lg border border-danger"}
              onChange={(e)=> onChange('Documento', String(e.target.value <= 99999999999 && e.target.value > 0 ? e.target.value : 0))}
              value={Documento} />
          </Col>
        </Row>
      </Col>
      <Col sm={12} className="mt-2">
        <label className="w-100">Email</label>
        <Input
          type="email"
          placeholder="nombre@correo.com"
          maxLength="150"
          className={validaMail ? "rounded-lg border border-success" : "rounded-lg border border-danger"}
          onChange={(e) => onChange('Email', e.target.value)}
          value={Email}
        />
      </Col>
      <Col sm={12} className="mt-2">
      <label className="w-100">Localidad</label>
      <Input
        type={"select"}
        disabled={disabled}
        className="rounded-lg border"
        onChange={(e)=> onChange('Localidad_id', e.target.value)}
        value={Localidad_id}>
        {
          localidades &&
          localidades.map(item=><option value={`${item.Codigo}`}>{item.Nombre} - {item.Provincia}</option>)
        }
      </Input>
      </Col>
      <Col sm={12} className="mt-2">
        <Row>
          <Col sm={8}>
            <label className="w-100">Domicilio</label>
            <Input
              maxLength="100"
              className="rounded-lg border"
              disabled={disabled}
              onChange={(e)=> onChange('Domicilio', e.target.value)}
              value={Domicilio}
            />
          </Col>
          <Col sm={4}>
            <label className="w-100">C. Postal</label>
            <Input
              maxLength="8"
              className="rounded-lg border"
              disabled={disabled}
              onChange={(e)=> onChange('CodPostal', e.target.value)}
              value={CodPostal}
            />
          </Col>
        </Row>
      </Col>
      <Col sm={12} className="mt-3">
        <label className="w-100">Domicilio de Entrega</label>
        <Input
          maxLength="100"
          placeholder="Opcional si desea que se entrege en otro lugar que no sea su domicilio"
          className="rounded-lg border"
          onChange={(e)=> onChange('DomicilioEntrega', e.target.value)}
          value={DomicilioEntrega}
        />
      </Col>
      <Col sm={12} className="mt-2 mb-5">
        <label className="w-100">Teléfono</label>
        <Input
          maxLength="50"
          className={functions.validateChar(Telefono, 7) ? "rounded-lg border border-success" : "rounded-lg border border-danger"}
          onChange={(e)=> onChange('Telefono', e.target.value)}
          value={Telefono}
        />
      </Col>
    </Row>
    </div>
  )
}
