import React, { Component } from 'react';
import api from "../../../../api/api.js";
import apifunctions from "../../../../api/functions.js";
import validateFunctions from "../../../../functions/functions.js";
import BuyerFormView from './BuyerFormView.js';

class BuyerForm extends Component {

  constructor(props){
    super(props);
    this.state = {
      tiposDoc:[],
      localidades:[],
      situacionesIVA:[],
      validaMail: false,
    }

    this.writingTimeout = null;
  }

  componentDidMount(){
    const TiposDocumentoCallback = (response) => {
      let tiposDoc = response.data ? response.data : [];
      this.setState({tiposDoc:tiposDoc}, () => {
        let TipoDoc_id = this.props.buyer ? this.props.buyer.TipoDoc_id : tiposDoc[0].Codigo;
        if (tiposDoc.length> 0) this.onChange('TipoDoc_id', TipoDoc_id);
      });
    };

    const LocalidadesCallback = (response) => {
      let localidades = response.data ? response.data : [];
      this.setState({localidades:localidades}, () => {
        let Localidad_id = this.props.buyer ? this.props.buyer.Localidad_id : localidades[0].Codigo;
        if (localidades.length> 0) this.onChange('Localidad_id', Localidad_id);
      });
    };

    const SituacionesIVACallback = (response) => {
      let situacionesIVA = response.data ? response.data : [];
      this.setState({situacionesIVA:situacionesIVA}, () => {
        let SituacionIVA_id = this.props.buyer ? this.props.buyer.SituacionIVA_id : situacionesIVA[0].Codigo;
        if (situacionesIVA.length> 0) this.onChange('SituacionIVA_id', SituacionIVA_id);
      });
    };

    apifunctions.get(api.api.clientes.cliente.tiposdocumentos, null, null, {}, TiposDocumentoCallback);
    apifunctions.get(api.api.clientes.cliente.localidades, null, null, {}, LocalidadesCallback);
    apifunctions.get(api.api.clientes.cliente.situacionesIVA, null, null, {}, SituacionesIVACallback);
  }

  componentWillReceiveProps(nextProps){
    const { buyer } = nextProps;

    clearTimeout(this.writingTimeout);

    const ValidationLogic = () => {
      clearTimeout(this.writingTimeout);

      if (buyer !== undefined && buyer !== null){
        const isNameValid = validateFunctions.validateChar(buyer.Nombre, 5);
        const isTipoDocumentoValid = buyer.TipoDoc_id != undefined && buyer.TipoDoc_id != null && buyer.TipoDoc_id != "";
        const isSituacionIVAValid = buyer.SituacionIVA_id != undefined && buyer.SituacionIVA_id != null && buyer.SituacionIVA_id != "";
        const isLocalidadValid = buyer.Localidad_id != undefined && buyer.Localidad_id != null && buyer.Localidad_id != "";
        const isDocumentoValid = validateFunctions.validateChar(buyer.Documento, 7);
        const isTelefonoValid = validateFunctions.validateChar(buyer.Telefono, 7);
        const isEmailValid = buyer.Email && validateFunctions.isEmailValid(buyer.Email);

        const isValid = isNameValid && isTipoDocumentoValid && isSituacionIVAValid && isLocalidadValid && isDocumentoValid && isTelefonoValid && isEmailValid;

        const UpdateFather = () => this.props.onChange(isValid, buyer);

        this.setState({ validaMail: isEmailValid }, UpdateFather);
      };
    };

    this.writingTimeout = setTimeout(ValidationLogic, 2000);
  }

  onChange(key, value){
    if(this.props.onChange){
      let buyer = this.props.buyer;
      if(key != 'validaMail') buyer[key] = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      if(key == "SituacionIVA_id" && value == "1"){
        buyer["TipoDoc_id"] = "3";
      }
      this.props.onChange(false, buyer);
    }
  }

  render(){
    return (
      <BuyerFormView
        buyer = {this.props.buyer}
        disabled={this.props.buyer && this.props.buyer.disabled}
        situacionIVAEsInscripto={this.props.buyer && this.props.buyer.SituacionIVA_id === "1"}
        onChange = { (key, value) => this.onChange(key, value) }
        tiposDoc = {this.state.tiposDoc}
        localidades = {this.state.localidades}
        situacionesIVA = {this.state.situacionesIVA}
        validaMail = {this.state.validaMail}
      />
    );
  }
}
export default BuyerForm;
