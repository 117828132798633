import React, { Component, useState } from "react";
import api from "../../api/api.js";
import apifunctions from "../../api/functions.js";
import imagenNoDisponible from "../../assets/image_not_found.png";

export const CartContext = React.createContext();

class CartProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      articulos: this.decode(),
      CodigoCondicionDePago: null,
      CodigoPlanPagoConTarjeta: null,

      porcentajeIIBB: 0,
      montoIIBB: 0,
      montoIVA: 0,
      subtotal: 0,
      total: 0,

      cantidadTotal: 0,
    };
  }

  componentDidMount() {
    let articulos = this.decode();
    let cantidadTotal = 0;
    if (articulos.length > 0)
      cantidadTotal = articulos.map((x) => x.quantity).reduce((a, b) => a + b);
    if (articulos) {
      this.setState({ articulos: articulos, cantidadTotal: cantidadTotal });
    }
  }

  decode() {
    let cart = localStorage.getItem("cartItems");
    if (cart) {
      cart = JSON.parse(atob(cart));
      cart = Array.isArray(cart) ? cart : [];
      cart = cart.filter((item) => !!item.quantity && item.quantity > 0);
      cart.forEach((item) => {
        item.talle_id = !!!item.talle_id ? null : item.talle_id;
        item.color_id = !!!item.color_id ? null : item.color_id;
      });
    } else {
      cart = [];
    }
    return cart;
  }

  encode() {
    return btoa(
      JSON.stringify(
        this.state.articulos.map((item) => ({
          code: item.code,
          quantity: item.quantity,
          color_id: item.color_id,
          talle_id: item.talle_id,
        }))
      )
    );
  }

  saveLocal() {
    localStorage.setItem("cartItems", this.encode());
  }

  saveApi(callback) {
    let cart = localStorage.getItem("cartItems");
    if (cart !== undefined && cart !== null && cart !== "") {
      const succesCallback = () => callback && callback();
      apifunctions.post(
        api.api.clientes.cliente.carrito,
        null,
        { cartItems: cart },
        succesCallback
      );
    }
  }

  save(callback) {
    this.saveLocal();
    if (this.isLogged()) this.saveApi(callback);
    else callback && callback();
  }

  setItems(items, callback) {
    localStorage.removeItem("cartItems");
    localStorage.setItem("cartItems", btoa(JSON.stringify(items)));

    let cantidadTotal = 0;
    if (items.length > 0)
      cantidadTotal = items.map((x) => x.quantity).reduce((a, b) => a + b);

    setTimeout(
      () =>
        this.setState({ articulos: items, cantidadTotal: cantidadTotal }, (e) =>
          this.save(callback)
        ),
      100
    );
    return true;
  }

  getCantidadTotal() {
    const { articulos } = this.state;
    let total = 0;
    if (articulos.length > 0)
      total = articulos.map((x) => x.quantity).reduce((a, b) => a + b);
    return total;
  }

  getItem({ code, talle_id, color_id }) {
    let _item = this.state.articulos.filter(
      (art) =>
        art.code == code && art.talle_id == talle_id && art.color_id == color_id
    );
    _item = _item.length > 0 ? _item[0] : null;
    return _item;
  }

  updateData(callback) {
    const successCallback = (resp) => {
      if (resp && resp.data) {
        const data = resp.data;
        data.Articulos.forEach((art) => {
          if (art.Imagenes.length === 0)
            art.Imagenes.push({
              src: imagenNoDisponible,
              color_id: null,
              talle_id: null,
            });
        });

        this.setState(
          (prevState) => {
            prevState.loading = false;
            prevState.articulos = data.Articulos;
            prevState.CodigoCondicionDePago = data.CodigoCondicionDePago;
            prevState.CodigoPlanPagoConTarjeta = data.CodigoPlanPagoConTarjeta;
            prevState.porcentajeIIBB = data.PorcentajeIIBB;
            prevState.montoIIBB = data.MontoIIBB;
            prevState.montoIVA = data.MontoIVA;
            prevState.subtotal = data.SubTotal;
            prevState.total = data.Total;

            prevState.cantidadTotal = 0;
            if (prevState.articulos.length > 0)
              prevState.cantidadTotal = prevState.articulos
                .map((x) => x.quantity)
                .reduce((a, b) => a + b);

            return prevState;
          },
          () => callback && callback(data)
        );
      }
    };

    const data = {
      cartItems: this.state.articulos.map((item) => ({
        code: item.code,
        quantity: item.quantity,
        color_id: item.color_id,
        talle_id: item.talle_id,
      })),
      CodigoCondicionDePago: this.state.CodigoCondicionDePago,
      CodigoPlanPagoConTarjeta: this.state.CodigoPlanPagoConTarjeta,
    };

    this.setState({ loading: true });
    apifunctions.asyncPost(api.api.ventas.cart, null, data, successCallback);
  }

  setItem({ code, quantity, talle_id, color_id }, callback) {
    if (quantity && !isNaN(quantity)) {
      const successCallback = () => {
        this.save();
        if (callback) callback();
      };
      const updateState = (prevState) => {
        if (
          prevState.articulos.filter(
            (x) =>
              x.code === code &&
              x.color_id === color_id &&
              x.talle_id === talle_id
          ).length > 0
        ) {
          prevState.articulos
            .filter(
              (x) =>
                x.code === code &&
                x.color_id === color_id &&
                x.talle_id === talle_id
            )
            .forEach((x) => {
              x.quantity = quantity;
            });
        } else {
          prevState.articulos.push({ code, quantity, color_id, talle_id });
        }

        prevState.cantidadTotal = 0;
        if (prevState.articulos.length > 0)
          prevState.cantidadTotal = prevState.articulos
            .map((x) => x.quantity)
            .reduce((a, b) => a + b);
        return prevState;
      };
      this.setState(updateState, successCallback);
    } else {
      this.removeItem({ code, talle_id, color_id }, callback);
    }
  }

  removeItem({ code, talle_id, color_id }, callback) {
    if (
      this.state.articulos.filter(
        (x) =>
          x.code === code && x.color_id === color_id && x.talle_id === talle_id
      ).length > 0
    ) {
      this.setState(
        (prevState) => {
          prevState.cantidadTotal = 0;
          prevState.articulos = prevState.articulos.filter(
            (art) =>
              art.code !== code ||
              art.color_id !== color_id ||
              art.talle_id !== talle_id
          );
          if (prevState.articulos.length > 0)
            prevState.cantidadTotal = prevState.articulos
              .map((x) => x.quantity)
              .reduce((a, b) => a + b);
          return prevState;
        },
        () => {
          this.save(callback);
        }
      );
    }
  }

  removeItems(itemsToRemove, callback) {
    if (
      this.state.articulos.filter(
        (x) =>
          itemsToRemove.filter(
            (y) =>
              y.code === x.code &&
              y.color_id === x.color_id &&
              y.talle_id === x.talle_id
          ).length > 0
      ).length > 0
    ) {
      this.setState(
        (prevState) => {
          prevState.articulos = prevState.articulos.filter(
            (x) =>
              itemsToRemove.filter(
                (y) =>
                  y.code === x.code &&
                  y.color_id === x.color_id &&
                  y.talle_id === x.talle_id
              ).length === 0
          );
          prevState.cantidadTotal = 0;
          if (prevState.articulos.length > 0)
            prevState.cantidadTotal = prevState.articulos
              .map((x) => x.quantity)
              .reduce((a, b) => a + b);
          return prevState;
        },
        () => {
          this.save();
          if (callback) callback();
        }
      );
    }
  }

  getCantidadItem({ code, talle_id, color_id }) {
    let total = 0;
    const { articulos } = this.state;
    if (articulos && articulos.length > 0 && code) {
      let items = articulos.filter(
        (x) =>
          x.code === code && x.color_id == color_id && x.talle_id === talle_id
      );
      if (items.length > 0)
        total = items.map((x) => x.quantity).reduce((a, b) => a + b);
    }
    return total;
  }

  isLogged() {
    return localStorage.getItem("token") ? true : false;
  }

  isItemPresent({ code, talle_id, color_id }) {
    let isPresent = false;
    const { articulos } = this.state;
    if (articulos && articulos.length > 0)
      isPresent =
        articulos.filter(
          (x) =>
            x.code === code &&
            x.talle_id === talle_id &&
            x.color_id === color_id
        ).length > 0;
    return isPresent;
  }

  render() {
    return (
      <CartContext.Provider
        value={{
          cartState: { ...this.state },

          clear: () => this.setItems([]),
          setItems: (items, callback) => this.setItems(items, callback),
          setItem: ({ code, talle_id, color_id, quantity }, callback) =>
            this.setItem({ code, talle_id, color_id, quantity }, callback),
          getItem: ({ code, talle_id, color_id }) =>
            this.getItem({ code, talle_id, color_id }),
          getCantidadTotal: () => this.getCantidadTotal(),
          removeItem: ({ code, talle_id, color_id }, callback) =>
            this.removeItem({ code, talle_id, color_id }, callback),
          removeItems: (items, callback) => this.removeItems(items, callback),
          getCantidadItem: ({ code, talle_id, color_id }) =>
            this.getCantidadItem({ code, talle_id, color_id }),
          isItemPresent: ({ code, talle_id, color_id }) =>
            this.isItemPresent({ code, talle_id, color_id }),

          updateData: (callback) => this.updateData(callback),
          isLogged: () => this.isLogged(),
          setCodigoCondicionDePago: (condPago, planPago, callback) =>
            this.setState(
              {
                CodigoCondicionDePago: condPago,
                CodigoPlanPagoConTarjeta: planPago,
              },
              callback
            ),
        }}
      >
        {this.props.children}
      </CartContext.Provider>
    );
  }
}

export default CartProvider;

export const withCartContext = (Componente) => (
  <CartContext.Consumer>
    {(value) => <Componente {...value} />}
  </CartContext.Consumer>
);
