import React from "react";

// Assets
import logo from "../assets/img/site/Logo-Agrosur.png";

// Componentes
import LoadingScreen from "react-loading-screen";
// Componentes de tienda
import CantidadSelectorView from "./Tienda/components/CantidadSelector/CantidadSelectorView";
import ProductoBox from "./ProductoBox/ProductoBox";
import OfertasView from "./Tienda/components/ProdOfertados/OfertasView";
import OfertaView from "./Tienda/components/ProdOfertados/OfertaView";
import DestacadosView from "./Tienda/components/ProdDestacados/DestacadosView";
import DestacadoView from "./Tienda/components/ProdDestacados/DestacadoView";
import CartDetailView from "./Tienda/components/CheckoutButton/CartDetailView";
import {
  Sugerencias,
  password_validator,
} from "./Tienda/components/PasswordScheme/BaseScheme";
// Paginas
// import ProductosView from './Tienda/views/Productos/ProductosView';
import ProductosView from "./Productos/ProductosView";
import ProductDetail from "./ProductDetail/ProductDetail";

export const LoadingScreenProps = {
  spinnerColor: "#9ee5f8",
  logoSrc: logo,
};

export const View = {
  LoadingScreen: (props) => (
    <div className="esige-productos-list-loader">
      <LoadingScreen {...LoadingScreenProps} {...props} />
    </div>
  ),

  ProductoBox: ProductoBox,
  CantidadSelectorView: CantidadSelectorView,

  OfertasView: OfertasView,
  OfertaView: OfertaView,

  CartDetailView: CartDetailView,

  DestacadosView: DestacadosView,
  DestacadoView: DestacadoView,

  ProductosView: ProductosView,
  ProductoView: (props) => <ProductDetail {...props} magnifier={false} />,
};

export const GlobalConfigTienda = {
  NumeroDecimales: 0,
  logoSrc: logo,
  maxQuantity: 999,

  passwordScheme: { Sugerencias, password_validator },
};
